<template>
    <svg
        width="55"
        height="54"
        viewBox="0 0 55 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M45.2909 37.845L43.8734 47.88C43.5584 50.1075 41.6459 51.75 39.4184 51.75H25.5584C24.3659 51.75 22.6559 51.2775 21.8234 50.4225L11.5859 39.645L13.4534 37.755C13.9934 37.215 14.7584 36.9675 15.5234 37.125L22.8359 38.79V14.625C22.8359 12.7575 24.3434 11.25 26.2109 11.25C28.0784 11.25 29.5859 12.7575 29.5859 14.625V28.125H31.6334C32.3309 28.125 33.0284 28.2825 33.6359 28.5975L42.8384 33.1875C44.5709 34.065 45.5609 35.9325 45.2909 37.845ZM45.1334 12.375H38.5859V15.75H49.8359V4.5H46.4609V9.045C41.2409 4.7925 34.5809 2.25 27.3359 2.25C14.9834 2.25 6.47844 9.27 4.83594 15.75H8.36844C10.0784 11.295 16.6709 5.625 27.3359 5.625C34.1534 5.625 40.3634 8.19 45.1334 12.375Z"
            fill="currentColor"
        />
    </svg>
</template>
