import {defineStore} from "pinia";

export const useUserNotificationsStore = defineStore("userNotifications", {
    state: () => ({
        profileImg: "/assets/user-profile-img-temp.jpg",
        notifications: {
            achievements: [
                // "O0L0C0", "O0L0C1"
            ],
            glossary: [
                // "000"
            ],
        },
    }),
    getters:{
        hasNotifications(state) {
            return this.hasAchievementsNotifications || this.hasGlossaryNotifications;
        },
        hasAchievementsNotifications(state) {
            return this.notifications.achievements.length > 0;
        },
        hasGlossaryNotifications(state) {
            return this.notifications.glossary.length > 0;
        },
        achievementsNotificationsLength() {
            return this.notifications.achievements.length;
        },
        glossaryNotificationsLength() {
            return this.notifications.glossary.length;
        },
    },
    actions: {
        pushNotification(type, notification) {
            if (type === "achievement") {
                this.notifications.achievements.push(notification);
            } else if (type === "glossary") {
                this.notifications.glossary.push(notification);
            } else {
                console.warn("pushNotification type not recognized:", type);
            }
        },

        clearAllNotifications() {
            this.notifications.achievements = [];
            this.notifications.glossary = [];
        },
        clearAchievementsNotifications() {
            this.notifications.achievements = [];
        },
        clearGlossaryNotifications() {
            this.notifications.glossary = [];
        },
    },
    persist: true,
});
