<script setup>

import {AppContent} from "@/content/data/content.js";
import {useUserStore} from "@/stores/userStore.js";

const UserStore = useUserStore();
</script>

<template>
    <div class="user-icon" role="button" tabindex="0">
        <img class="user-icon-img" alt="User profile image"
             :src="AppContent.achievementProfilePictures[UserStore.currentProfileImg].default"></img>
        <slot name="badge"></slot>
        <!-- <div class="user-notification-icon" :hidden="!UserNotificationsStore.hasNotifications()"></div> -->
    </div>
</template>

<style scoped>
.user-icon {
    @apply relative pointer-events-none ;
}

.user-icon-img {
    @apply w-full h-full object-cover rounded-full overflow-hidden;
}

.user-notification-icon {
    @apply absolute w-4 h-4 bg-warm rounded-full bottom-0 right-0;
}
</style>
