<script setup>
import { ref } from "vue";

const props = defineProps({
    icon: { type: String, required: true },
    text: { type: String, required: true },
});

const open = ref(false);
</script>

<template>
    <div
        class="character-card-content-item"
        :class="open ? 'open' : ''"
        role="button"
        tabindex="0"
        @click="open = !open"
    >
        <!-- icon -->
        <div class="p-c-c-i-icon">
            <img class="w-full h-full" :src="icon"  alt="character trait icon"/>
        </div>
        <!-- text -->
        <div class="p-c-c-i-textbox h-full" :class="open ? 'open' : ''">
            <div class="overflow-x-hidden">
                <span class="text-label h-full">{{ text }}</span>
            </div>
        </div>
    </div>
</template>

<style scoped>
.character-card-content-item {
    @apply bg-warm-light ps-4 py-2 mb-1 rounded-r-full h-9;
    @apply flex items-center;
    width: auto;
    max-width: 20%;
    transition: max-width 0.4s ease-out;
}
.character-card-content-item.open {
    max-width: 100%;
    width: 100%;
}
.p-c-c-i-icon {
    @apply h-6 w-6 mr-4;
}
.p-c-c-i-textbox {
    @apply grid;
    grid-template-columns: 0fr;
    transition: grid-template-columns 0.4s ease-out;
}
.p-c-c-i-textbox.open {
    grid-template-columns: 1fr;
}
.text-label {
    @apply overflow-y-clip text-nowrap;
}
</style>
