<template>
    <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.731 0.68987C15.8505 1.58712 15.8505 3.05996 16.731 3.95721L28.2451 15.6892H2.2596C1.0135 15.6892 0 16.7218 0 17.9915V18.0254C0 19.2951 1.0135 20.3278 2.2596 20.3278H28.2451L16.731 32.0597C15.8505 32.957 15.8505 34.4298 16.731 35.3271C17.6116 36.2243 19.0571 36.2243 19.9377 35.3271L35.3396 19.6337C36.2201 18.7364 36.2201 17.2636 35.3396 16.3663L19.9377 0.672937C19.0571 -0.224312 17.6116 -0.224312 16.731 0.672937V0.68987Z"
            fill="currentColor"
        />
    </svg>
</template>
