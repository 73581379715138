<script setup>

import AppButton from "@/components/utilities/AppButton.vue";
import {ref} from "vue";
import {flip, offset, shift, useFloating, autoUpdate,} from "@floating-ui/vue";

const open = ref(false)

const button = ref(null)
const tooltip = ref(null)

const {floatingStyles} = useFloating(button, tooltip, {
    placement: 'right-start',
    middleware: [offset(7), flip(), shift()],
    whileElementsMounted: autoUpdate,
});

</script>

<template>
    <div class="absolute z-30">
        <AppButton ref="button" variant="icon" theme="transparentInherit" @click="open = !open">
            <template #icon>
                <img v-if="!open" src="/assets/icons/dir-role-tooltip-open.png"  alt="open tooltip icon"/>
                <img v-else src="/assets/icons/dir-role-tooltip-close.png"  alt="close tooltip icon"/>
            </template>
        </AppButton>
        <div ref="tooltip" v-if="open" class="role-tooltip" :style="floatingStyles">
            <h4 class="role-tooltip-role"><slot name="role"></slot></h4>
            <p class="role-tooltip-name"><slot name="name"></slot></p>
        </div>
    </div>
</template>

<style scoped>
.role-tooltip{
    @apply bg-warm w-52 rounded-3xl px-6 py-4
}

.role-tooltip-role{
    @apply w-full text-white text-xl font-medium text-center mb-5
}

.role-tooltip-name{
    @apply w-full text-black text-base font-normal
}
</style>
