<script setup>
import LayoutPrimaryPage from "@/components/home/LayoutPrimaryPage.vue";
import PageBreadcrumb from "@/components/utilities/PageBreadcrumb.vue";
import ContentNav from "@/components/content/ContentNav.vue";
import ContentContainer from "@/components/content/ContentContainer.vue";
import EndCapitolo from "@/components/content/ContentEndLevel.vue";
import {computed, onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {extractChapterInfoFromRoute, extractChapterRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {AppContent} from "@/content/data/content.js";



const route = useRoute()
const router = useRouter()

const UserProgression = useUserProgressionStore();

const chapterRouteInfo = ref(extractChapterRouteInfo())
const readChapters = ref(0)

const operaId = computed(() => chapterRouteInfo.value.operaInfo.id);
const levelId = computed(() => chapterRouteInfo.value.levelInfo.id);


const operaIndex = computed(() => chapterRouteInfo.value.operaInfo.index);
const levelIndex = computed(() => chapterRouteInfo.value.levelInfo.index);
const chapterIndex = computed(() => chapterRouteInfo.value.chapterInfo.index);

const currentLevel = AppContent.operas[operaIndex.value].levels[levelIndex.value];

const currentChapter = computed(() => {
    return currentLevel.chapters[chapterIndex.value];
})

const prevChapterLink = computed(() => {
    return isFirstChapterOrIsFinished.value
        ? `/app/${operaId.value}/${levelId.value}`
        : `/app/${operaId.value}/${levelId.value}/${currentLevel.chapters[chapterIndex.value - 1].prettyName}`
})

const isFirstChapterOrIsFinished = computed(() => chapterIndex.value === 0 || readChapters.value === currentLevel.chapters.length)

const currentContent = computed(() => currentChapter.value.component);

watch(() => route.params.chapterId, (newVal, oldVal) => {
    chapterRouteInfo.value = extractChapterInfoFromRoute(route);
    redirectIfLockedContent()
})

function redirectIfLockedContent(){
    if(UserProgression.levelProgression(operaIndex.value, levelIndex.value).state === "locked"){
        router.push(`/app/${operaId.value}`)
    }
    else {
        if(UserProgression.chapterProgression(operaIndex.value, levelIndex.value, chapterIndex.value).state === "locked"){
            router.push(`/app/${operaId.value}/${levelId.value}/${currentLevel.chapters[0].prettyName}`)
        }
    }
}

</script>

<template>
    <LayoutPrimaryPage>
        <div class="content">
            <div class="flex-grow-0 flex-shrink-0 px-4">
                <PageBreadcrumb :vertical="isFirstChapterOrIsFinished" :back-to="prevChapterLink">
                    <span class="uppercase">{{ currentLevel.title }}</span>
                    <span
                        class="capitalize"
                        v-if="readChapters < currentLevel.chapters.length"> - <span class="uppercase">{{ currentChapter.title }}</span>
                    </span>
                    <span class="capitalize" v-else> - Completato </span>
                </PageBreadcrumb>
            </div>
            <!-- capitolo container -->
            <ContentContainer v-if="readChapters !== currentLevel.chapters.length" :key="chapterIndex">
                <template #content>
                    <!-- capitoloContent -->
                    <Transition name="fade" mode="out-in" appear>
                        <div :key="route.params.chapterId">
                            <component v-model="readChapters" :chapter-index="chapterIndex" :is="currentContent"/>
                        </div>
                    </Transition>
                </template>
            </ContentContainer>
            <!-- capitolo end -->
            <EndCapitolo v-else :data="currentLevel"></EndCapitolo>
            <!-- nav -->
            <div class="" v-if="readChapters !== currentLevel.chapters.length">
                <ContentNav/>
            </div>
        </div>
    </LayoutPrimaryPage>
</template>

<style scoped>
.content {
    @apply container px-0 max-w-screen-md flex-grow flex flex-col overflow-hidden;
    max-height: 100vh;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.8s ease;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
