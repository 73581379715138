import {defineStore} from "pinia";
import { remote } from "@/libraries/remote/Remote.js";
import {AppContent} from "@/content/data/content.js";



export const useUserProgressionStore = defineStore("userProgression", {
    state: () => ({
        mainContents: [{id: 0, title: "", state: ""}],
        levels: [{id: 0, title: "", achievements: [], state: ""}],
        chapters: [{id: 0, title: "", state: ""}],
        achievements: [{id : 0}],
        glossaryWords: [{id: 0}],
        arias: [{id: 0}],
        directors: [{id : 0}],
    }),
    getters: {
        operaProgression(state){
            return (operaIndex) => {
                const opera = AppContent.operas[operaIndex]
                return state.mainContents.find(el => el.id === opera.id)
            }
        },

        operaUnlockedAchievements(state){
            return (operaIndex) => {
                const opera = AppContent.operas[operaIndex]
                return opera.levels.reduce((res, next, nextIndex) => res.concat(this.unlockedLevelAchievements(operaIndex, nextIndex)), [])
            }
        },

        levelProgression(state){
            return (operaIndex, levelIndex) => {
                const level = AppContent.operas[operaIndex].levels[levelIndex]
                return state.levels.find(el => el.id === level.id)
            }
        },

        isLevelMastered(state){
            return (operaIndex, levelIndex) => {
                const achievementsIds = new Set(AppContent.operas[operaIndex].levels[levelIndex].achievements.map(el => el.id))
                return state.achievements.filter(el => achievementsIds.has(el.id)).length === achievementsIds.size
            }
        },

        levelCompletionPerc(state){
            return (operaIndex, levelIndex) => {
                const chaptersId = new Set(AppContent.operas[operaIndex].levels[levelIndex].chapters.map(el => el.id))
                return state.chapters.filter(el => chaptersId.has(el.id) && el.state === "read").length / chaptersId.size
            }
        },

        unlockedLevelAchievements(state){
            return (operaIndex, levelIndex) => {
                const achievementsIds = new Set(AppContent.operas[operaIndex].levels[levelIndex].achievements.map(el => el.id))
                return state.achievements.filter(el => achievementsIds.has(el.id))
            }
        },

        isAchievementUnlocked(state){
            return (achievementId) => {
                return state.achievements.some(el => el.id === achievementId)
            }
        },

        chapterProgression(state){
            return (operaIndex, levelIndex, chapterIndex) => {
                const chapter = AppContent.operas[operaIndex].levels[levelIndex].chapters[chapterIndex]
                return state.chapters.find(el => el.id === chapter.id)
            }
        },

        isAriaListened(state) {
            return (ariaId) => {
                return state.arias.some(el => el.id === ariaId)
            }
        },

        isDirectorRevealed(state){
            return (directorId) => {
                return state.directors.some(el => el.id === directorId)
            }
        },

        isCharacterRevealed(state) {
            return(characterId) =>
            {
                return state.characters.some((el) => el.id === characterId);
            }
        },

        isGlossaryWordUnlocked(state) {
            return (wordId) => {
                return state.glossaryWords.some(el => el.id === wordId);
            }
        },

        glossaryGroupHasSomeWordsUnlocked(state) {
            return (groupIndex) => {
                const groupWordsIds = new Set(AppContent.glossary[groupIndex].items.map(el => el.id));
                if (state.glossaryWords) {
                    for(const word of state.glossaryWords) {
                        if (groupWordsIds.has(word.id)) {
                            return true
                        }
                    }
                }
                return false
            }
        },

        anyGlossaryWordUnlocked(state) {
            return () => {
                return state.glossaryWords.length > 0
            }
        },

        allAriasListened(state){
            return (operaIndex, levelIndex, chapterIndex) => {
                const ariasIds =
                    new Set(AppContent.operas[operaIndex].levels[levelIndex].chapters[chapterIndex].arias
                        .map(el => el.id))
                return state.arias.filter(el => ariasIds.has(el.id)).length === ariasIds.size
            }
        },

        allAutoimprestitiListened(state){
            return (operaIndex, levelIndex, chapterIndex) => {
                const autoimprestitiIds = new Set(AppContent.operas[operaIndex].levels[levelIndex].chapters[chapterIndex].autoimprestiti
                    .reduce((res, next) => res.concat(next.sources.map(el => el.id)), []))
                return state.autoimprestiti.filter(el => autoimprestitiIds.has(el.id)).length === autoimprestitiIds.size
            }
        },

        allCharactersUnlocked(state){
            return (operaIndex, levelIndex, chapterIndex) => {
                const charactersIds =
                    new Set(AppContent.operas[operaIndex].levels[levelIndex].chapters[chapterIndex].characters
                        .map(el => el.id))
                return state.characters.filter(el => charactersIds.has(el.id)).length === charactersIds.size
            }
        },

        allLevelGlossaryWordFound(state){
            return (operaIndex, levelIndex) =>{
                const wordsIds = new Set(AppContent.operas[operaIndex].levels[levelIndex].glossaryWords)
                return state.glossaryWords.filter(el => wordsIds.has(el.id)).length === wordsIds.size
            }
        }
    },
    actions: {
        setValues(setupValues){
            this.levels = setupValues.levels;
            this.mainContents = setupValues.mainContents;
            this.chapters = setupValues.chapters;
            this.achievements = setupValues.achievements;
            this.glossaryWords = setupValues.glossary_words;
            this.characters = setupValues.characters;
            this.arias = setupValues.arias
            this.directors = setupValues.directors
            this.autoimprestiti = setupValues.autoimprestiti
        },

        setLevelAsUnlocked(operaIndex, levelIndex) {
            const level = AppContent.operas[operaIndex].levels[levelIndex]
            const levelProgression = this.levels.find(el => el.id === level.id);
            if(levelProgression.state === "locked"){
                levelProgression.state = "unlocked";
                remote.updateUserLevelProgressionState(level.id, "unlocked")
            }
        },


        setChapterAsUnlocked(operaIndex, levelIndex, chapterIndex) {
            const chapter = AppContent.operas[operaIndex].levels[levelIndex].chapters[chapterIndex]
            const chapterProgression = this.chapters.find(el => el.id === chapter.id)
            if(chapterProgression.state === "locked"){
                chapterProgression.state = "unlocked";
                remote.updateUserChapterProgressionState(chapter.id, "unlocked")
            }
        },

        setChapterAsRead(operaIndex, levelIndex, chapterIndex) {
            const chapter = AppContent.operas[operaIndex].levels[levelIndex].chapters[chapterIndex]
            const chapterProgression = this.chapters.find(el => el.id === chapter.id)
            if(chapterProgression.state === "unlocked"){
                chapterProgression.state = "read";
                remote.updateUserChapterProgressionState(chapter.id, "read")
            }
            const level = AppContent.operas[operaIndex].levels[levelIndex]

            if(levelIndex < AppContent.operas[operaIndex].levels.length - 1 && this.levelCompletionPerc(operaIndex, levelIndex) >= 1){
                this.setLevelAsUnlocked(operaIndex, levelIndex + 1)
                this.setChapterAsUnlocked(operaIndex, levelIndex + 1, 0)
            }
        },

        unlockAchievementById(achievementId) {
            if(!this.achievements){
                this.achievements = []
            }
            const achievementsIds = new Set(this.achievements.map(el => el.id))
            if(!achievementsIds.has(achievementId)) {
                this.achievements.push({id: achievementId});
                remote.addAchievementToUser(achievementId)
            }
        },

        revealCharacterById(characterId) {
            if(!this.characters){
                this.characters = []
            }
            const charactersIds = new Set(this.characters.map(el => el.id))
            if(!charactersIds.has(characterId)) {
                this.characters.push({id : characterId});
                remote.addCharacterToUser(characterId)
            }
        },

        addGlossaryWord(wordId) {
            if(!this.glossaryWords){
                this.glossaryWords = []
            }
            const wordsIds = new Set(this.glossaryWords.map(el => el.id))
            if(!wordsIds.has(wordId)) {
                this.glossaryWords.push({id : wordId});
                remote.addGlossaryWordToUser(wordId)
            }
        },

        setAriaAsListened(ariaId){
            if(!this.arias){
                this.arias = []
            }
            const ariasIds = new Set(this.arias.map(el => el.id))
            if(!ariasIds.has(ariaId)){
                this.arias.push({id : ariaId})
                remote.addAriaToUser(ariaId)
            }
        },

        setAutoimprestitoAsListened(autoimprestitoId){
            if (!this.autoimprestiti){
                this.autoimprestiti = []
            }
            const autoimprestitiIds = new Set(this.autoimprestiti.map(el => el.id))
            if (!autoimprestitiIds.has(autoimprestitoId)){
                this.autoimprestiti.push({id : autoimprestitoId})
                remote.addAutoimprestitoToUser(autoimprestitoId)
            }
        },

        setDirectorAsRevealed(directorId){
            if (!this.directors){
                this.directors = []
            }
            const directorsIds = new Set(this.directors.map(el => el.id))
            if (!directorsIds.has(directorId)){
                this.directors.push({id : directorId})
                remote.addDirectorToUser(directorId)
            }
        }

    },
    persist: true,
});


