<script setup>
const props = defineProps({
    src : {type : String, required : true},
    nPages : {type : Number, required : true}
})
const emit = defineEmits(['download'])
</script>

<template>
    <div class="content-download-item-container">
        <div class="content-download-item">
            <h3 class="font-medium text-2xl leading-none"><slot name="title"></slot></h3>
            <p class="px-8 py-2"><slot name="description"></slot></p>
            <div class="flex justify-between py-4">
                <a :href="src" download @click="emit('download')">
                    <img src="/assets/icons/download-icon.png" alt="download button">
                </a>
                <div class="grid content-end">
                    <span>{{nPages}} pagine</span>
                </div>

            </div>
        </div>
    </div>
</template>

<style scoped>
.content-download-item-container{
    @apply px-8 pt-4 max-w-screen-sm mx-auto;
}
.content-download-item {
    @apply py-2 px-6 bg-warm-light rounded-3xl border-dashed border-warm border-2;
}

</style>
