import StoryO0L0 from "@/content/components/ilbarbieredisiviglia/level0/Story.vue";
import CharactersO0L0 from "@/content/components/ilbarbieredisiviglia/level0/Characters.vue";
import MusicO0L0 from "@/content/components/ilbarbieredisiviglia/level0/Music.vue";
import MinigameO0LO from "@/content/components/ilbarbieredisiviglia/level0/Minigame.vue";
import CharactersO0L1 from "@/content/components/ilbarbieredisiviglia/level1/Characters.vue";
import StoryO0L1 from "@/content/components/ilbarbieredisiviglia/level1/Story.vue";
import MusicO0L1 from "@/content/components/ilbarbieredisiviglia/level1/Music.vue";
import MinigameO0L1 from "@/content/components/ilbarbieredisiviglia/level1/Minigame.vue";
import StoryO0L2 from "@/content/components/ilbarbieredisiviglia/level2/Story.vue";
import CharactersO0L2 from "@/content/components/ilbarbieredisiviglia/level2/Characters.vue";
import MusicO0L2 from "@/content/components/ilbarbieredisiviglia/level2/Music.vue";
import MinigameO0L2 from "@/content/components/ilbarbieredisiviglia/level2/Minigame.vue";

export const chaptersMap = {
    1: {
        id: 1,
        prettyName: "storia",
        title: "Storia",
        component: StoryO0L0
    },
    2: {
        id: 2,
        prettyName: "personaggi",
        title: "Personaggi",
        characters: [1, 2, 3, 4, 5, 6],
        component: CharactersO0L0
    },
    3: {
        id: 3,
        prettyName: "musica",
        title: "Musica",
        instruments: [1, 2, 3, 4, 5, 6],
        arias: [1, 2, 3, 4, 5, 6],
        component: MusicO0L0
    },
    4: {
        id: 4,
        prettyName: "minigame",
        title: "ROFplay",
        component: MinigameO0LO
    },
    5: {
        id: 5,
        prettyName: "storia",
        title: "Storia",
        component: StoryO0L1
    },
    6: {
        id: 6,
        prettyName: "personaggi",
        title: "Personaggi",
        component: CharactersO0L1
    },
    7: {
        id: 7,
        prettyName: "musica",
        title: "Musica",
        component: MusicO0L1
    },
    8: {
        id: 8,
        prettyName: "minigame",
        title: "ROFplay",
        component: MinigameO0L1
    },
    9: {
        id: 9,
        prettyName: "storia",
        title: "Storia",
        component: StoryO0L2
    },
    10: {
        id: 10,
        prettyName: "personaggi",
        title: "Personaggi",
        component: CharactersO0L2
    },
    11: {
        id: 11,
        prettyName: "musica",
        title: "Musica",
        component: MusicO0L2,
        autoimprestiti: [1, 2, 3],
        arias: [7, 8, 9]
    },
    12: {
        id: 12,
        prettyName: "minigame",
        title: "ROFplay",
        component: MinigameO0L2
    },
}
