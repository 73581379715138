<script setup>
import AppDialog from "@/components/utilities/AppDialog.vue";
import {computed, ref} from "vue";
import DirectionCard from "@/components/content/direction/DirectionCard.vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";

const props = defineProps({
    director : { type : Object , required : true}
})

const emits = defineEmits(['revealed'])

const UserProgression = useUserProgressionStore()


const dialog = ref(null);

const cardBg = `url(${props.director.cardPic})`

function setRevealed() {
    dialog.value.openDialog()
    emits("revealed")
    UserProgression.setDirectorAsRevealed(props.director.id)
}

</script>

<template>
    <div
        class="direction-card-trigger"
        :style="UserProgression.isDirectorRevealed(props.director.id) ? `background-image: ${director.cardPic}` : ''"
        @click.self="setRevealed()"
        :class="UserProgression.isDirectorRevealed(props.director.id) ? 'revealed' : ''"
    >
        <!-- reveal trigger content -->
        <p class="direction-card-trigger-text">{{director.title}}</p>
        <!-- dialog shown on trigger's click -->
        <AppDialog ref="dialog" class="h-144">
            <!-- card  -->
            <DirectionCard @close-card="dialog.closeDialog()" :direction-card-info="director">
                <template #slides>
                    <slot name="slides"></slot>
                </template>
            </DirectionCard>
        </AppDialog>
    </div>
</template>

<style scoped>
.direction-card-trigger {
    @apply bg-black text-gold w-full h-full rounded-2xl overflow-clip;

    background-image: url("/assets/curtains/curtain-bg-left-new.png");
    background-size: cover;
    background-repeat: no-repeat;
}
.direction-card-trigger.revealed {
    /* @apply bg-green ; */
    background-image: v-bind(cardBg);
    background-position: center;
    background-size: cover;
}

.direction-card-trigger-text {
    @apply pl-5 pt-5 font-bold text-sm uppercase max-w-44;
    /* font-size: 0.8rem; */
}
</style>
