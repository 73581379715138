<template>
  <svg width="47" height="56" viewBox="0 0 47 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.5046 0.5H22.5715L22.5482 0.547347C10.2704 1.05156 0.5 11.3128 0.5 23.839C0.5 32.1832 4.83632 39.5142 11.3509 43.6276V51.8792C11.3509 54.6847 14.4533 56.51 16.8795 54.9126L16.8849 54.9091L23.3772 50.6908L23.3788 50.6898C23.5746 50.5643 23.8161 50.5636 24.0124 50.6876L30.3051 54.8498C32.7149 56.4367 35.8555 54.6706 35.8555 51.8186V43.4826C42.2639 39.3462 46.5 32.0768 46.5 23.8111C46.5 10.9644 36.2233 0.5 23.5046 0.5ZM3.5 23.839C3.5 12.6074 12.4751 3.52793 23.4954 3.52793V2.03019L23.5046 2.03471V3.5C34.5249 3.5 43.5 12.5794 43.5 23.8111C43.5 31.3042 39.5187 37.8405 33.5903 41.3572L32.8555 41.793V51.8186C32.8555 52.346 32.3177 52.5818 31.9563 52.3451L31.9551 52.3443L25.6509 48.1745L25.6437 48.1699C24.4558 47.4036 22.9387 47.4036 21.7509 48.1699L21.7468 48.1725L15.2408 52.3997L15.2311 52.4061C14.8961 52.6274 14.3509 52.4142 14.3509 51.8792V41.9177L13.6027 41.4843C7.57455 37.9927 3.5 31.3991 3.5 23.839ZM30.7399 27.6192C30.4875 26.8557 30.7674 26.0177 31.4006 25.5568H31.4051L36.6907 21.6881C38.1176 20.6592 37.3835 18.3873 35.6308 18.3873L29.0927 18.4339C28.3127 18.4339 27.6062 17.9264 27.3722 17.1629L25.3993 10.8454C24.8671 9.14149 22.4996 9.14149 21.9674 10.8454L19.9945 17.1629C19.7605 17.9264 19.0539 18.4339 18.2739 18.4339L11.7358 18.3873C9.98314 18.3734 9.24904 20.6592 10.676 21.6881L15.989 25.5568C16.6359 26.0224 16.902 26.8557 16.6497 27.6192L14.5896 33.9228C14.0299 35.6127 15.9431 37.0327 17.3563 35.9712L22.628 32.042C23.2612 31.5624 24.1284 31.5624 24.7615 32.042L30.0333 35.9712C31.4464 37.014 33.3597 35.6127 32.7999 33.9228L30.7399 27.6192Z"
      fill="currentColor"
    />
  </svg>
</template>
