export const charactersMap = {
    1 : {
        id : 1,
        name : "Conte D'Almaviva",
        mainStrength : "carisma",
        traits : "fascino, gentilezza",
        weaknesses : "mascheramento, travestimento",
        goal : "Conquistare il cuore di Rosina",
        weapon : "ricchezza",
        cardPic : "/assets/characters/almaviva-card-picture.png",
        cardPicMini : "/assets/characters/almaviva-card-picture-mini.png",
        profilePic : "/assets/characters/character-almaviva-profile.png",
        voiceType : "Tenore",
    },
    2 : {
        id : 2,
        name : "Bartolo",
        mainStrength : "dispotismo",
        traits : "gelosia",
        weaknesses : "avidità, sospettosità",
        goal : "Sposare Rosina per aumentare il proprio patrimonio",
        weapon : "status sociale",
        cardPic : "/assets/characters/bartolo-card-picture.png",
        cardPicMini : "/assets/characters/bartolo-card-picture-mini.png",
        profilePic : "/assets/characters/character-bartolo-profile.png",
        voiceType : "Basso",
    },
    3 : {
        id : 3,
        name : "Rosina",
        mainStrength : "grinta, tenerezza",
        traits : "intelligenza, vivacità",
        weaknesses : "orgoglio, capricci",
        goal : "Sfuggire al controllo di Bartolo per seguire il proprio cuore",
        weapon : "intraprendenza",
        cardPic : "/assets/characters/rosina-card-picture.png",
        cardPicMini : "/assets/characters/rosina-card-picture-mini.png",
        profilePic : "/assets/characters/character-rosina-profile.png",
        voiceType : "Mezzosoprano",
    },
    4 : {
        id : 4,
        name : "Figaro",
        mainStrength : "spirito d’iniziativa, intraprendenza",
        traits : "amore per la vita",
        weaknesses : "egocentrismo, inconcludenza",
        goal : "Aiutare il Conte d'Almaviva a conquistare Rosina",
        weapon : "spigliatezza, disinvoltura",
        cardPic : "/assets/characters/figaro-card-picture.png",
        cardPicMini : "/assets/characters/figaro-card-picture-mini.png",
        profilePic : "/assets/characters/character-figaro-profile.png",
        voiceType : "Baritono",
    },
    5 : {
        id : 5,
        name : "Basilio",
        mainStrength : "manipolazione",
        traits : "ipocrisia, avidità",
        weaknesses : "mancanza di dignità, servilismo",
        goal : "Mantenere il proprio status",
        weapon : "compiacenza, adulazione",
        cardPic : "/assets/characters/basilio-card-picture.png",
        cardPicMini : "/assets/characters/basilio-card-picture-mini.png",
        profilePic : "/assets/characters/character-basilio-profile.png",
        voiceType : "Basso",
    },
    6 : {
        id : 6,
        name : "Berta",
        mainStrength : "senso dell'umorismo",
        traits : "spirito d'iniziativa, umorismo",
        weaknesses : "rimpianto, nostalgia",
        goal : "Rivivere i sentimenti d’amore",
        weapon : "ironia",
        cardPic : "/assets/characters/berta-card-picture.png",
        cardPicMini : "/assets/characters/berta-card-picture-mini.png",
        profilePic : "/assets/characters/character-berta-profile.png",
        voiceType : "Soprano",
    }
}
