<script setup>

</script>

<template>
<div class="slide">
    <slot></slot>
</div>
</template>

<style scoped>
.slide{
    @apply absolute h-full w-full;
}
</style>
