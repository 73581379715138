<script setup>

import Title from "@/components/content/ContentTitle.vue";
import Textbox from "@/components/content/ContentTextbox.vue";
import Video from "@/components/content/ContentVideo.vue";
import {onMounted, ref} from "vue";
import {gsap} from "gsap";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";

const props = defineProps({
  sources : {type : Array, required : true}
})

const emits = defineEmits(["autoimprestito-played"])

gsap.registerPlugin(ScrollToPlugin);

const firstSlide = ref(true)
const videScroller = ref(null)

let videoScrollerSelector;

const nextSlide = () => {
    gsap.to(videScroller.value, {duration: 1, scrollTo: `#${props.sources[1].tag}`});
    firstSlide.value = false;
}

const prevSlide = () => {
    gsap.to(videScroller.value, {duration: 1, scrollTo: `#${props.sources[0].tag}`});
    firstSlide.value = true;
}

onMounted(() => {
    videoScrollerSelector = gsap.utils.selector(videScroller.value)
    gsap.to(videScroller.value, {duration: 0, scrollTo: `#${props.sources[0].tag}`});
})

function onAutoimprestitoVideoPlayed(id){
    emits("autoimprestito-played", id)
}

</script>

<template>
    <div class="mb-4">
        <div class="flex flex-row container max-w-screen-sm mx-auto px-6 pt-8 pb-6 font-medium text-green-light">
            <img class="mr-3 h-5 w-5" src="/assets/icons/character-paragraph-title-dot.png" alt="dot">
            <h1>
                <slot name="title"></slot>
            </h1>
        </div>
        <Textbox>
            <slot name="description"></slot>
        </Textbox>
        <div class="bg-green-light flex flex-col text-white relative mt-8">
            <div v-if="firstSlide" class="absolute right-5 bottom-32 z-10 hidden">
                <button @click="nextSlide"><img src="/assets/icons/next.png" alt="next slide btn image"></button>
            </div>
            <div v-else class="absolute left-5 bottom-32 z-10 hidden">
                <button @click="prevSlide"><img src="/assets/icons/prev.png" alt="next slide btn image"></button>
            </div>
            <div ref="videScroller" class="flex flex-row overflow-x-auto">
                <div v-for="source in sources" class="flex flex-col min-w-80 max-w-80 md:min-w-140 md:max-w-140 min-h-72 justify-between">
                    <div>
                        <h3 class="container max-w-screen-sm mx-auto pl-14 pr-4 pt-8 font-medium text-2xl leading-none" v-html="source.title">
                        </h3>
                        <p class="container max-w-screen-sm mx-auto px-14 mb-4 text-base font-normal" v-html="source.subtitle">
                        </p>
                    </div>

                    <Video
                        class="w-80 md:w-140"
                        width="320"
                        height="192"
                        controls
                        controlslist="nodownload noremoteplayback noplaybackrate"
                        :poster="source.poster"
                        :id="source.tag"
                        @play="onAutoimprestitoVideoPlayed(source.id)"
                    >
                        <template #sources>
                            <source :src="source.url"
                                    type="video/mp4"/>
                        </template>
                    </Video>
                </div>
            </div>
            <p class="container max-w-screen-sm mx-auto pl-14 mb-4">
                Scorri di lato per scoprire l’origine del brano
            </p>
        </div>
    </div>
</template>

<style scoped>
</style>
