<script setup>
import {defineModel, defineProps} from "vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {useUserNotificationsStore} from "@/stores/userNotificationStore.js";
import {extractChapterRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import Title from "@/components/content/ContentTitle.vue";
import Textbox from "@/components/content/ContentTextbox.vue";
import GlossaryWord from "@/components/glossary/GlossaryWordTooltip.vue";
import ImageBox from "@/components/content/ContentImageBox.vue";
import NextChapterCard from "@/components/content/ContentNextChapterCard.vue";
import FunFact from "@/components/content/ContentFunFact.vue";
import FunfactParagraph from "@/components/content/ContentFunfactParagraph.vue";
import DirectionTrigger from "@/components/content/direction/DirectionTrigger.vue";
import DirectionCardSlide from "@/components/content/direction/DirectionCardSlide.vue";
import RoleTooltip from "@/components/content/direction/RoleTooltip.vue";

const props = defineProps({
    chapterIndex : {type : Number, required : true}
})

const readChapters = defineModel()

const UserProgression = useUserProgressionStore()
const UserNotifications = useUserNotificationsStore()

const routeInfo = extractChapterRouteInfo()

const operaIndex = routeInfo.operaInfo.index
const levelIndex = routeInfo.levelInfo.index;

function onDirectorRevealed(id){

}

const ronconi = {
    id : 1,
    title : "Il barbiere di Siviglia di Luca Ronconi (2005)",
    shortTitle : "IL BARBIERE DI LUCA RONCONI",
    cardPic : "/assets/operas/barbieredisiviglia/level-2/direction/ronconi-trigger-bg.png",
}

const pizzi = {
    id : 2,
    title : "Il barbiere di Siviglia di Pier Luigi Pizzi (2018)",
    shortTitle: "IL BARBIERE DI PIER LUIGI PIZZI",
    cardPic : "/assets/operas/barbieredisiviglia/level-2/direction/pizzi-trigger-bg.png",
}

</script>

<template>
    <Title>Scambi di ruoli vocali nel <i class="italic">Barbiere.</i></Title>
    <Textbox>
        Nel <i class="italic">Barbiere</i> <GlossaryWord :word-id="33">autografo</GlossaryWord> di Rossini, custodito al Conservatorio di Bologna, oggi Museo Internazionale
        e Biblioteca della Musica, Rosina è interpretata da una voce femminile di mezzo-contralto
        (tipologia vocale che apparteneva a <GlossaryWord :word-id="41">Geltrude Righetti-Giorgi</GlossaryWord>, primadonna del Teatro Argentina nel 1816);
        Berta, di scrittura vocale più acuta di quella di Rosina, da un soprano, e Bartolo, importante basso,
        è impegnato in un'aria di gran difficoltà "A un dottor della mia sorte".
    </Textbox>

    <ImageBox align="right" height="h-80">
        <img src="/assets/operas/barbieredisiviglia/level-2/content-image-5-O0L2C1.png" alt=""  />
    </ImageBox>

    <Textbox class="mt-8">
        Accanto al <i class="italic">Barbiere</i> del debutto se ne conobbero altri profondamente modificati.<br>
        Le diverse situazioni
        richiedevano talvolta trasposizioni di interi brani, aggiunte, soppressioni, cambiamenti che modificavano
        il profilo stesso della partitura.<br>
        Ad esempio Rosina diventa soprano, Berta mezzosoprano e Bartolo un
        comprimario di poco conto.
    </Textbox>
    <Textbox>
        Tutto questo ci mostra quanto <i class="italic">Il barbiere di Siviglia</i> sia stato soggetto a variazioni nel corso del tempo.
        Confrontando testo e musica di diverse versioni, possiamo tracciare una storia affascinante di questo
        capolavoro rossiniano.
    </Textbox>

    <FunFact theme="green" hidden-bot-desc hidden-legacy-p :hidden-top-desc="false">
        <template #title>
            Le partiture del primo Ottocento non erano fatte per essere stampate.
        </template>
        <template #upper-description>
            <Textbox>
                Le partiture delle opere liriche italiane del primo Ottocento non venivano pubblicate immediatamente dopo
                la composizione da parte dell’autore, ma, il più delle volte, quando l’autore era già scomparso.
            </Textbox>
            <Textbox>
                Infatti né il compositore né l’editore avevano interesse a farlo per non fissarle in un testo rigido
                dal momento che l’opera lirica era considerata una composizione aperta, suscettibile a modifiche
                e la sua diffusione avveniva in situazioni molto diverse, con organici strumentali, corali e compagnie
                di canto che cambiavano da teatro a teatro.
            </Textbox>
        </template>
        <template #funcfact-p>
            <FunfactParagraph>
                <template #image>
                    <img src="/assets/operas/barbieredisiviglia/level-2/funfact-image-2-O0L2C1.png" alt="fun-fact related image"/>
                </template>
                <template #image-description>
                    <p>
                        Quando gli editori dovevano trasferire un manoscritto alla stampa, si trovavano di fronte ad un
                        compito arduo e di grande responsabilità: non potevano limitarsi a trascrivere acriticamente
                        l'autografo, spesso incompleto, ma dovevano mediare tra l'intenzione approssimativa dell'autore
                        e una traduzione grafica coerente.
                    </p>
                </template>
            </FunfactParagraph>
        </template>
    </FunFact>

    <Title>Mettere in scena il <i class="italic">Barbiere di Siviglia.</i></Title>
    <Textbox>
        Tra le rappresentazioni del <i class="italic">Barbiere di Siviglia</i> di Rossini più importanti,
        si ricordano quella del 2005 di <GlossaryWord :word-id="43">Luca Ronconi</GlossaryWord> e del 2018
        di <GlossaryWord :word-id="42">Pier Luigi Pizzi.</GlossaryWord>
    </Textbox>

    <div class="direction-trigger-container">
        <DirectionTrigger :director="ronconi" @revealed="onDirectorRevealed(1)">
            <template #slides>
                <DirectionCardSlide background-src="/assets/operas/barbieredisiviglia/level-2/direction/ronconi-slide-1.png" direction-notes>
                    <template #role-tooltips>
                        <RoleTooltip class="left-20 top-64">
                            <template #role>
                                Regia
                            </template>
                            <template #name>
                                Luca Ronconi
                            </template>
                        </RoleTooltip>
                        <RoleTooltip class="right-16 bottom-36">
                            <template #role>
                                Regista collaboratore
                            </template>
                            <template #name>
                                Ugo Tessitore
                            </template>
                        </RoleTooltip>
                    </template>
                    <template #direction-notes>
                        <p>
                            Nella regia di Ronconi i personaggi, in abiti contemporanei, si muovono in
                            un’ambientazione spagnola non stereotipata, più vicina alla Spagna del regista
                            Luis Buñuel che a quella del drammaturgo de Beaumarchais.
                        </p>
                        <p>
                            La scena è uno spazio neutro,
                            come un palcoscenico vuoto, uno studio o una sala prove. Alcuni personaggi salgono
                            in soffitta.
                        </p>
                        <p>
                            Nel finale del primo atto, la casa viene sconvolta quando il Conte
                            d’Almaviva si introduce fingendosi ubriaco.
                        </p>
                    </template>
                </DirectionCardSlide>
                <DirectionCardSlide background-src="/assets/operas/barbieredisiviglia/level-2/direction/ronconi-slide-2.png">
                    <template #role-tooltips>
                        <RoleTooltip class="right-7 top-36">
                            <template #role>
                                Costumi
                            </template>
                            <template #name>
                                Giovanna Buzzi
                            </template>
                        </RoleTooltip>
                    </template>
                </DirectionCardSlide>
                <DirectionCardSlide background-src="/assets/operas/barbieredisiviglia/level-2/direction/ronconi-slide-3.png">
                    <template #role-tooltips>
                        <RoleTooltip class="right-12 top-32">
                            <template #role>
                                Scene
                            </template>
                            <template #name>
                                Gae Aulenti
                            </template>
                        </RoleTooltip>
                    </template>
                </DirectionCardSlide>
                <DirectionCardSlide background-src="/assets/operas/barbieredisiviglia/level-2/direction/ronconi-slide-4.png">
                    <template #role-tooltips>
                        <RoleTooltip class="right-5 bottom-60">
                            <template #role>
                                Luogo rappresentazione
                            </template>
                            <template #name>
                                Palafestival
                            </template>
                        </RoleTooltip>
                        <RoleTooltip class="left-24 bottom-28">
                            <template #role>
                                Progetto luci
                            </template>
                            <template #name>
                                Guido Levi
                            </template>
                        </RoleTooltip>
                    </template>
                </DirectionCardSlide>
            </template>
        </DirectionTrigger>
    </div>

    <div class="direction-trigger-container">
        <DirectionTrigger :director="pizzi" @revealed="onDirectorRevealed(2)">
            <template #slides>
                <DirectionCardSlide background-src="/assets/operas/barbieredisiviglia/level-2/direction/pizzi-slide-1.png" direction-notes>
                    <template #role-tooltips>
                        <RoleTooltip class="right-10 top-44">
                            <template #role>
                                Regia, Scene e Costumi
                            </template>
                            <template #name>
                                Pier Luigi Pizzi
                            </template>
                        </RoleTooltip>
                    </template>
                    <template #direction-notes>
                        <p>
                            La regia di Pizzi vede l'opera come una commedia d'intrigo, ispirata a de Beaumarchais,
                            che esalta libertà e uguaglianza sociali con una visione illuministica.
                        </p>
                        <p>
                            È una commedia di
                            caratteri forti, non burattini. Rossini chiarisce tutto con la sua ironia, mantenendo la
                            comicità di personaggi negativi.
                        </p>
                        <p>
                            Si parla molto di denaro e poco d'amore, ma alla fine i
                            personaggi risultano simpatici o accettabili grazie a Rossini, che trasforma il male in
                            bene e rende i caratteri universali e riconoscibili.
                        </p>
                    </template>
                </DirectionCardSlide>
                <DirectionCardSlide background-src="/assets/operas/barbieredisiviglia/level-2/direction/pizzi-slide-2.png">
                    <template #role-tooltips>
                        <RoleTooltip class="right-16 top-56">
                            <template #role>
                                Regista collaboratore e Luci
                            </template>
                            <template #name>
                                Massimo Gasparon
                            </template>
                        </RoleTooltip>
                    </template>
                </DirectionCardSlide>
                <DirectionCardSlide background-src="/assets/operas/barbieredisiviglia/level-2/direction/pizzi-slide-3.png">
                    <template #role-tooltips>
                        <RoleTooltip class="right-16 top-56">
                            <template #role>
                                Luogo rappresentazione
                            </template>
                            <template #name>
                                Adriatic Arena
                            </template>
                        </RoleTooltip>
                    </template>
                </DirectionCardSlide>
                <DirectionCardSlide background-src="/assets/operas/barbieredisiviglia/level-2/direction/pizzi-slide-4.png">

                </DirectionCardSlide>
            </template>
        </DirectionTrigger>
    </div>

    <FunFact hidden-legacy-p hidden-bot-desc :hidden-top-desc="false" theme="green">
        <template #title>Gli antichi figurini utilizzati per i costumi del <i class="italic">Barbiere di Siviglia.</i>
        </template>
        <template #upper-description>
            <Textbox>
                Per i costumi dei personaggi da mettere in scena, venivano realizzati  dei figurini.<br>
                Eccone alcuni esempi delle rappresentazioni del <i class="italic">Barbiere</i> al Teatro dell’Opera di Roma nel 1825 e al
                Théâtre de L’Odéon di Parigi nel 1824.
            </Textbox>

        </template>
        <template #funcfact-p>
            <div class="max-w-screen-sm mx-auto">
                <FunfactParagraph desc-on-top image-w="w-1/2" text-w="w-1/2" class="mt-5">
                    <template #image>
                        <img class="ml-5 mr-3" src="/assets/operas/barbieredisiviglia/level-2/funfact-image-3-O0L2C1.png" alt="fun-fact related image"/>
                    </template>
                    <template #image-description>
                        <div class="mt-4 mr-2">
                            <p>
                                <i class="italic">Figurino per il costume di Rosina. Roma, Teatro dell’Opera, 1825 (Collezione Ragni, Napoli).</i>
                            </p>
                        </div>
                    </template>
                </FunfactParagraph>
                <FunfactParagraph desc-on-top right class="text-right mt-5" image-w="w-1/2" text-w="w-1/2">
                    <template #image>
                        <img class="mr-5 ml-3" src="/assets/operas/barbieredisiviglia/level-2/funfact-image-4-O0L2C1.png" alt="fun-fact related image"/>
                    </template>
                    <template #image-description>
                        <div class="mt-4 ml-2">
                            <p>
                                <i class="italic">Figurino per il costume del Conte di Almaviva. Parigi, Théâtre de L’Odéon (Collezione Ragni, Napoli).</i>
                            </p>
                        </div>
                    </template>
                </FunfactParagraph>
                <FunfactParagraph desc-on-top image-w="w-1/2" text-w="w-1/2" class="mt-5">
                    <template #image>
                        <img class="ml-5 mr-3" src="/assets/operas/barbieredisiviglia/level-2/funfact-image-5-O0L2C1.png" alt="fun-fact related image"/>
                    </template>
                    <template #image-description>
                        <div class="mt-4 mr-2">
                            <p>
                                <i class="italic">Luigi Lablache in costume di Figaro (Collezione Ragni, Napoli).</i>
                            </p>
                        </div>
                    </template>
                </FunfactParagraph>
                <FunfactParagraph desc-on-top right class="text-right mt-5" image-w="w-1/2" text-w="w-1/2" >
                    <template #image>
                        <img class="mr-5 ml-3" src="/assets/operas/barbieredisiviglia/level-2/funfact-image-8-O0L2C1.png" alt="fun-fact related image"/>
                    </template>
                    <template #image-description>
                        <div class="mt-4 ml-2">
                            <p>
                                <i class="italic">Figurino per il costume del Conte di Almaviva. Parigi,
                                    Théâtre de L’Odéon, 1824 (Collezione Ragni, Napoli).</i>
                            </p>
                        </div>
                    </template>
                </FunfactParagraph>
                <FunfactParagraph desc-on-top image-w="w-1/2" text-w="w-1/2" class="mt-5">
                    <template #image>
                        <img class="ml-5 mr-3" src="/assets/operas/barbieredisiviglia/level-2/funfact-image-6-O0L2C1.png" alt="fun-fact related image"/>
                    </template>
                    <template #image-description>
                        <div class="mt-4 mr-2">
                            <p>
                                <i class="italic">Giorgio Ronconi in costume di Figaro (Collezione Ragni, Napoli).</i>
                            </p>
                        </div>
                    </template>
                </FunfactParagraph>
                <FunfactParagraph desc-on-top right class="text-right mt-5" image-w="w-1/2" text-w="w-1/2">
                    <template #image>
                        <img class="mr-5 ml-3" src="/assets/operas/barbieredisiviglia/level-2/funfact-image-7-O0L2C1.png" alt="fun-fact related image"/>
                    </template>
                    <template #image-description>
                        <div class="mt-4 ml-2">
                            <p>
                                <i class="italic">Figurino per il costume di Rosina. Parigi, Théatre Royal Italien, 1819.</i>
                            </p>
                        </div>
                    </template>
                </FunfactParagraph>
            </div>
        </template>
    </FunFact>

    <NextChapterCard v-model="readChapters" :chapter-index="chapterIndex"/>

</template>

<style scoped>
    .direction-trigger-container{
        @apply px-5 w-full h-40 mb-6 max-w-screen-sm mx-auto;
    }
</style>
