<script setup>
import IconAchievements from "@/components/icons/IconAchievements.vue";
import IconTime from "@/components/icons/IconTime.vue";
/**
 *  Stats for level component
 */
const props = defineProps({
    data: { type: Object, required: true },
    progression: { type: Object, required: true },
    theme: { type: String, required: false },
    isReport : { type: Boolean, required: false, default : false },
});

const themes = {
    levelCard: { icon: "text-white", text : "text-warm-light"},
    levelPage: { icon: "text-warm", text : "text-green-light"}
};
const theme = props.theme in themes ? props.theme : "default";

</script>

<template>
    <div class="stats-item" :class="themes[theme].text">
        <div class="stat-item-icon">
            <IconAchievements class="w-full h-full" :class="themes[theme].icon" />
        </div>
        <p class="mb-0">
            <span>{{ progression.length }}</span>
            /
            <span>{{ data.achievements.length }}</span>
            achievements
        </p>
    </div>
    <div v-if="!isReport" class="stats-item" :class="themes[theme].text">
        <div class="stat-item-icon">
            <IconTime class="w-full h-full scale-90" :class="themes[theme].icon"/>
        </div>
        <p class="mb-0">
            <span>{{ data.duration }}</span>
        </p>
    </div>
</template>

<style scoped>
.stats-item {
    @apply flex items-center mb-1 font-medium;
}
.stat-item-icon {
    @apply w-8 h-8 mr-1;
}
</style>
