<script setup>

import LayoutSecondaryPage from "@/components/home/LayoutSecondaryPage.vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {extractOperaRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {computed} from "vue";
import {AppContent} from "@/content/data/content.js";
import PageBreadcrumb from "@/components/utilities/PageBreadcrumb.vue";


const UserProgression = useUserProgressionStore();

const operaRouteInfo = extractOperaRouteInfo()

const operaId = operaRouteInfo.id;
const operaIndex = operaRouteInfo.index;

const opera = computed(() => {
    return AppContent.operas[operaIndex]
})
</script>

<template>
    <LayoutSecondaryPage>
        <template #head>
            <div class="container max-w-screen-sm">
                <PageBreadcrumb back-to="/app" vertical theme="white">
                    {{opera.title}}
                </PageBreadcrumb>
            </div>
        </template>
        <template #main>
            <div class="epilogue-container">
                <component :is="opera.epilogue">

                </component>
            </div>
        </template>
    </LayoutSecondaryPage>
</template>

<style scoped>

</style>
