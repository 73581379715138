<script setup>
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {AppContent} from "@/content/data/content.js";
import {useUserStore} from "@/stores/userStore.js";
import AppButton from "@/components/utilities/AppButton.vue";
import {ref} from "vue";

const UserProgression = useUserProgressionStore();
const User = useUserStore();

const currentSelectedImage = ref(User.currentProfileImg)


console.log(currentSelectedImage.value)

function setSelectedProfileImage(id){
    //User.setProfileImg(id);
    currentSelectedImage.value = id;
}

function saveProfilePicture(){
    User.setProfileImg(currentSelectedImage.value);
}

function profileImageFromId(id){
    return UserProgression.isAchievementUnlocked(id)
        ? AppContent.achievementProfilePictures[id].default
        : '/assets/avatars/locked-avatar.png'
}


</script>

<template>
    <div>
        <div class="user-image-selector">
            <div
                class="achievement-item-profileimg unlocked"
                :class="{current: currentSelectedImage === 'default'}"
                role="button"
                tabindex="0"
                @click="setSelectedProfileImage('default')"
            >
                <img
                    class="achievement-item-img"
                    :src="AppContent.achievementProfilePictures.default.default"
                    alt="achievement icon"/>
            </div>
            <template v-for="(opera, oIndex) in AppContent.operas" :key="opera.title">
                <template v-for="(level, lIndex) in opera.levels">
                    <div
                        class="achievement-item-profileimg"
                        v-for="(item, iIndex) in level.achievements"
                        :key="item.title"
                        :class="{
                        unlocked: UserProgression.isAchievementUnlocked(item.id),
                        current: currentSelectedImage.toString() === item.id.toString()}"
                        role="button"
                        tabindex="0"
                        @click="setSelectedProfileImage(item.id)"
                    >
                        <img
                            class="achievement-item-img"
                            :src="profileImageFromId(item.id)"
                            alt="profile picture option"/>
                    </div>
                </template>
            </template>
        </div>
        <div class="flex justify-center">
            <AppButton @click="saveProfilePicture" variant="text" theme="roundedRedText" class="uppercase save-button" text="SALVA"/>
        </div>

    </div>
</template>

<style scoped>
.user-image-selector {
    @apply grid grid-cols-3 md:grid-cols-6 max-h-64 overflow-auto my-8 gap-4 place-content-between;
}

.achievement-item-profileimg {
    @apply w-20 h-20 flex rounded-full overflow-hidden justify-self-center opacity-35 pointer-events-none;
}

.achievement-item-profileimg.unlocked {
    @apply opacity-100 text-white pointer-events-auto;
}

.achievement-item-profileimg.unlocked.current {
    @apply border-warm-light border-2;
}

/* .achievement-item-textbox {
    @apply flex flex-col;
} */
.achievement-item-img {
    @apply w-20 h-20;
}

.achievement-item.unlocked .achievement-item-img {
    @apply opacity-100;
}

.save-button{
    @apply w-48
}
</style>
