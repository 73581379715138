<template>
    <svg
        width="46"
        height="45"
        viewBox="0 0 46 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M23 45C10.3159 45 0 34.9043 0 22.5C0 10.0957 10.3159 0 23 0C35.6841 0 46 10.0916 46 22.5C46 34.9084 35.6841 45 23 45ZM23 3.29791C12.1785 3.29791 3.3712 11.9137 3.3712 22.5C3.3712 33.0863 12.1785 41.7021 23 41.7021C33.8215 41.7021 42.6288 33.0863 42.6288 22.5C42.6288 11.9137 33.8215 3.29791 23 3.29791Z"
            fill="currentColor"
        />
        <path
            d="M23.0005 24.1488C22.0692 24.1488 21.3149 23.4109 21.3149 22.4999V8.12097C21.3149 7.20992 22.0692 6.47202 23.0005 6.47202C23.9318 6.47202 24.6861 7.20992 24.6861 8.12097V22.4999C24.6861 23.4109 23.9318 24.1488 23.0005 24.1488Z"
            fill="currentColor"
        />
        <path
            d="M23.0006 24.1488C22.4191 24.1488 21.8502 23.852 21.5384 23.3243C21.0748 22.5369 21.3488 21.5269 22.1536 21.0735L34.8841 13.884C35.689 13.4264 36.7214 13.6985 37.185 14.4859C37.6485 15.2733 37.3746 16.2832 36.5697 16.7367L23.8392 23.9262C23.5737 24.0746 23.283 24.1488 22.9964 24.1488H23.0006Z"
            fill="currentColor"
        />
    </svg>
</template>
