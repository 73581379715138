<template>
  <svg width="42" height="51" viewBox="0 0 42 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 0.356079C14.295 0.356079 8.90674 5.89023 8.90674 12.6584C8.90674 16.9996 11.1235 20.8331 14.4827 23.0241C6.24585 25.8104 0.319336 33.7315 0.319336 43.0236C0.319336 47.1962 3.64564 50.6393 7.81414 50.6393H34.1858C38.3483 50.6393 41.6806 47.2024 41.6806 43.0236C41.6806 33.7331 35.7542 25.8079 27.5127 23.0224C30.8744 20.8319 33.0932 16.9969 33.0932 12.6537C33.0932 5.88475 27.7041 0.356079 21 0.356079ZM20.7408 24.9579C20.827 24.9597 20.9134 24.9607 21 24.9607H21.4052C30.9516 25.181 38.6806 33.1648 38.6806 43.0236C38.6806 45.6008 36.6367 47.6393 34.1858 47.6393H7.81414C5.36016 47.6393 3.31934 45.5976 3.31934 43.0236C3.31934 33.1105 11.1313 25.0996 20.7408 24.9579ZM21.019 21.956C26.0039 21.9455 30.0932 17.8135 30.0932 12.6537C30.0932 7.48822 25.9944 3.35608 21 3.35608C16.0064 3.35608 11.9067 7.49202 11.9067 12.6584C11.9067 17.7245 15.849 21.7999 20.7105 21.956H20.9655C20.977 21.956 20.9885 21.956 21 21.956C21.0063 21.956 21.0126 21.956 21.019 21.956Z"
      fill="currentColor"
    />
  </svg>
</template>
