<script setup>
const props = defineProps({
    decos: { type: Array, required: true },
});
</script>

<template>
    <div class="opera-bg">
        <!-- limiter for large screens -->
        <div class="opera-bg-limiter">
            <!-- left half -->
            <div class="opera-decos-half">
                <template v-for="(item, index) in props.decos" :key="index">
                    <div
                        class="opera-deco -m-8"
                        v-if="index < Math.ceil(props.decos.length / 2)"
                    >
                        <img class="relative w-full" :src="item.img" />
                    </div>
                </template>
            </div>
            <!-- right half -->
            <div class="opera-decos-half">
                <template v-for="(item, index) in props.decos" :key="index">
                    <div
                        class="opera-deco -m-8"
                        v-if="index >= Math.ceil(props.decos.length / 2)"
                    >
                        <img class="relative w-full" :src="item.img" />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>
.opera-bg {
    @apply fixed -z-10 left-0 top-0 bottom-0 right-0 flex overflow-clip;
}
.opera-bg-limiter {
    @apply flex-grow w-full h-full flex mx-auto max-w-screen-xl;
}
.opera-decos-half {
    @apply h-full w-1/2 flex items-end justify-around;
}
/* .opera-deco {
    @apply -m-8;
} */
</style>
