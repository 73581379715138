<script setup>
import {gsap} from "gsap";
import {Draggable} from "gsap/Draggable";
import {onMounted, ref} from "vue";
import SlidesCardSlide from "@/components/utilities/SlidesCardSlide.vue";

const props = defineProps({
    directionNotes : {type : Boolean, required : false, default : false},
    backgroundSrc : {type : String, required : true},
})

gsap.registerPlugin(Draggable)

const directionNote = ref(null)
const directionNoteContainer = ref(null)

onMounted(() => {

    Draggable.create(directionNote.value, {
        type : "y",
        bounds : directionNoteContainer.value,
        allowNativeTouchScrolling: false,
    })

})

</script>

<template>
    <SlidesCardSlide>
        <img class="direction-slide-background" :src="backgroundSrc" alt="card-slide-background">
        <div class="direction-slide-halftone top">
            <img class="w-full" src="/assets/halftones/halftone-dir-top.png" alt="decoration-halftone">
        </div>
        <div class="direction-slide-halftone bottom">
            <img class="w-full" src="/assets/halftones/halftone-dir-bot.png" alt="decoration-halftone">
        </div>
        <slot  name="role-tooltips"></slot>
        <div v-if="directionNotes" ref="directionNoteContainer" class="direction-note-container">
            <div ref="directionNote" class="direction-note">
                <img class="mt-3 mb-1.5 w-11" src="/assets/icons/draggable-hint.png" alt="draggable element hint align-center">
                <h4 class="font-medium text-xs mb-2.5">NOTE DI REGIA</h4>
                <div class="mx-5">
                    <slot name="direction-notes"></slot>
                </div>
            </div>
        </div>
    </SlidesCardSlide>
</template>

<style scoped>
    .direction-slide-background{
        @apply w-full object-center object-cover absolute bottom-0 z-0;
    }

    .direction-slide-halftone{
        @apply absolute z-10 inset-x-0;
    }

    .direction-slide-halftone.top {
        @apply top-0;
    }

    .direction-slide-halftone.bottom{
        @apply bottom-0;
    }

    .direction-note-container{
        @apply absolute h-184 inset-x-10 -bottom-88;
    }

    .direction-note{
        @apply absolute h-100 w-full bottom-0 bg-warm-light rounded-t-3xl z-40 flex flex-col items-center;
    }
</style>
