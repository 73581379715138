<script setup>

</script>

<template>
    <svg width="475" height="476" viewBox="0 0 475 476" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1290_5134)">
            <rect y="238" width="335.572" height="335.572" rx="20" transform="rotate(-45 0 238)" fill="#F5515A"/>
            <path d="M170.29 199.436L170.182 199.4C158.5 199.4 147.3 204.055 139.047 212.332L90.1445 255.834C84.5024 260.826 80.9414 268.104 80.9414 276.225C80.9414 291.251 93.128 303.437 108.154 303.437C112.593 303.437 116.779 302.367 120.485 300.478L120.846 300.285C120.99 300.213 121.146 300.129 121.291 300.045L164.227 277.043L161.556 260.165C158.717 242.239 160.714 223.881 167.331 206.991L170.29 199.424V199.436Z" fill="#F7F0F5"/>
            <path d="M258.329 155.478C256.271 155.79 254.118 155.502 252.145 154.539L243.712 169.637C240.536 175.327 235.495 179.61 229.588 181.896C229.588 183.34 229.179 184.723 228.457 185.902C227.724 187.081 226.677 188.055 225.378 188.705C224.066 189.367 222.827 190.149 221.696 191.039C216.403 195.165 213.203 201.565 213.203 208.435V225.289C216.981 206.654 232.776 192.386 252.157 190.907H252.145L258.329 155.478Z" fill="#E5B798"/>
            <path d="M270.164 153.901L268.48 142.292C267.842 143.868 267.385 145.516 267.048 147.188C266.819 148.343 266.386 149.474 265.737 150.533C264.065 153.312 261.298 155.02 258.326 155.477L252.143 190.906H252.155C253.249 190.834 254.356 190.774 255.475 190.774L270.164 153.901Z" fill="#FFCAA6"/>
            <mask id="mask0_1290_5134" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="255" y="199" width="223" height="440">
                <path d="M466.799 354.783H461.999C408.453 354.783 365.252 313.952 359.622 261.825C359.237 258.216 359.033 254.571 359.009 250.878C358.852 221.981 335.008 198.691 305.907 199.424C304.127 199.473 302.37 199.605 300.638 199.834C275.675 203.034 256.09 224.279 255.488 249.927V255.594C255.476 277.056 259.518 287.714 265.293 296.821C270.093 304.412 275.711 310.608 281.63 316.286H281.606C297.57 331.757 307.242 353.688 307.242 377.665V484.132C307.242 517.071 317.528 547.616 335.08 572.711C363.063 612.747 409.487 638.925 462.036 638.925H477.795V355.336C474.174 354.975 470.517 354.783 466.799 354.783Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_1290_5134)">
                <path d="M477.795 536.391V638.925H462.036C409.488 638.925 363.063 612.747 335.081 572.71C317.529 547.615 307.243 517.071 307.243 484.132V377.664C307.243 353.688 297.57 331.757 281.606 316.286H281.63C275.711 310.608 270.093 304.412 265.293 296.821C259.519 287.714 255.477 277.055 255.477 255.593V249.927C256.09 224.279 275.675 203.033 300.638 199.833C302.37 199.605 304.127 199.472 305.907 199.424C335.008 198.69 358.852 221.981 359.009 250.877C359.033 254.571 359.237 258.216 359.622 261.813C365.253 313.952 408.453 354.783 462 354.783H466.8C470.517 354.783 474.174 354.975 477.795 355.336V536.391Z" fill="#DB4B55"/>
                <path d="M365.433 295.859C365.446 295.859 365.457 295.848 365.457 295.835C365.457 295.823 365.446 295.812 365.433 295.812C365.421 295.812 365.41 295.823 365.41 295.835C365.41 295.848 365.421 295.859 365.433 295.859Z" fill="#C63846"/>
                <path d="M372.217 298.324C372.229 298.324 372.24 298.313 372.24 298.3C372.24 298.287 372.229 298.277 372.217 298.277C372.204 298.277 372.193 298.287 372.193 298.3C372.193 298.313 372.204 298.324 372.217 298.324Z" fill="#C63846"/>
                <path d="M362.966 302.656C362.992 302.656 363.014 302.635 363.014 302.608C363.014 302.582 362.992 302.56 362.966 302.56C362.939 302.56 362.918 302.582 362.918 302.608C362.918 302.635 362.939 302.656 362.966 302.656Z" fill="#C63846"/>
                <path d="M369.753 305.207C369.819 305.207 369.873 305.153 369.873 305.087C369.873 305.02 369.819 304.967 369.753 304.967C369.687 304.967 369.633 305.02 369.633 305.087C369.633 305.153 369.687 305.207 369.753 305.207Z" fill="#C63846"/>
                <path d="M376.538 307.637C376.585 307.637 376.623 307.599 376.623 307.552C376.623 307.506 376.585 307.468 376.538 307.468C376.491 307.468 376.453 307.506 376.453 307.552C376.453 307.599 376.491 307.637 376.538 307.637Z" fill="#C63846"/>
                <path d="M353.715 306.949C353.727 306.949 353.738 306.939 353.738 306.926C353.738 306.913 353.727 306.903 353.715 306.903C353.702 306.903 353.691 306.913 353.691 306.926C353.691 306.939 353.702 306.949 353.715 306.949Z" fill="#C63846"/>
                <path d="M360.501 309.478C360.548 309.478 360.586 309.44 360.586 309.393C360.586 309.346 360.548 309.308 360.501 309.308C360.454 309.308 360.416 309.346 360.416 309.393C360.416 309.44 360.454 309.478 360.501 309.478Z" fill="#C63846"/>
                <path d="M367.285 312.003C367.364 312.003 367.429 311.938 367.429 311.859C367.429 311.779 367.364 311.715 367.285 311.715C367.205 311.715 367.141 311.779 367.141 311.859C367.141 311.938 367.205 312.003 367.285 312.003Z" fill="#C63846"/>
                <path d="M374.059 314.555C374.179 314.555 374.276 314.458 374.276 314.338C374.276 314.218 374.179 314.121 374.059 314.121C373.939 314.121 373.842 314.218 373.842 314.338C373.842 314.458 373.939 314.555 374.059 314.555Z" fill="#C63846"/>
                <path d="M380.842 317.021C380.962 317.021 381.059 316.924 381.059 316.804C381.059 316.684 380.962 316.587 380.842 316.587C380.722 316.587 380.625 316.684 380.625 316.804C380.625 316.924 380.722 317.021 380.842 317.021Z" fill="#C63846"/>
                <path d="M387.632 319.355C387.679 319.355 387.716 319.317 387.716 319.27C387.716 319.223 387.679 319.185 387.632 319.185C387.585 319.185 387.547 319.223 387.547 319.27C387.547 319.317 387.585 319.355 387.632 319.355Z" fill="#C63846"/>
                <path d="M351.248 313.746C351.267 313.746 351.283 313.73 351.283 313.711C351.283 313.692 351.267 313.676 351.248 313.676C351.229 313.676 351.213 313.692 351.213 313.711C351.213 313.73 351.229 313.746 351.248 313.746Z" fill="#C63846"/>
                <path d="M358.037 316.289C358.097 316.289 358.146 316.24 358.146 316.18C358.146 316.12 358.097 316.071 358.037 316.071C357.977 316.071 357.928 316.12 357.928 316.18C357.928 316.24 357.977 316.289 358.037 316.289Z" fill="#C63846"/>
                <path d="M364.807 318.812C364.9 318.812 364.975 318.737 364.975 318.644C364.975 318.551 364.9 318.476 364.807 318.476C364.714 318.476 364.639 318.551 364.639 318.644C364.639 318.737 364.714 318.812 364.807 318.812Z" fill="#C63846"/>
                <path d="M371.592 321.351C371.725 321.351 371.833 321.243 371.833 321.11C371.833 320.978 371.725 320.87 371.592 320.87C371.459 320.87 371.352 320.978 371.352 321.11C371.352 321.243 371.459 321.351 371.592 321.351Z" fill="#C63846"/>
                <path d="M378.377 323.902C378.55 323.902 378.69 323.762 378.69 323.589C378.69 323.416 378.55 323.276 378.377 323.276C378.204 323.276 378.064 323.416 378.064 323.589C378.064 323.762 378.204 323.902 378.377 323.902Z" fill="#C63846"/>
                <path d="M385.162 326.428C385.368 326.428 385.535 326.261 385.535 326.055C385.535 325.849 385.368 325.682 385.162 325.682C384.956 325.682 384.789 325.849 384.789 326.055C384.789 326.261 384.956 326.428 385.162 326.428Z" fill="#C63846"/>
                <path d="M391.948 328.846C392.127 328.846 392.273 328.7 392.273 328.521C392.273 328.342 392.127 328.196 391.948 328.196C391.768 328.196 391.623 328.342 391.623 328.521C391.623 328.7 391.768 328.846 391.948 328.846Z" fill="#C63846"/>
                <path d="M398.721 331.179C398.828 331.179 398.914 331.093 398.914 330.987C398.914 330.881 398.828 330.795 398.721 330.795C398.615 330.795 398.529 330.881 398.529 330.987C398.529 331.093 398.615 331.179 398.721 331.179Z" fill="#C63846"/>
                <path d="M405.507 333.513C405.533 333.513 405.555 333.492 405.555 333.466C405.555 333.439 405.533 333.418 405.507 333.418C405.48 333.418 405.459 333.439 405.459 333.466C405.459 333.492 405.48 333.513 405.507 333.513Z" fill="#C63846"/>
                <path d="M452.991 350.827C453.038 350.827 453.076 350.789 453.076 350.742C453.076 350.695 453.038 350.657 452.991 350.657C452.944 350.657 452.906 350.695 452.906 350.742C452.906 350.789 452.944 350.827 452.991 350.827Z" fill="#C63846"/>
                <path d="M459.775 353.556C459.967 353.556 460.124 353.4 460.124 353.207C460.124 353.015 459.967 352.858 459.775 352.858C459.582 352.858 459.426 353.015 459.426 353.207C459.426 353.4 459.582 353.556 459.775 353.556Z" fill="#C63846"/>
                <path d="M466.56 356.48C467.005 356.48 467.366 356.119 467.366 355.674C467.366 355.228 467.005 354.868 466.56 354.868C466.115 354.868 465.754 355.228 465.754 355.674C465.754 356.119 466.115 356.48 466.56 356.48Z" fill="#C63846"/>
                <path d="M473.332 359.367C474.003 359.367 474.547 358.823 474.547 358.152C474.547 357.481 474.003 356.937 473.332 356.937C472.661 356.937 472.117 357.481 472.117 358.152C472.117 358.823 472.661 359.367 473.332 359.367Z" fill="#C63846"/>
                <path d="M348.784 320.556C348.817 320.556 348.844 320.529 348.844 320.496C348.844 320.463 348.817 320.437 348.784 320.437C348.751 320.437 348.725 320.463 348.725 320.496C348.725 320.529 348.751 320.556 348.784 320.556Z" fill="#C63846"/>
                <path d="M355.558 323.095C355.631 323.095 355.69 323.036 355.69 322.963C355.69 322.89 355.631 322.831 355.558 322.831C355.485 322.831 355.426 322.89 355.426 322.963C355.426 323.036 355.485 323.095 355.558 323.095Z" fill="#C63846"/>
                <path d="M362.339 325.633C362.452 325.633 362.543 325.541 362.543 325.429C362.543 325.316 362.452 325.224 362.339 325.224C362.226 325.224 362.135 325.316 362.135 325.429C362.135 325.541 362.226 325.633 362.339 325.633Z" fill="#C63846"/>
                <path d="M369.128 328.159C369.274 328.159 369.393 328.041 369.393 327.895C369.393 327.749 369.274 327.63 369.128 327.63C368.982 327.63 368.863 327.749 368.863 327.895C368.863 328.041 368.982 328.159 369.128 328.159Z" fill="#C63846"/>
                <path d="M375.913 330.698C376.099 330.698 376.25 330.547 376.25 330.361C376.25 330.175 376.099 330.024 375.913 330.024C375.727 330.024 375.576 330.175 375.576 330.361C375.576 330.547 375.727 330.698 375.913 330.698Z" fill="#C63846"/>
                <path d="M382.694 333.249C382.92 333.249 383.103 333.065 383.103 332.84C383.103 332.614 382.92 332.431 382.694 332.431C382.468 332.431 382.285 332.614 382.285 332.84C382.285 333.065 382.468 333.249 382.694 333.249Z" fill="#C63846"/>
                <path d="M389.469 335.787C389.735 335.787 389.951 335.572 389.951 335.306C389.951 335.04 389.735 334.825 389.469 334.825C389.204 334.825 388.988 335.04 388.988 335.306C388.988 335.572 389.204 335.787 389.469 335.787Z" fill="#C63846"/>
                <path d="M396.255 338.326C396.56 338.326 396.808 338.078 396.808 337.772C396.808 337.467 396.56 337.219 396.255 337.219C395.949 337.219 395.701 337.467 395.701 337.772C395.701 338.078 395.949 338.326 396.255 338.326Z" fill="#C63846"/>
                <path d="M403.039 340.828C403.364 340.828 403.628 340.564 403.628 340.239C403.628 339.913 403.364 339.649 403.039 339.649C402.713 339.649 402.449 339.913 402.449 340.239C402.449 340.564 402.713 340.828 403.039 340.828Z" fill="#C63846"/>
                <path d="M409.825 343.282C410.137 343.282 410.391 343.029 410.391 342.717C410.391 342.404 410.137 342.151 409.825 342.151C409.513 342.151 409.26 342.404 409.26 342.717C409.26 343.029 409.513 343.282 409.825 343.282Z" fill="#C63846"/>
                <path d="M416.611 345.688C416.89 345.688 417.116 345.462 417.116 345.183C417.116 344.904 416.89 344.678 416.611 344.678C416.332 344.678 416.105 344.904 416.105 345.183C416.105 345.462 416.332 345.688 416.611 345.688Z" fill="#C63846"/>
                <path d="M423.396 348.154C423.675 348.154 423.901 347.928 423.901 347.649C423.901 347.37 423.675 347.143 423.396 347.143C423.117 347.143 422.891 347.37 422.891 347.649C422.891 347.928 423.117 348.154 423.396 348.154Z" fill="#C63846"/>
                <path d="M430.168 350.644C430.46 350.644 430.697 350.407 430.697 350.115C430.697 349.823 430.46 349.586 430.168 349.586C429.876 349.586 429.639 349.823 429.639 350.115C429.639 350.407 429.876 350.644 430.168 350.644Z" fill="#C63846"/>
                <path d="M436.954 353.231C437.313 353.231 437.604 352.94 437.604 352.582C437.604 352.223 437.313 351.932 436.954 351.932C436.596 351.932 436.305 352.223 436.305 352.582C436.305 352.94 436.596 353.231 436.954 353.231Z" fill="#C63846"/>
                <path d="M443.739 355.914C444.211 355.914 444.593 355.532 444.593 355.06C444.593 354.588 444.211 354.206 443.739 354.206C443.267 354.206 442.885 354.588 442.885 355.06C442.885 355.532 443.267 355.914 443.739 355.914Z" fill="#C63846"/>
                <path d="M450.523 358.573C451.101 358.573 451.57 358.105 451.57 357.526C451.57 356.948 451.101 356.48 450.523 356.48C449.945 356.48 449.477 356.948 449.477 357.526C449.477 358.105 449.945 358.573 450.523 358.573Z" fill="#C63846"/>
                <path d="M457.307 361.147C457.945 361.147 458.462 360.63 458.462 359.992C458.462 359.354 457.945 358.837 457.307 358.837C456.669 358.837 456.152 359.354 456.152 359.992C456.152 360.63 456.669 361.147 457.307 361.147Z" fill="#C63846"/>
                <path d="M464.081 363.686C464.758 363.686 465.308 363.136 465.308 362.459C465.308 361.781 464.758 361.231 464.081 361.231C463.403 361.231 462.854 361.781 462.854 362.459C462.854 363.136 463.403 363.686 464.081 363.686Z" fill="#C63846"/>
                <path d="M470.866 366.224C471.583 366.224 472.165 365.642 472.165 364.924C472.165 364.207 471.583 363.625 470.866 363.625C470.148 363.625 469.566 364.207 469.566 364.924C469.566 365.642 470.148 366.224 470.866 366.224Z" fill="#C63846"/>
                <path d="M339.531 324.826C339.544 324.826 339.554 324.815 339.554 324.803C339.554 324.79 339.544 324.779 339.531 324.779C339.518 324.779 339.508 324.79 339.508 324.803C339.508 324.815 339.518 324.826 339.531 324.826Z" fill="#C63846"/>
                <path d="M346.307 327.356C346.354 327.356 346.392 327.318 346.392 327.271C346.392 327.224 346.354 327.186 346.307 327.186C346.261 327.186 346.223 327.224 346.223 327.271C346.223 327.318 346.261 327.356 346.307 327.356Z" fill="#C63846"/>
                <path d="M353.09 329.904C353.176 329.904 353.246 329.834 353.246 329.748C353.246 329.662 353.176 329.592 353.09 329.592C353.003 329.592 352.934 329.662 352.934 329.748C352.934 329.834 353.003 329.904 353.09 329.904Z" fill="#C63846"/>
                <path d="M359.876 332.444C360.002 332.444 360.105 332.342 360.105 332.215C360.105 332.089 360.002 331.986 359.876 331.986C359.749 331.986 359.646 332.089 359.646 332.215C359.646 332.342 359.749 332.444 359.876 332.444Z" fill="#C63846"/>
                <path d="M366.662 334.969C366.821 334.969 366.95 334.84 366.95 334.68C366.95 334.521 366.821 334.392 366.662 334.392C366.502 334.392 366.373 334.521 366.373 334.68C366.373 334.84 366.502 334.969 366.662 334.969Z" fill="#C63846"/>
                <path d="M373.447 337.508C373.646 337.508 373.808 337.347 373.808 337.148C373.808 336.948 373.646 336.787 373.447 336.787C373.248 336.787 373.086 336.948 373.086 337.148C373.086 337.347 373.248 337.508 373.447 337.508Z" fill="#C63846"/>
                <path d="M380.22 340.059C380.459 340.059 380.653 339.865 380.653 339.626C380.653 339.387 380.459 339.193 380.22 339.193C379.981 339.193 379.787 339.387 379.787 339.626C379.787 339.865 379.981 340.059 380.22 340.059Z" fill="#C63846"/>
                <path d="M387.001 342.596C387.28 342.596 387.507 342.37 387.507 342.091C387.507 341.812 387.28 341.586 387.001 341.586C386.722 341.586 386.496 341.812 386.496 342.091C386.496 342.37 386.722 342.596 387.001 342.596Z" fill="#C63846"/>
                <path d="M393.788 345.123C394.1 345.123 394.354 344.87 394.354 344.557C394.354 344.245 394.1 343.992 393.788 343.992C393.476 343.992 393.223 344.245 393.223 344.557C393.223 344.87 393.476 345.123 393.788 345.123Z" fill="#C63846"/>
                <path d="M400.575 347.661C400.927 347.661 401.213 347.376 401.213 347.024C401.213 346.672 400.927 346.386 400.575 346.386C400.223 346.386 399.938 346.672 399.938 347.024C399.938 347.376 400.223 347.661 400.575 347.661Z" fill="#C63846"/>
                <path d="M407.356 350.2C407.748 350.2 408.066 349.882 408.066 349.49C408.066 349.098 407.748 348.781 407.356 348.781C406.964 348.781 406.646 349.098 406.646 349.49C406.646 349.882 406.964 350.2 407.356 350.2Z" fill="#C63846"/>
                <path d="M414.132 352.75C414.563 352.75 414.914 352.4 414.914 351.968C414.914 351.536 414.563 351.186 414.132 351.186C413.7 351.186 413.35 351.536 413.35 351.968C413.35 352.4 413.7 352.75 414.132 352.75Z" fill="#C63846"/>
                <path d="M420.916 355.277C421.381 355.277 421.758 354.9 421.758 354.434C421.758 353.969 421.381 353.592 420.916 353.592C420.451 353.592 420.074 353.969 420.074 354.434C420.074 354.9 420.451 355.277 420.916 355.277Z" fill="#C63846"/>
                <path d="M427.701 357.815C428.206 357.815 428.616 357.406 428.616 356.901C428.616 356.396 428.206 355.986 427.701 355.986C427.196 355.986 426.787 356.396 426.787 356.901C426.787 357.406 427.196 357.815 427.701 357.815Z" fill="#C63846"/>
                <path d="M434.486 360.353C435.031 360.353 435.473 359.911 435.473 359.366C435.473 358.822 435.031 358.38 434.486 358.38C433.942 358.38 433.5 358.822 433.5 359.366C433.5 359.911 433.942 360.353 434.486 360.353Z" fill="#C63846"/>
                <path d="M441.271 362.879C441.849 362.879 442.318 362.41 442.318 361.832C442.318 361.254 441.849 360.786 441.271 360.786C440.693 360.786 440.225 361.254 440.225 361.832C440.225 362.41 440.693 362.879 441.271 362.879Z" fill="#C63846"/>
                <path d="M448.056 365.43C448.674 365.43 449.175 364.929 449.175 364.311C449.175 363.693 448.674 363.192 448.056 363.192C447.438 363.192 446.938 363.693 446.938 364.311C446.938 364.929 447.438 365.43 448.056 365.43Z" fill="#C63846"/>
                <path d="M454.83 367.969C455.487 367.969 456.021 367.436 456.021 366.778C456.021 366.12 455.487 365.587 454.83 365.587C454.172 365.587 453.639 366.12 453.639 366.778C453.639 367.436 454.172 367.969 454.83 367.969Z" fill="#C63846"/>
                <path d="M461.615 370.507C462.312 370.507 462.878 369.941 462.878 369.244C462.878 368.546 462.312 367.98 461.615 367.98C460.917 367.98 460.352 368.546 460.352 369.244C460.352 369.941 460.917 370.507 461.615 370.507Z" fill="#C63846"/>
                <path d="M468.4 373.045C469.137 373.045 469.735 372.447 469.735 371.71C469.735 370.972 469.137 370.375 468.4 370.375C467.662 370.375 467.064 370.972 467.064 371.71C467.064 372.447 467.662 373.045 468.4 373.045Z" fill="#C63846"/>
                <path d="M475.184 375.403C475.862 375.403 476.411 374.853 476.411 374.176C476.411 373.498 475.862 372.949 475.184 372.949C474.506 372.949 473.957 373.498 473.957 374.176C473.957 374.853 474.506 375.403 475.184 375.403Z" fill="#C63846"/>
                <path d="M337.054 331.636C337.08 331.636 337.101 331.615 337.101 331.589C337.101 331.562 337.08 331.541 337.054 331.541C337.027 331.541 337.006 331.562 337.006 331.589C337.006 331.615 337.027 331.636 337.054 331.636Z" fill="#C63846"/>
                <path d="M343.839 334.175C343.905 334.175 343.959 334.122 343.959 334.055C343.959 333.989 343.905 333.935 343.839 333.935C343.773 333.935 343.719 333.989 343.719 334.055C343.719 334.122 343.773 334.175 343.839 334.175Z" fill="#C63846"/>
                <path d="M350.623 336.702C350.723 336.702 350.804 336.621 350.804 336.522C350.804 336.422 350.723 336.341 350.623 336.341C350.524 336.341 350.443 336.422 350.443 336.522C350.443 336.621 350.524 336.702 350.623 336.702Z" fill="#C63846"/>
                <path d="M357.409 339.252C357.548 339.252 357.662 339.139 357.662 338.999C357.662 338.859 357.548 338.746 357.409 338.746C357.269 338.746 357.156 338.859 357.156 338.999C357.156 339.139 357.269 339.252 357.409 339.252Z" fill="#C63846"/>
                <path d="M364.194 341.79C364.373 341.79 364.519 341.645 364.519 341.465C364.519 341.286 364.373 341.141 364.194 341.141C364.015 341.141 363.869 341.286 363.869 341.465C363.869 341.645 364.015 341.79 364.194 341.79Z" fill="#C63846"/>
                <path d="M370.969 344.317C371.182 344.317 371.354 344.144 371.354 343.932C371.354 343.719 371.182 343.547 370.969 343.547C370.756 343.547 370.584 343.719 370.584 343.932C370.584 344.144 370.756 344.317 370.969 344.317Z" fill="#C63846"/>
                <path d="M377.752 346.855C378.005 346.855 378.209 346.651 378.209 346.398C378.209 346.146 378.005 345.941 377.752 345.941C377.5 345.941 377.295 346.146 377.295 346.398C377.295 346.651 377.5 346.855 377.752 346.855Z" fill="#C63846"/>
                <path d="M384.537 349.405C384.829 349.405 385.066 349.168 385.066 348.876C385.066 348.584 384.829 348.347 384.537 348.347C384.245 348.347 384.008 348.584 384.008 348.876C384.008 349.168 384.245 349.405 384.537 349.405Z" fill="#C63846"/>
                <path d="M391.322 351.945C391.654 351.945 391.924 351.675 391.924 351.343C391.924 351.011 391.654 350.742 391.322 350.742C390.99 350.742 390.721 351.011 390.721 351.343C390.721 351.675 390.99 351.945 391.322 351.945Z" fill="#C63846"/>
                <path d="M398.109 354.471C398.474 354.471 398.771 354.175 398.771 353.81C398.771 353.444 398.474 353.148 398.109 353.148C397.744 353.148 397.447 353.444 397.447 353.81C397.447 354.175 397.744 354.471 398.109 354.471Z" fill="#C63846"/>
                <path d="M404.882 357.008C405.288 357.008 405.616 356.68 405.616 356.274C405.616 355.869 405.288 355.541 404.882 355.541C404.477 355.541 404.148 355.869 404.148 356.274C404.148 356.68 404.477 357.008 404.882 357.008Z" fill="#C63846"/>
                <path d="M411.665 359.547C412.111 359.547 412.471 359.186 412.471 358.741C412.471 358.296 412.111 357.935 411.665 357.935C411.22 357.935 410.859 358.296 410.859 358.741C410.859 359.186 411.22 359.547 411.665 359.547Z" fill="#C63846"/>
                <path d="M418.45 362.085C418.929 362.085 419.316 361.697 419.316 361.219C419.316 360.74 418.929 360.352 418.45 360.352C417.972 360.352 417.584 360.74 417.584 361.219C417.584 361.697 417.972 362.085 418.45 362.085Z" fill="#C63846"/>
                <path d="M425.237 364.624C425.755 364.624 426.176 364.204 426.176 363.686C426.176 363.168 425.755 362.747 425.237 362.747C424.719 362.747 424.299 363.168 424.299 363.686C424.299 364.204 424.719 364.624 425.237 364.624Z" fill="#C63846"/>
                <path d="M432.018 367.163C432.576 367.163 433.029 366.71 433.029 366.152C433.029 365.594 432.576 365.142 432.018 365.142C431.46 365.142 431.008 365.594 431.008 366.152C431.008 366.71 431.46 367.163 432.018 367.163Z" fill="#C63846"/>
                <path d="M438.805 369.701C439.403 369.701 439.888 369.216 439.888 368.618C439.888 368.02 439.403 367.535 438.805 367.535C438.207 367.535 437.723 368.02 437.723 368.618C437.723 369.216 438.207 369.701 438.805 369.701Z" fill="#C63846"/>
                <path d="M445.579 372.24C446.217 372.24 446.734 371.723 446.734 371.085C446.734 370.447 446.217 369.93 445.579 369.93C444.941 369.93 444.424 370.447 444.424 371.085C444.424 371.723 444.941 372.24 445.579 372.24Z" fill="#C63846"/>
                <path d="M452.363 374.777C453.035 374.777 453.579 374.233 453.579 373.562C453.579 372.891 453.035 372.347 452.363 372.347C451.692 372.347 451.148 372.891 451.148 373.562C451.148 374.233 451.692 374.777 452.363 374.777Z" fill="#C63846"/>
                <path d="M459.148 377.304C459.853 377.304 460.423 376.733 460.423 376.029C460.423 375.324 459.853 374.753 459.148 374.753C458.444 374.753 457.873 375.324 457.873 376.029C457.873 376.733 458.444 377.304 459.148 377.304Z" fill="#C63846"/>
                <path d="M465.933 379.842C466.677 379.842 467.281 379.239 467.281 378.495C467.281 377.751 466.677 377.147 465.933 377.147C465.189 377.147 464.586 377.751 464.586 378.495C464.586 379.239 465.189 379.842 465.933 379.842Z" fill="#C63846"/>
                <path d="M472.718 382.381C473.502 382.381 474.138 381.745 474.138 380.961C474.138 380.177 473.502 379.542 472.718 379.542C471.934 379.542 471.299 380.177 471.299 380.961C471.299 381.745 471.934 382.381 472.718 382.381Z" fill="#C63846"/>
                <path d="M334.587 338.444C334.626 338.444 334.658 338.412 334.658 338.373C334.658 338.334 334.626 338.302 334.587 338.302C334.548 338.302 334.516 338.334 334.516 338.373C334.516 338.412 334.548 338.444 334.587 338.444Z" fill="#C63846"/>
                <path d="M341.373 340.984C341.452 340.984 341.517 340.92 341.517 340.84C341.517 340.761 341.452 340.696 341.373 340.696C341.293 340.696 341.229 340.761 341.229 340.84C341.229 340.92 341.293 340.984 341.373 340.984Z" fill="#C63846"/>
                <path d="M348.157 343.511C348.27 343.511 348.361 343.419 348.361 343.306C348.361 343.194 348.27 343.102 348.157 343.102C348.045 343.102 347.953 343.194 347.953 343.306C347.953 343.419 348.045 343.511 348.157 343.511Z" fill="#C63846"/>
                <path d="M354.943 346.049C355.096 346.049 355.219 345.925 355.219 345.773C355.219 345.62 355.096 345.496 354.943 345.496C354.79 345.496 354.666 345.62 354.666 345.773C354.666 345.925 354.79 346.049 354.943 346.049Z" fill="#C63846"/>
                <path d="M361.716 348.6C361.909 348.6 362.065 348.444 362.065 348.251C362.065 348.058 361.909 347.902 361.716 347.902C361.523 347.902 361.367 348.058 361.367 348.251C361.367 348.444 361.523 348.6 361.716 348.6Z" fill="#C63846"/>
                <path d="M368.501 351.138C368.734 351.138 368.922 350.949 368.922 350.717C368.922 350.484 368.734 350.296 368.501 350.296C368.269 350.296 368.08 350.484 368.08 350.717C368.08 350.949 368.269 351.138 368.501 351.138Z" fill="#C63846"/>
                <path d="M375.286 353.676C375.559 353.676 375.779 353.456 375.779 353.183C375.779 352.911 375.559 352.69 375.286 352.69C375.014 352.69 374.793 352.911 374.793 353.183C374.793 353.456 375.014 353.676 375.286 353.676Z" fill="#C63846"/>
                <path d="M382.071 356.203C382.377 356.203 382.624 355.955 382.624 355.65C382.624 355.344 382.377 355.096 382.071 355.096C381.765 355.096 381.518 355.344 381.518 355.65C381.518 355.955 381.765 356.203 382.071 356.203Z" fill="#C63846"/>
                <path d="M388.856 358.753C389.202 358.753 389.482 358.473 389.482 358.127C389.482 357.782 389.202 357.502 388.856 357.502C388.511 357.502 388.23 357.782 388.23 358.127C388.23 358.473 388.511 358.753 388.856 358.753Z" fill="#C63846"/>
                <path d="M395.631 361.28C396.01 361.28 396.317 360.973 396.317 360.594C396.317 360.215 396.01 359.908 395.631 359.908C395.252 359.908 394.945 360.215 394.945 360.594C394.945 360.973 395.252 361.28 395.631 361.28Z" fill="#C63846"/>
                <path d="M402.414 363.818C402.833 363.818 403.172 363.479 403.172 363.06C403.172 362.642 402.833 362.302 402.414 362.302C401.996 362.302 401.656 362.642 401.656 363.06C401.656 363.479 401.996 363.818 402.414 363.818Z" fill="#C63846"/>
                <path d="M409.199 366.357C409.658 366.357 410.029 365.985 410.029 365.527C410.029 365.068 409.658 364.697 409.199 364.697C408.741 364.697 408.369 365.068 408.369 365.527C408.369 365.985 408.741 366.357 409.199 366.357Z" fill="#C63846"/>
                <path d="M415.984 368.895C416.483 368.895 416.887 368.491 416.887 367.992C416.887 367.494 416.483 367.09 415.984 367.09C415.486 367.09 415.082 367.494 415.082 367.992C415.082 368.491 415.486 368.895 415.984 368.895Z" fill="#C63846"/>
                <path d="M422.769 371.434C423.301 371.434 423.731 371.003 423.731 370.471C423.731 369.94 423.301 369.509 422.769 369.509C422.238 369.509 421.807 369.94 421.807 370.471C421.807 371.003 422.238 371.434 422.769 371.434Z" fill="#C63846"/>
                <path d="M429.554 373.972C430.126 373.972 430.589 373.509 430.589 372.937C430.589 372.366 430.126 371.903 429.554 371.903C428.983 371.903 428.52 372.366 428.52 372.937C428.52 373.509 428.983 373.972 429.554 373.972Z" fill="#C63846"/>
                <path d="M436.328 376.51C436.939 376.51 437.434 376.014 437.434 375.403C437.434 374.792 436.939 374.296 436.328 374.296C435.716 374.296 435.221 374.792 435.221 375.403C435.221 376.014 435.716 376.51 436.328 376.51Z" fill="#C63846"/>
                <path d="M443.112 379.036C443.757 379.036 444.279 378.514 444.279 377.869C444.279 377.225 443.757 376.702 443.112 376.702C442.468 376.702 441.945 377.225 441.945 377.869C441.945 378.514 442.468 379.036 443.112 379.036Z" fill="#C63846"/>
                <path d="M449.899 381.575C450.584 381.575 451.138 381.02 451.138 380.336C451.138 379.651 450.584 379.097 449.899 379.097C449.215 379.097 448.66 379.651 448.66 380.336C448.66 381.02 449.215 381.575 449.899 381.575Z" fill="#C63846"/>
                <path d="M456.68 384.125C457.405 384.125 457.992 383.538 457.992 382.814C457.992 382.09 457.405 381.503 456.68 381.503C455.956 381.503 455.369 382.09 455.369 382.814C455.369 383.538 455.956 384.125 456.68 384.125Z" fill="#C63846"/>
                <path d="M463.469 386.664C464.233 386.664 464.853 386.045 464.853 385.281C464.853 384.516 464.233 383.897 463.469 383.897C462.705 383.897 462.086 384.516 462.086 385.281C462.086 386.045 462.705 386.664 463.469 386.664Z" fill="#C63846"/>
                <path d="M470.241 389.201C471.045 389.201 471.696 388.55 471.696 387.746C471.696 386.942 471.045 386.29 470.241 386.29C469.437 386.29 468.785 386.942 468.785 387.746C468.785 388.55 469.437 389.201 470.241 389.201Z" fill="#C63846"/>
                <path d="M325.336 342.704C325.349 342.704 325.359 342.693 325.359 342.68C325.359 342.668 325.349 342.657 325.336 342.657C325.323 342.657 325.312 342.668 325.312 342.68C325.312 342.693 325.323 342.704 325.336 342.704Z" fill="#C63846"/>
                <path d="M332.122 345.257C332.176 345.257 332.219 345.213 332.219 345.16C332.219 345.106 332.176 345.063 332.122 345.063C332.069 345.063 332.025 345.106 332.025 345.16C332.025 345.213 332.069 345.257 332.122 345.257Z" fill="#C63846"/>
                <path d="M338.906 347.793C338.999 347.793 339.075 347.717 339.075 347.624C339.075 347.532 338.999 347.456 338.906 347.456C338.814 347.456 338.738 347.532 338.738 347.624C338.738 347.717 338.814 347.793 338.906 347.793Z" fill="#C63846"/>
                <path d="M345.692 350.332C345.825 350.332 345.932 350.224 345.932 350.091C345.932 349.958 345.825 349.85 345.692 349.85C345.559 349.85 345.451 349.958 345.451 350.091C345.451 350.224 345.559 350.332 345.692 350.332Z" fill="#C63846"/>
                <path d="M352.465 352.858C352.631 352.858 352.766 352.723 352.766 352.557C352.766 352.391 352.631 352.256 352.465 352.256C352.299 352.256 352.164 352.391 352.164 352.557C352.164 352.723 352.299 352.858 352.465 352.858Z" fill="#C63846"/>
                <path d="M359.248 355.409C359.454 355.409 359.621 355.242 359.621 355.036C359.621 354.83 359.454 354.663 359.248 354.663C359.042 354.663 358.875 354.83 358.875 355.036C358.875 355.242 359.042 355.409 359.248 355.409Z" fill="#C63846"/>
                <path d="M366.035 357.947C366.281 357.947 366.48 357.748 366.48 357.502C366.48 357.256 366.281 357.057 366.035 357.057C365.789 357.057 365.59 357.256 365.59 357.502C365.59 357.748 365.789 357.947 366.035 357.947Z" fill="#C63846"/>
                <path d="M372.82 360.473C373.099 360.473 373.325 360.247 373.325 359.968C373.325 359.689 373.099 359.463 372.82 359.463C372.541 359.463 372.314 359.689 372.314 359.968C372.314 360.247 372.541 360.473 372.82 360.473Z" fill="#C63846"/>
                <path d="M379.605 363.013C379.924 363.013 380.182 362.754 380.182 362.435C380.182 362.116 379.924 361.858 379.605 361.858C379.286 361.858 379.027 362.116 379.027 362.435C379.027 362.754 379.286 363.013 379.605 363.013Z" fill="#C63846"/>
                <path d="M386.378 365.55C386.737 365.55 387.028 365.259 387.028 364.9C387.028 364.541 386.737 364.251 386.378 364.251C386.019 364.251 385.729 364.541 385.729 364.9C385.729 365.259 386.019 365.55 386.378 365.55Z" fill="#C63846"/>
                <path d="M393.163 368.1C393.562 368.1 393.885 367.777 393.885 367.379C393.885 366.98 393.562 366.657 393.163 366.657C392.765 366.657 392.441 366.98 392.441 367.379C392.441 367.777 392.765 368.1 393.163 368.1Z" fill="#C63846"/>
                <path d="M399.948 370.639C400.387 370.639 400.742 370.284 400.742 369.845C400.742 369.407 400.387 369.051 399.948 369.051C399.51 369.051 399.154 369.407 399.154 369.845C399.154 370.284 399.51 370.639 399.948 370.639Z" fill="#C63846"/>
                <path d="M406.733 373.166C407.205 373.166 407.587 372.783 407.587 372.312C407.587 371.84 407.205 371.457 406.733 371.457C406.261 371.457 405.879 371.84 405.879 372.312C405.879 372.783 406.261 373.166 406.733 373.166Z" fill="#C63846"/>
                <path d="M413.518 375.704C414.03 375.704 414.444 375.289 414.444 374.777C414.444 374.266 414.03 373.851 413.518 373.851C413.007 373.851 412.592 374.266 412.592 374.777C412.592 375.289 413.007 375.704 413.518 375.704Z" fill="#C63846"/>
                <path d="M420.293 378.23C420.838 378.23 421.28 377.789 421.28 377.244C421.28 376.699 420.838 376.257 420.293 376.257C419.748 376.257 419.307 376.699 419.307 377.244C419.307 377.789 419.748 378.23 420.293 378.23Z" fill="#C63846"/>
                <path d="M427.076 380.781C427.661 380.781 428.135 380.307 428.135 379.722C428.135 379.137 427.661 378.663 427.076 378.663C426.492 378.663 426.018 379.137 426.018 379.722C426.018 380.307 426.492 380.781 427.076 380.781Z" fill="#C63846"/>
                <path d="M433.861 383.319C434.486 383.319 434.992 382.812 434.992 382.188C434.992 381.563 434.486 381.057 433.861 381.057C433.237 381.057 432.73 381.563 432.73 382.188C432.73 382.812 433.237 383.319 433.861 383.319Z" fill="#C63846"/>
                <path d="M440.646 385.857C441.311 385.857 441.849 385.319 441.849 384.654C441.849 383.99 441.311 383.451 440.646 383.451C439.982 383.451 439.443 383.99 439.443 384.654C439.443 385.319 439.982 385.857 440.646 385.857Z" fill="#C63846"/>
                <path d="M447.431 388.396C448.136 388.396 448.707 387.825 448.707 387.121C448.707 386.416 448.136 385.845 447.431 385.845C446.727 385.845 446.156 386.416 446.156 387.121C446.156 387.825 446.727 388.396 447.431 388.396Z" fill="#C63846"/>
                <path d="M454.216 390.922C454.954 390.922 455.552 390.324 455.552 389.586C455.552 388.849 454.954 388.251 454.216 388.251C453.479 388.251 452.881 388.849 452.881 389.586C452.881 390.324 453.479 390.922 454.216 390.922Z" fill="#C63846"/>
                <path d="M460.989 393.461C461.76 393.461 462.385 392.836 462.385 392.066C462.385 391.295 461.76 390.67 460.989 390.67C460.219 390.67 459.594 391.295 459.594 392.066C459.594 392.836 460.219 393.461 460.989 393.461Z" fill="#C63846"/>
                <path d="M467.774 395.999C468.585 395.999 469.242 395.342 469.242 394.531C469.242 393.721 468.585 393.064 467.774 393.064C466.964 393.064 466.307 393.721 466.307 394.531C466.307 395.342 466.964 395.999 467.774 395.999Z" fill="#C63846"/>
                <path d="M474.559 398.441C475.356 398.441 476.003 397.794 476.003 396.997C476.003 396.2 475.356 395.553 474.559 395.553C473.762 395.553 473.115 396.2 473.115 396.997C473.115 397.794 473.762 398.441 474.559 398.441Z" fill="#C63846"/>
                <path d="M322.87 349.524C322.903 349.524 322.93 349.498 322.93 349.465C322.93 349.432 322.903 349.405 322.87 349.405C322.837 349.405 322.811 349.432 322.811 349.465C322.811 349.498 322.837 349.524 322.87 349.524Z" fill="#C63846"/>
                <path d="M329.655 352.053C329.722 352.053 329.775 351.999 329.775 351.932C329.775 351.866 329.722 351.812 329.655 351.812C329.589 351.812 329.535 351.866 329.535 351.932C329.535 351.999 329.589 352.053 329.655 352.053Z" fill="#C63846"/>
                <path d="M336.428 354.602C336.535 354.602 336.621 354.516 336.621 354.41C336.621 354.304 336.535 354.218 336.428 354.218C336.322 354.218 336.236 354.304 336.236 354.41C336.236 354.516 336.322 354.602 336.428 354.602Z" fill="#C63846"/>
                <path d="M343.214 357.142C343.36 357.142 343.479 357.023 343.479 356.877C343.479 356.731 343.36 356.612 343.214 356.612C343.068 356.612 342.949 356.731 342.949 356.877C342.949 357.023 343.068 357.142 343.214 357.142Z" fill="#C63846"/>
                <path d="M349.999 359.668C350.178 359.668 350.323 359.523 350.323 359.343C350.323 359.164 350.178 359.018 349.999 359.018C349.819 359.018 349.674 359.164 349.674 359.343C349.674 359.523 349.819 359.668 349.999 359.668Z" fill="#C63846"/>
                <path d="M356.784 362.206C357.003 362.206 357.181 362.028 357.181 361.809C357.181 361.59 357.003 361.412 356.784 361.412C356.564 361.412 356.387 361.59 356.387 361.809C356.387 362.028 356.564 362.206 356.784 362.206Z" fill="#C63846"/>
                <path d="M363.569 364.757C363.828 364.757 364.038 364.547 364.038 364.287C364.038 364.028 363.828 363.818 363.569 363.818C363.31 363.818 363.1 364.028 363.1 364.287C363.1 364.547 363.31 364.757 363.569 364.757Z" fill="#C63846"/>
                <path d="M370.354 367.295C370.653 367.295 370.895 367.052 370.895 366.753C370.895 366.454 370.653 366.212 370.354 366.212C370.055 366.212 369.812 366.454 369.812 366.753C369.812 367.052 370.055 367.295 370.354 367.295Z" fill="#C63846"/>
                <path d="M377.127 369.821C377.459 369.821 377.728 369.552 377.728 369.22C377.728 368.887 377.459 368.618 377.127 368.618C376.795 368.618 376.525 368.887 376.525 369.22C376.525 369.552 376.795 369.821 377.127 369.821Z" fill="#C63846"/>
                <path d="M383.91 372.36C384.282 372.36 384.584 372.058 384.584 371.686C384.584 371.314 384.282 371.012 383.91 371.012C383.538 371.012 383.236 371.314 383.236 371.686C383.236 372.058 383.538 372.36 383.91 372.36Z" fill="#C63846"/>
                <path d="M390.697 374.898C391.109 374.898 391.443 374.564 391.443 374.152C391.443 373.74 391.109 373.406 390.697 373.406C390.285 373.406 389.951 373.74 389.951 374.152C389.951 374.564 390.285 374.898 390.697 374.898Z" fill="#C63846"/>
                <path d="M397.482 377.437C397.927 377.437 398.288 377.076 398.288 376.631C398.288 376.186 397.927 375.825 397.482 375.825C397.037 375.825 396.676 376.186 396.676 376.631C396.676 377.076 397.037 377.437 397.482 377.437Z" fill="#C63846"/>
                <path d="M404.267 379.975C404.752 379.975 405.145 379.582 405.145 379.096C405.145 378.611 404.752 378.218 404.267 378.218C403.782 378.218 403.389 378.611 403.389 379.096C403.389 379.582 403.782 379.975 404.267 379.975Z" fill="#C63846"/>
                <path d="M411.04 382.513C411.565 382.513 411.991 382.088 411.991 381.563C411.991 381.038 411.565 380.613 411.04 380.613C410.515 380.613 410.09 381.038 410.09 381.563C410.09 382.088 410.515 382.513 411.04 382.513Z" fill="#C63846"/>
                <path d="M417.825 385.051C418.39 385.051 418.848 384.593 418.848 384.029C418.848 383.464 418.39 383.006 417.825 383.006C417.261 383.006 416.803 383.464 416.803 384.029C416.803 384.593 417.261 385.051 417.825 385.051Z" fill="#C63846"/>
                <path d="M424.61 387.59C425.215 387.59 425.705 387.1 425.705 386.495C425.705 385.89 425.215 385.4 424.61 385.4C424.006 385.4 423.516 385.89 423.516 386.495C423.516 387.1 424.006 387.59 424.61 387.59Z" fill="#C63846"/>
                <path d="M431.395 390.128C432.033 390.128 432.55 389.611 432.55 388.973C432.55 388.336 432.033 387.819 431.395 387.819C430.757 387.819 430.24 388.336 430.24 388.973C430.24 389.611 430.757 390.128 431.395 390.128Z" fill="#C63846"/>
                <path d="M438.18 392.654C438.851 392.654 439.395 392.11 439.395 391.439C439.395 390.768 438.851 390.224 438.18 390.224C437.509 390.224 436.965 390.768 436.965 391.439C436.965 392.11 437.509 392.654 438.18 392.654Z" fill="#C63846"/>
                <path d="M444.965 395.192C445.676 395.192 446.252 394.616 446.252 393.905C446.252 393.194 445.676 392.618 444.965 392.618C444.254 392.618 443.678 393.194 443.678 393.905C443.678 394.616 444.254 395.192 444.965 395.192Z" fill="#C63846"/>
                <path d="M451.738 397.731C452.489 397.731 453.098 397.123 453.098 396.372C453.098 395.621 452.489 395.013 451.738 395.013C450.988 395.013 450.379 395.621 450.379 396.372C450.379 397.123 450.988 397.731 451.738 397.731Z" fill="#C63846"/>
                <path d="M458.523 400.282C459.314 400.282 459.955 399.641 459.955 398.851C459.955 398.06 459.314 397.419 458.523 397.419C457.733 397.419 457.092 398.06 457.092 398.851C457.092 399.641 457.733 400.282 458.523 400.282Z" fill="#C63846"/>
                <path d="M465.308 402.82C466.139 402.82 466.812 402.147 466.812 401.316C466.812 400.486 466.139 399.812 465.308 399.812C464.478 399.812 463.805 400.486 463.805 401.316C463.805 402.147 464.478 402.82 465.308 402.82Z" fill="#C63846"/>
                <path d="M472.094 405.359C472.964 405.359 473.669 404.653 473.669 403.783C473.669 402.912 472.964 402.207 472.094 402.207C471.223 402.207 470.518 402.912 470.518 403.783C470.518 404.653 471.223 405.359 472.094 405.359Z" fill="#C63846"/>
                <path d="M313.617 353.808C313.63 353.808 313.64 353.798 313.64 353.785C313.64 353.772 313.63 353.761 313.617 353.761C313.604 353.761 313.594 353.772 313.594 353.785C313.594 353.798 313.604 353.808 313.617 353.808Z" fill="#C63846"/>
                <path d="M320.403 356.336C320.45 356.336 320.488 356.298 320.488 356.251C320.488 356.204 320.45 356.166 320.403 356.166C320.356 356.166 320.318 356.204 320.318 356.251C320.318 356.298 320.356 356.336 320.403 356.336Z" fill="#C63846"/>
                <path d="M327.177 358.861C327.257 358.861 327.321 358.796 327.321 358.717C327.321 358.637 327.257 358.573 327.177 358.573C327.098 358.573 327.033 358.637 327.033 358.717C327.033 358.796 327.098 358.861 327.177 358.861Z" fill="#C63846"/>
                <path d="M333.961 361.413C334.081 361.413 334.178 361.316 334.178 361.196C334.178 361.076 334.081 360.979 333.961 360.979C333.841 360.979 333.744 361.076 333.744 361.196C333.744 361.316 333.841 361.413 333.961 361.413Z" fill="#C63846"/>
                <path d="M340.748 363.95C340.907 363.95 341.036 363.821 341.036 363.661C341.036 363.502 340.907 363.372 340.748 363.372C340.588 363.372 340.459 363.502 340.459 363.661C340.459 363.821 340.588 363.95 340.748 363.95Z" fill="#C63846"/>
                <path d="M347.533 366.489C347.732 366.489 347.894 366.327 347.894 366.128C347.894 365.929 347.732 365.767 347.533 365.767C347.333 365.767 347.172 365.929 347.172 366.128C347.172 366.327 347.333 366.489 347.533 366.489Z" fill="#C63846"/>
                <path d="M354.318 369.016C354.55 369.016 354.739 368.827 354.739 368.595C354.739 368.362 354.55 368.174 354.318 368.174C354.085 368.174 353.896 368.362 353.896 368.595C353.896 368.827 354.085 369.016 354.318 369.016Z" fill="#C63846"/>
                <path d="M361.103 371.553C361.375 371.553 361.596 371.332 361.596 371.06C361.596 370.787 361.375 370.566 361.103 370.566C360.83 370.566 360.609 370.787 360.609 371.06C360.609 371.332 360.83 371.553 361.103 371.553Z" fill="#C63846"/>
                <path d="M367.874 374.104C368.186 374.104 368.439 373.85 368.439 373.538C368.439 373.226 368.186 372.973 367.874 372.973C367.562 372.973 367.309 373.226 367.309 373.538C367.309 373.85 367.562 374.104 367.874 374.104Z" fill="#C63846"/>
                <path d="M374.661 376.63C375.006 376.63 375.286 376.35 375.286 376.004C375.286 375.659 375.006 375.379 374.661 375.379C374.315 375.379 374.035 375.659 374.035 376.004C374.035 376.35 374.315 376.63 374.661 376.63Z" fill="#C63846"/>
                <path d="M381.446 379.169C381.831 379.169 382.144 378.856 382.144 378.471C382.144 378.086 381.831 377.773 381.446 377.773C381.06 377.773 380.748 378.086 380.748 378.471C380.748 378.856 381.06 379.169 381.446 379.169Z" fill="#C63846"/>
                <path d="M388.229 381.707C388.654 381.707 388.999 381.362 388.999 380.937C388.999 380.511 388.654 380.167 388.229 380.167C387.804 380.167 387.459 380.511 387.459 380.937C387.459 381.362 387.804 381.707 388.229 381.707Z" fill="#C63846"/>
                <path d="M395.016 384.245C395.481 384.245 395.858 383.868 395.858 383.403C395.858 382.938 395.481 382.561 395.016 382.561C394.551 382.561 394.174 382.938 394.174 383.403C394.174 383.868 394.551 384.245 395.016 384.245Z" fill="#C63846"/>
                <path d="M401.787 386.796C402.292 386.796 402.702 386.386 402.702 385.881C402.702 385.376 402.292 384.967 401.787 384.967C401.282 384.967 400.873 385.376 400.873 385.881C400.873 386.386 401.282 386.796 401.787 386.796Z" fill="#C63846"/>
                <path d="M408.572 389.322C409.11 389.322 409.547 388.886 409.547 388.348C409.547 387.81 409.11 387.373 408.572 387.373C408.034 387.373 407.598 387.81 407.598 388.348C407.598 388.886 408.034 389.322 408.572 389.322Z" fill="#C63846"/>
                <path d="M415.359 391.86C415.937 391.86 416.406 391.392 416.406 390.814C416.406 390.236 415.937 389.767 415.359 389.767C414.781 389.767 414.312 390.236 414.312 390.814C414.312 391.392 414.781 391.86 415.359 391.86Z" fill="#C63846"/>
                <path d="M422.144 394.387C422.755 394.387 423.251 393.891 423.251 393.28C423.251 392.669 422.755 392.173 422.144 392.173C421.533 392.173 421.037 392.669 421.037 393.28C421.037 393.891 421.533 394.387 422.144 394.387Z" fill="#C63846"/>
                <path d="M428.929 396.925C429.58 396.925 430.108 396.398 430.108 395.746C430.108 395.095 429.58 394.568 428.929 394.568C428.278 394.568 427.75 395.095 427.75 395.746C427.75 396.398 428.278 396.925 428.929 396.925Z" fill="#C63846"/>
                <path d="M435.714 399.476C436.405 399.476 436.965 398.916 436.965 398.225C436.965 397.534 436.405 396.974 435.714 396.974C435.023 396.974 434.463 397.534 434.463 398.225C434.463 398.916 435.023 399.476 435.714 399.476Z" fill="#C63846"/>
                <path d="M442.487 402.014C443.218 402.014 443.811 401.421 443.811 400.691C443.811 399.96 443.218 399.367 442.487 399.367C441.757 399.367 441.164 399.96 441.164 400.691C441.164 401.421 441.757 402.014 442.487 402.014Z" fill="#C63846"/>
                <path d="M449.272 404.552C450.043 404.552 450.668 403.928 450.668 403.157C450.668 402.386 450.043 401.761 449.272 401.761C448.502 401.761 447.877 402.386 447.877 403.157C447.877 403.928 448.502 404.552 449.272 404.552Z" fill="#C63846"/>
                <path d="M456.057 407.079C456.861 407.079 457.513 406.427 457.513 405.623C457.513 404.819 456.861 404.168 456.057 404.168C455.253 404.168 454.602 404.819 454.602 405.623C454.602 406.427 455.253 407.079 456.057 407.079Z" fill="#C63846"/>
                <path d="M462.842 409.618C463.679 409.618 464.358 408.939 464.358 408.102C464.358 407.265 463.679 406.586 462.842 406.586C462.005 406.586 461.326 407.265 461.326 408.102C461.326 408.939 462.005 409.618 462.842 409.618Z" fill="#C63846"/>
                <path d="M469.627 412.156C470.504 412.156 471.215 411.445 471.215 410.568C471.215 409.691 470.504 408.98 469.627 408.98C468.75 408.98 468.039 409.691 468.039 410.568C468.039 411.445 468.75 412.156 469.627 412.156Z" fill="#C63846"/>
                <path d="M311.152 360.604C311.172 360.604 311.188 360.588 311.188 360.569C311.188 360.549 311.172 360.534 311.152 360.534C311.133 360.534 311.117 360.549 311.117 360.569C311.117 360.588 311.133 360.604 311.152 360.604Z" fill="#C63846"/>
                <path d="M317.925 363.146C317.986 363.146 318.034 363.097 318.034 363.037C318.034 362.977 317.986 362.928 317.925 362.928C317.865 362.928 317.816 362.977 317.816 363.037C317.816 363.097 317.865 363.146 317.925 363.146Z" fill="#C63846"/>
                <path d="M324.709 365.682C324.809 365.682 324.89 365.602 324.89 365.502C324.89 365.403 324.809 365.322 324.709 365.322C324.61 365.322 324.529 365.403 324.529 365.502C324.529 365.602 324.61 365.682 324.709 365.682Z" fill="#C63846"/>
                <path d="M331.495 368.21C331.627 368.21 331.735 368.102 331.735 367.969C331.735 367.836 331.627 367.728 331.495 367.728C331.362 367.728 331.254 367.836 331.254 367.969C331.254 368.102 331.362 368.21 331.495 368.21Z" fill="#C63846"/>
                <path d="M338.28 370.76C338.452 370.76 338.592 370.619 338.592 370.447C338.592 370.274 338.452 370.134 338.28 370.134C338.107 370.134 337.967 370.274 337.967 370.447C337.967 370.619 338.107 370.76 338.28 370.76Z" fill="#C63846"/>
                <path d="M345.065 373.297C345.277 373.297 345.45 373.125 345.45 372.912C345.45 372.7 345.277 372.527 345.065 372.527C344.852 372.527 344.68 372.7 344.68 372.912C344.68 373.125 344.852 373.297 345.065 373.297Z" fill="#C63846"/>
                <path d="M351.849 375.824C352.095 375.824 352.295 375.625 352.295 375.379C352.295 375.133 352.095 374.934 351.849 374.934C351.604 374.934 351.404 375.133 351.404 375.379C351.404 375.625 351.604 375.824 351.849 375.824Z" fill="#C63846"/>
                <path d="M358.623 378.363C358.908 378.363 359.14 378.131 359.14 377.845C359.14 377.56 358.908 377.328 358.623 377.328C358.337 377.328 358.105 377.56 358.105 377.845C358.105 378.131 358.337 378.363 358.623 378.363Z" fill="#C63846"/>
                <path d="M365.41 380.901C365.735 380.901 365.999 380.637 365.999 380.312C365.999 379.986 365.735 379.722 365.41 379.722C365.084 379.722 364.82 379.986 364.82 380.312C364.82 380.637 365.084 380.901 365.41 380.901Z" fill="#C63846"/>
                <path d="M372.195 383.452C372.56 383.452 372.857 383.156 372.857 382.79C372.857 382.425 372.56 382.129 372.195 382.129C371.829 382.129 371.533 382.425 371.533 382.79C371.533 383.156 371.829 383.452 372.195 383.452Z" fill="#C63846"/>
                <path d="M378.978 385.989C379.383 385.989 379.712 385.66 379.712 385.255C379.712 384.85 379.383 384.521 378.978 384.521C378.573 384.521 378.244 384.85 378.244 385.255C378.244 385.66 378.573 385.989 378.978 385.989Z" fill="#C63846"/>
                <path d="M385.765 388.515C386.203 388.515 386.559 388.16 386.559 387.721C386.559 387.283 386.203 386.927 385.765 386.927C385.326 386.927 384.971 387.283 384.971 387.721C384.971 388.16 385.326 388.515 385.765 388.515Z" fill="#C63846"/>
                <path d="M392.536 391.054C393.014 391.054 393.402 390.666 393.402 390.188C393.402 389.71 393.014 389.322 392.536 389.322C392.058 389.322 391.67 389.71 391.67 390.188C391.67 390.666 392.058 391.054 392.536 391.054Z" fill="#C63846"/>
                <path d="M399.321 393.581C399.832 393.581 400.247 393.166 400.247 392.654C400.247 392.143 399.832 391.728 399.321 391.728C398.809 391.728 398.395 392.143 398.395 392.654C398.395 393.166 398.809 393.581 399.321 393.581Z" fill="#C63846"/>
                <path d="M406.108 396.131C406.659 396.131 407.106 395.684 407.106 395.133C407.106 394.581 406.659 394.134 406.108 394.134C405.556 394.134 405.109 394.581 405.109 395.133C405.109 395.684 405.556 396.131 406.108 396.131Z" fill="#C63846"/>
                <path d="M412.891 398.67C413.482 398.67 413.962 398.191 413.962 397.599C413.962 397.008 413.482 396.529 412.891 396.529C412.3 396.529 411.82 397.008 411.82 397.599C411.82 398.191 412.3 398.67 412.891 398.67Z" fill="#C63846"/>
                <path d="M419.678 401.209C420.309 401.209 420.821 400.697 420.821 400.066C420.821 399.435 420.309 398.923 419.678 398.923C419.047 398.923 418.535 399.435 418.535 400.066C418.535 400.697 419.047 401.209 419.678 401.209Z" fill="#C63846"/>
                <path d="M426.449 403.746C427.12 403.746 427.664 403.202 427.664 402.531C427.664 401.86 427.12 401.316 426.449 401.316C425.778 401.316 425.234 401.86 425.234 402.531C425.234 403.202 425.778 403.746 426.449 403.746Z" fill="#C63846"/>
                <path d="M433.234 406.284C433.938 406.284 434.509 405.713 434.509 405.009C434.509 404.305 433.938 403.734 433.234 403.734C432.53 403.734 431.959 404.305 431.959 405.009C431.959 405.713 432.53 406.284 433.234 406.284Z" fill="#C63846"/>
                <path d="M440.021 408.811C440.758 408.811 441.356 408.213 441.356 407.475C441.356 406.738 440.758 406.14 440.021 406.14C439.283 406.14 438.686 406.738 438.686 407.475C438.686 408.213 439.283 408.811 440.021 408.811Z" fill="#C63846"/>
                <path d="M446.806 411.349C447.583 411.349 448.214 410.719 448.214 409.941C448.214 409.164 447.583 408.534 446.806 408.534C446.029 408.534 445.398 409.164 445.398 409.941C445.398 410.719 446.029 411.349 446.806 411.349Z" fill="#C63846"/>
                <path d="M453.591 413.888C454.408 413.888 455.071 413.226 455.071 412.408C455.071 411.591 454.408 410.929 453.591 410.929C452.774 410.929 452.111 411.591 452.111 412.408C452.111 413.226 452.774 413.888 453.591 413.888Z" fill="#C63846"/>
                <path d="M460.376 416.426C461.233 416.426 461.928 415.731 461.928 414.874C461.928 414.017 461.233 413.322 460.376 413.322C459.519 413.322 458.824 414.017 458.824 414.874C458.824 415.731 459.519 416.426 460.376 416.426Z" fill="#C63846"/>
                <path d="M467.149 418.977C468.046 418.977 468.774 418.249 468.774 417.352C468.774 416.455 468.046 415.728 467.149 415.728C466.253 415.728 465.525 416.455 465.525 417.352C465.525 418.249 466.253 418.977 467.149 418.977Z" fill="#C63846"/>
                <path d="M473.933 421.454C474.837 421.454 475.569 420.722 475.569 419.818C475.569 418.915 474.837 418.182 473.933 418.182C473.029 418.182 472.297 418.915 472.297 419.818C472.297 420.722 473.029 421.454 473.933 421.454Z" fill="#C63846"/>
                <path d="M308.675 367.414C308.708 367.414 308.734 367.387 308.734 367.354C308.734 367.321 308.708 367.295 308.675 367.295C308.642 367.295 308.615 367.321 308.615 367.354C308.615 367.387 308.642 367.414 308.675 367.414Z" fill="#C63846"/>
                <path d="M315.46 369.952C315.533 369.952 315.592 369.893 315.592 369.82C315.592 369.747 315.533 369.688 315.46 369.688C315.387 369.688 315.328 369.747 315.328 369.82C315.328 369.893 315.387 369.952 315.46 369.952Z" fill="#C63846"/>
                <path d="M322.245 372.491C322.358 372.491 322.449 372.4 322.449 372.287C322.449 372.174 322.358 372.083 322.245 372.083C322.132 372.083 322.041 372.174 322.041 372.287C322.041 372.4 322.132 372.491 322.245 372.491Z" fill="#C63846"/>
                <path d="M329.03 375.018C329.176 375.018 329.295 374.899 329.295 374.753C329.295 374.607 329.176 374.489 329.03 374.489C328.884 374.489 328.766 374.607 328.766 374.753C328.766 374.899 328.884 375.018 329.03 375.018Z" fill="#C63846"/>
                <path d="M335.815 377.557C336.001 377.557 336.152 377.406 336.152 377.22C336.152 377.034 336.001 376.883 335.815 376.883C335.629 376.883 335.479 377.034 335.479 377.22C335.479 377.406 335.629 377.557 335.815 377.557Z" fill="#C63846"/>
                <path d="M342.587 380.106C342.813 380.106 342.996 379.923 342.996 379.697C342.996 379.471 342.813 379.288 342.587 379.288C342.361 379.288 342.178 379.471 342.178 379.697C342.178 379.923 342.361 380.106 342.587 380.106Z" fill="#C63846"/>
                <path d="M349.372 382.645C349.638 382.645 349.853 382.43 349.853 382.164C349.853 381.898 349.638 381.683 349.372 381.683C349.106 381.683 348.891 381.898 348.891 382.164C348.891 382.43 349.106 382.645 349.372 382.645Z" fill="#C63846"/>
                <path d="M356.157 385.172C356.456 385.172 356.698 384.929 356.698 384.63C356.698 384.331 356.456 384.089 356.157 384.089C355.858 384.089 355.615 384.331 355.615 384.63C355.615 384.929 355.858 385.172 356.157 385.172Z" fill="#C63846"/>
                <path d="M362.942 387.71C363.281 387.71 363.555 387.436 363.555 387.097C363.555 386.758 363.281 386.483 362.942 386.483C362.603 386.483 362.328 386.758 362.328 387.097C362.328 387.436 362.603 387.71 362.942 387.71Z" fill="#C63846"/>
                <path d="M369.727 390.249C370.105 390.249 370.412 389.942 370.412 389.563C370.412 389.184 370.105 388.877 369.727 388.877C369.348 388.877 369.041 389.184 369.041 389.563C369.041 389.942 369.348 390.249 369.727 390.249Z" fill="#C63846"/>
                <path d="M376.511 392.787C376.923 392.787 377.257 392.453 377.257 392.041C377.257 391.629 376.923 391.295 376.511 391.295C376.1 391.295 375.766 391.629 375.766 392.041C375.766 392.453 376.1 392.787 376.511 392.787Z" fill="#C63846"/>
                <path d="M383.285 395.326C383.737 395.326 384.103 394.96 384.103 394.508C384.103 394.056 383.737 393.69 383.285 393.69C382.833 393.69 382.467 394.056 382.467 394.508C382.467 394.96 382.833 395.326 383.285 395.326Z" fill="#C63846"/>
                <path d="M390.072 397.864C390.564 397.864 390.962 397.465 390.962 396.974C390.962 396.482 390.564 396.083 390.072 396.083C389.58 396.083 389.182 396.482 389.182 396.974C389.182 397.465 389.58 397.864 390.072 397.864Z" fill="#C63846"/>
                <path d="M396.857 400.402C397.388 400.402 397.819 399.971 397.819 399.439C397.819 398.908 397.388 398.477 396.857 398.477C396.325 398.477 395.895 398.908 395.895 399.439C395.895 399.971 396.325 400.402 396.857 400.402Z" fill="#C63846"/>
                <path d="M403.64 402.94C404.211 402.94 404.675 402.477 404.675 401.906C404.675 401.334 404.211 400.871 403.64 400.871C403.069 400.871 402.605 401.334 402.605 401.906C402.605 402.477 403.069 402.94 403.64 402.94Z" fill="#C63846"/>
                <path d="M410.427 405.479C411.031 405.479 411.522 404.989 411.522 404.384C411.522 403.78 411.031 403.289 410.427 403.289C409.822 403.289 409.332 403.78 409.332 404.384C409.332 404.989 409.822 405.479 410.427 405.479Z" fill="#C63846"/>
                <path d="M417.2 408.005C417.838 408.005 418.355 407.488 418.355 406.85C418.355 406.212 417.838 405.695 417.2 405.695C416.562 405.695 416.045 406.212 416.045 406.85C416.045 407.488 416.562 408.005 417.2 408.005Z" fill="#C63846"/>
                <path d="M423.983 410.543C424.661 410.543 425.21 409.994 425.21 409.316C425.21 408.639 424.661 408.089 423.983 408.089C423.305 408.089 422.756 408.639 422.756 409.316C422.756 409.994 423.305 410.543 423.983 410.543Z" fill="#C63846"/>
                <path d="M430.77 413.082C431.488 413.082 432.069 412.5 432.069 411.783C432.069 411.065 431.488 410.484 430.77 410.484C430.052 410.484 429.471 411.065 429.471 411.783C429.471 412.5 430.052 413.082 430.77 413.082Z" fill="#C63846"/>
                <path d="M437.553 415.633C438.31 415.633 438.925 415.019 438.925 414.261C438.925 413.504 438.31 412.89 437.553 412.89C436.796 412.89 436.182 413.504 436.182 414.261C436.182 415.019 436.796 415.633 437.553 415.633Z" fill="#C63846"/>
                <path d="M444.34 418.17C445.137 418.17 445.784 417.524 445.784 416.726C445.784 415.929 445.137 415.283 444.34 415.283C443.543 415.283 442.896 415.929 442.896 416.726C442.896 417.524 443.543 418.17 444.34 418.17Z" fill="#C63846"/>
                <path d="M451.125 420.709C451.962 420.709 452.641 420.03 452.641 419.193C452.641 418.356 451.962 417.677 451.125 417.677C450.288 417.677 449.609 418.356 449.609 419.193C449.609 420.03 450.288 420.709 451.125 420.709Z" fill="#C63846"/>
                <path d="M457.896 423.236C458.767 423.236 459.472 422.53 459.472 421.66C459.472 420.789 458.767 420.084 457.896 420.084C457.026 420.084 456.32 420.789 456.32 421.66C456.32 422.53 457.026 423.236 457.896 423.236Z" fill="#C63846"/>
                <path d="M464.683 425.774C465.594 425.774 466.331 425.036 466.331 424.126C466.331 423.216 465.594 422.478 464.683 422.478C463.773 422.478 463.035 423.216 463.035 424.126C463.035 425.036 463.773 425.774 464.683 425.774Z" fill="#C63846"/>
                <path d="M471.468 428.312C472.412 428.312 473.176 427.547 473.176 426.604C473.176 425.66 472.412 424.896 471.468 424.896C470.525 424.896 469.76 425.66 469.76 426.604C469.76 427.547 470.525 428.312 471.468 428.312Z" fill="#C63846"/>
                <path d="M306.209 374.163C306.228 374.163 306.244 374.147 306.244 374.127C306.244 374.108 306.228 374.092 306.209 374.092C306.19 374.092 306.174 374.108 306.174 374.127C306.174 374.147 306.19 374.163 306.209 374.163Z" fill="#C63846"/>
                <path d="M312.994 376.761C313.08 376.761 313.15 376.691 313.15 376.605C313.15 376.519 313.08 376.449 312.994 376.449C312.908 376.449 312.838 376.519 312.838 376.605C312.838 376.691 312.908 376.761 312.994 376.761Z" fill="#C63846"/>
                <path d="M319.78 379.301C319.906 379.301 320.009 379.199 320.009 379.072C320.009 378.946 319.906 378.843 319.78 378.843C319.653 378.843 319.551 378.946 319.551 379.072C319.551 379.199 319.653 379.301 319.78 379.301Z" fill="#C63846"/>
                <path d="M326.564 381.839C326.731 381.839 326.865 381.704 326.865 381.538C326.865 381.372 326.731 381.237 326.564 381.237C326.398 381.237 326.264 381.372 326.264 381.538C326.264 381.704 326.398 381.839 326.564 381.839Z" fill="#C63846"/>
                <path d="M333.337 384.365C333.537 384.365 333.698 384.204 333.698 384.005C333.698 383.805 333.537 383.644 333.337 383.644C333.138 383.644 332.977 383.805 332.977 384.005C332.977 384.204 333.138 384.365 333.337 384.365Z" fill="#C63846"/>
                <path d="M340.123 386.904C340.362 386.904 340.556 386.71 340.556 386.471C340.556 386.232 340.362 386.038 340.123 386.038C339.883 386.038 339.689 386.232 339.689 386.471C339.689 386.71 339.883 386.904 340.123 386.904Z" fill="#C63846"/>
                <path d="M346.908 389.455C347.187 389.455 347.413 389.229 347.413 388.95C347.413 388.67 347.187 388.444 346.908 388.444C346.629 388.444 346.402 388.67 346.402 388.95C346.402 389.229 346.629 389.455 346.908 389.455Z" fill="#C63846"/>
                <path d="M353.692 391.981C354.005 391.981 354.258 391.728 354.258 391.416C354.258 391.104 354.005 390.85 353.692 390.85C353.38 390.85 353.127 391.104 353.127 391.416C353.127 391.728 353.38 391.981 353.692 391.981Z" fill="#C63846"/>
                <path d="M360.477 394.518C360.83 394.518 361.115 394.233 361.115 393.881C361.115 393.529 360.83 393.243 360.477 393.243C360.125 393.243 359.84 393.529 359.84 393.881C359.84 394.233 360.125 394.518 360.477 394.518Z" fill="#C63846"/>
                <path d="M367.261 397.058C367.653 397.058 367.97 396.74 367.97 396.348C367.97 395.956 367.653 395.638 367.261 395.638C366.869 395.638 366.551 395.956 366.551 396.348C366.551 396.74 366.869 397.058 367.261 397.058Z" fill="#C63846"/>
                <path d="M374.036 399.596C374.468 399.596 374.818 399.246 374.818 398.814C374.818 398.382 374.468 398.032 374.036 398.032C373.604 398.032 373.254 398.382 373.254 398.814C373.254 399.246 373.604 399.596 374.036 399.596Z" fill="#C63846"/>
                <path d="M380.819 402.146C381.291 402.146 381.673 401.764 381.673 401.292C381.673 400.821 381.291 400.438 380.819 400.438C380.347 400.438 379.965 400.821 379.965 401.292C379.965 401.764 380.347 402.146 380.819 402.146Z" fill="#C63846"/>
                <path d="M387.604 404.673C388.109 404.673 388.518 404.264 388.518 403.759C388.518 403.254 388.109 402.844 387.604 402.844C387.099 402.844 386.689 403.254 386.689 403.759C386.689 404.264 387.099 404.673 387.604 404.673Z" fill="#C63846"/>
                <path d="M394.389 407.211C394.934 407.211 395.375 406.77 395.375 406.225C395.375 405.68 394.934 405.239 394.389 405.239C393.844 405.239 393.402 405.68 393.402 406.225C393.402 406.77 393.844 407.211 394.389 407.211Z" fill="#C63846"/>
                <path d="M401.174 409.737C401.752 409.737 402.22 409.269 402.22 408.691C402.22 408.113 401.752 407.644 401.174 407.644C400.596 407.644 400.127 408.113 400.127 408.691C400.127 409.269 400.596 409.737 401.174 409.737Z" fill="#C63846"/>
                <path d="M407.947 412.288C408.565 412.288 409.066 411.787 409.066 411.169C409.066 410.551 408.565 410.05 407.947 410.05C407.329 410.05 406.828 410.551 406.828 411.169C406.828 411.787 407.329 412.288 407.947 412.288Z" fill="#C63846"/>
                <path d="M414.734 414.826C415.392 414.826 415.925 414.293 415.925 413.635C415.925 412.977 415.392 412.444 414.734 412.444C414.076 412.444 413.543 412.977 413.543 413.635C413.543 414.293 414.076 414.826 414.734 414.826Z" fill="#C63846"/>
                <path d="M421.517 417.364C422.215 417.364 422.78 416.799 422.78 416.101C422.78 415.404 422.215 414.838 421.517 414.838C420.819 414.838 420.254 415.404 420.254 416.101C420.254 416.799 420.819 417.364 421.517 417.364Z" fill="#C63846"/>
                <path d="M428.302 419.903C429.04 419.903 429.638 419.305 429.638 418.568C429.638 417.83 429.04 417.232 428.302 417.232C427.565 417.232 426.967 417.83 426.967 418.568C426.967 419.305 427.565 419.903 428.302 419.903Z" fill="#C63846"/>
                <path d="M435.089 422.43C435.86 422.43 436.484 421.805 436.484 421.034C436.484 420.263 435.86 419.639 435.089 419.639C434.318 419.639 433.693 420.263 433.693 421.034C433.693 421.805 434.318 422.43 435.089 422.43Z" fill="#C63846"/>
                <path d="M441.874 424.967C442.678 424.967 443.329 424.316 443.329 423.512C443.329 422.708 442.678 422.056 441.874 422.056C441.07 422.056 440.418 422.708 440.418 423.512C440.418 424.316 441.07 424.967 441.874 424.967Z" fill="#C63846"/>
                <path d="M448.645 427.506C449.489 427.506 450.173 426.822 450.173 425.978C450.173 425.134 449.489 424.45 448.645 424.45C447.801 424.45 447.117 425.134 447.117 425.978C447.117 426.822 447.801 427.506 448.645 427.506Z" fill="#C63846"/>
                <path d="M455.432 430.045C456.316 430.045 457.032 429.328 457.032 428.445C457.032 427.561 456.316 426.845 455.432 426.845C454.548 426.845 453.832 427.561 453.832 428.445C453.832 429.328 454.548 430.045 455.432 430.045Z" fill="#C63846"/>
                <path d="M462.215 432.583C463.139 432.583 463.887 431.834 463.887 430.911C463.887 429.987 463.139 429.238 462.215 429.238C461.292 429.238 460.543 429.987 460.543 430.911C460.543 431.834 461.292 432.583 462.215 432.583Z" fill="#C63846"/>
                <path d="M469.002 435.12C469.966 435.12 470.747 434.339 470.747 433.376C470.747 432.413 469.966 431.632 469.002 431.632C468.039 431.632 467.258 432.413 467.258 433.376C467.258 434.339 468.039 435.12 469.002 435.12Z" fill="#C63846"/>
                <path d="M475.786 437.322C476.597 437.322 477.254 436.665 477.254 435.855C477.254 435.044 476.597 434.387 475.786 434.387C474.975 434.387 474.318 435.044 474.318 435.855C474.318 436.665 474.975 437.322 475.786 437.322Z" fill="#C63846"/>
                <path d="M310.527 383.547C310.62 383.547 310.696 383.472 310.696 383.379C310.696 383.286 310.62 383.211 310.527 383.211C310.435 383.211 310.359 383.286 310.359 383.379C310.359 383.472 310.435 383.547 310.527 383.547Z" fill="#C63846"/>
                <path d="M317.311 386.11C317.451 386.11 317.564 385.997 317.564 385.857C317.564 385.718 317.451 385.605 317.311 385.605C317.172 385.605 317.059 385.718 317.059 385.857C317.059 385.997 317.172 386.11 317.311 386.11Z" fill="#C63846"/>
                <path d="M324.087 388.649C324.266 388.649 324.411 388.503 324.411 388.324C324.411 388.144 324.266 387.999 324.087 387.999C323.907 387.999 323.762 388.144 323.762 388.324C323.762 388.503 323.907 388.649 324.087 388.649Z" fill="#C63846"/>
                <path d="M330.871 391.175C331.084 391.175 331.256 391.002 331.256 390.79C331.256 390.577 331.084 390.405 330.871 390.405C330.659 390.405 330.486 390.577 330.486 390.79C330.486 391.002 330.659 391.175 330.871 391.175Z" fill="#C63846"/>
                <path d="M337.656 393.713C337.909 393.713 338.114 393.508 338.114 393.256C338.114 393.004 337.909 392.799 337.656 392.799C337.404 392.799 337.199 393.004 337.199 393.256C337.199 393.508 337.404 393.713 337.656 393.713Z" fill="#C63846"/>
                <path d="M344.441 396.252C344.734 396.252 344.971 396.015 344.971 395.722C344.971 395.43 344.734 395.193 344.441 395.193C344.149 395.193 343.912 395.43 343.912 395.722C343.912 396.015 344.149 396.252 344.441 396.252Z" fill="#C63846"/>
                <path d="M351.227 398.802C351.559 398.802 351.828 398.533 351.828 398.201C351.828 397.869 351.559 397.599 351.227 397.599C350.894 397.599 350.625 397.869 350.625 398.201C350.625 398.533 350.894 398.802 351.227 398.802Z" fill="#C63846"/>
                <path d="M358.011 401.329C358.377 401.329 358.673 401.033 358.673 400.667C358.673 400.302 358.377 400.006 358.011 400.006C357.646 400.006 357.35 400.302 357.35 400.667C357.35 401.033 357.646 401.329 358.011 401.329Z" fill="#C63846"/>
                <path d="M364.783 403.867C365.188 403.867 365.517 403.538 365.517 403.133C365.517 402.728 365.188 402.399 364.783 402.399C364.377 402.399 364.049 402.728 364.049 403.133C364.049 403.538 364.377 403.867 364.783 403.867Z" fill="#C63846"/>
                <path d="M371.57 406.405C372.015 406.405 372.376 406.045 372.376 405.599C372.376 405.154 372.015 404.793 371.57 404.793C371.125 404.793 370.764 405.154 370.764 405.599C370.764 406.045 371.125 406.405 371.57 406.405Z" fill="#C63846"/>
                <path d="M378.354 408.931C378.833 408.931 379.221 408.544 379.221 408.065C379.221 407.587 378.833 407.199 378.354 407.199C377.876 407.199 377.488 407.587 377.488 408.065C377.488 408.544 377.876 408.931 378.354 408.931Z" fill="#C63846"/>
                <path d="M385.14 411.482C385.658 411.482 386.078 411.062 386.078 410.544C386.078 410.025 385.658 409.605 385.14 409.605C384.621 409.605 384.201 410.025 384.201 410.544C384.201 411.062 384.621 411.482 385.14 411.482Z" fill="#C63846"/>
                <path d="M391.923 414.02C392.481 414.02 392.933 413.567 392.933 413.009C392.933 412.451 392.481 411.999 391.923 411.999C391.365 411.999 390.912 412.451 390.912 413.009C390.912 413.567 391.365 414.02 391.923 414.02Z" fill="#C63846"/>
                <path d="M398.698 416.558C399.296 416.558 399.781 416.074 399.781 415.476C399.781 414.878 399.296 414.393 398.698 414.393C398.1 414.393 397.615 414.878 397.615 415.476C397.615 416.074 398.1 416.558 398.698 416.558Z" fill="#C63846"/>
                <path d="M405.481 419.098C406.119 419.098 406.636 418.581 406.636 417.943C406.636 417.305 406.119 416.788 405.481 416.788C404.843 416.788 404.326 417.305 404.326 417.943C404.326 418.581 404.843 419.098 405.481 419.098Z" fill="#C63846"/>
                <path d="M412.266 421.636C412.937 421.636 413.481 421.092 413.481 420.421C413.481 419.749 412.937 419.205 412.266 419.205C411.595 419.205 411.051 419.749 411.051 420.421C411.051 421.092 411.595 421.636 412.266 421.636Z" fill="#C63846"/>
                <path d="M419.051 424.162C419.755 424.162 420.326 423.591 420.326 422.887C420.326 422.183 419.755 421.612 419.051 421.612C418.346 421.612 417.775 422.183 417.775 422.887C417.775 423.591 418.346 424.162 419.051 424.162Z" fill="#C63846"/>
                <path d="M425.836 426.701C426.58 426.701 427.183 426.098 427.183 425.353C427.183 424.609 426.58 424.006 425.836 424.006C425.092 424.006 424.488 424.609 424.488 425.353C424.488 426.098 425.092 426.701 425.836 426.701Z" fill="#C63846"/>
                <path d="M432.609 429.238C433.393 429.238 434.029 428.602 434.029 427.818C434.029 427.034 433.393 426.399 432.609 426.399C431.825 426.399 431.189 427.034 431.189 427.818C431.189 428.602 431.825 429.238 432.609 429.238Z" fill="#C63846"/>
                <path d="M439.396 431.777C440.22 431.777 440.888 431.109 440.888 430.285C440.888 429.461 440.22 428.793 439.396 428.793C438.572 428.793 437.904 429.461 437.904 430.285C437.904 431.109 438.572 431.777 439.396 431.777Z" fill="#C63846"/>
                <path d="M446.179 434.327C447.043 434.327 447.743 433.627 447.743 432.763C447.743 431.9 447.043 431.199 446.179 431.199C445.315 431.199 444.615 431.9 444.615 432.763C444.615 433.627 445.315 434.327 446.179 434.327Z" fill="#C63846"/>
                <path d="M452.964 436.866C453.868 436.866 454.6 436.133 454.6 435.23C454.6 434.326 453.868 433.594 452.964 433.594C452.061 433.594 451.328 434.326 451.328 435.23C451.328 436.133 452.061 436.866 452.964 436.866Z" fill="#C63846"/>
                <path d="M459.751 439.392C460.688 439.392 461.447 438.632 461.447 437.695C461.447 436.759 460.688 435.999 459.751 435.999C458.814 435.999 458.055 436.759 458.055 437.695C458.055 438.632 458.814 439.392 459.751 439.392Z" fill="#C63846"/>
                <path d="M466.536 441.918C467.506 441.918 468.292 441.132 468.292 440.162C468.292 439.192 467.506 438.405 466.536 438.405C465.566 438.405 464.779 439.192 464.779 440.162C464.779 441.132 465.566 441.918 466.536 441.918Z" fill="#C63846"/>
                <path d="M473.307 444.445C474.31 444.445 475.123 443.631 475.123 442.628C475.123 441.625 474.31 440.812 473.307 440.812C472.304 440.812 471.49 441.625 471.49 442.628C471.49 443.631 472.304 444.445 473.307 444.445Z" fill="#C63846"/>
                <path d="M308.06 390.309C308.14 390.309 308.204 390.244 308.204 390.165C308.204 390.085 308.14 390.02 308.06 390.02C307.981 390.02 307.916 390.085 307.916 390.165C307.916 390.244 307.981 390.309 308.06 390.309Z" fill="#C63846"/>
                <path d="M314.833 392.907C314.986 392.907 315.11 392.783 315.11 392.63C315.11 392.478 314.986 392.354 314.833 392.354C314.681 392.354 314.557 392.478 314.557 392.63C314.557 392.783 314.681 392.907 314.833 392.907Z" fill="#C63846"/>
                <path d="M321.618 395.458C321.811 395.458 321.967 395.302 321.967 395.109C321.967 394.916 321.811 394.76 321.618 394.76C321.426 394.76 321.27 394.916 321.27 395.109C321.27 395.302 321.426 395.458 321.618 395.458Z" fill="#C63846"/>
                <path d="M328.405 397.996C328.638 397.996 328.826 397.808 328.826 397.575C328.826 397.343 328.638 397.154 328.405 397.154C328.173 397.154 327.984 397.343 327.984 397.575C327.984 397.808 328.173 397.996 328.405 397.996Z" fill="#C63846"/>
                <path d="M335.189 400.535C335.461 400.535 335.682 400.314 335.682 400.042C335.682 399.769 335.461 399.548 335.189 399.548C334.916 399.548 334.695 399.769 334.695 400.042C334.695 400.314 334.916 400.535 335.189 400.535Z" fill="#C63846"/>
                <path d="M341.973 403.062C342.279 403.062 342.527 402.814 342.527 402.508C342.527 402.202 342.279 401.955 341.973 401.955C341.668 401.955 341.42 402.202 341.42 402.508C341.42 402.814 341.668 403.062 341.973 403.062Z" fill="#C63846"/>
                <path d="M348.749 405.599C349.094 405.599 349.374 405.319 349.374 404.973C349.374 404.628 349.094 404.348 348.749 404.348C348.403 404.348 348.123 404.628 348.123 404.973C348.123 405.319 348.403 405.599 348.749 405.599Z" fill="#C63846"/>
                <path d="M355.533 408.137C355.912 408.137 356.219 407.83 356.219 407.452C356.219 407.073 355.912 406.766 355.533 406.766C355.155 406.766 354.848 407.073 354.848 407.452C354.848 407.83 355.155 408.137 355.533 408.137Z" fill="#C63846"/>
                <path d="M362.318 410.676C362.737 410.676 363.076 410.337 363.076 409.918C363.076 409.499 362.737 409.16 362.318 409.16C361.9 409.16 361.561 409.499 361.561 409.918C361.561 410.337 361.9 410.676 362.318 410.676Z" fill="#C63846"/>
                <path d="M369.104 413.214C369.562 413.214 369.934 412.842 369.934 412.384C369.934 411.925 369.562 411.554 369.104 411.554C368.645 411.554 368.273 411.925 368.273 412.384C368.273 412.842 368.645 413.214 369.104 413.214Z" fill="#C63846"/>
                <path d="M375.887 415.753C376.385 415.753 376.789 415.349 376.789 414.851C376.789 414.352 376.385 413.948 375.887 413.948C375.388 413.948 374.984 414.352 374.984 414.851C374.984 415.349 375.388 415.753 375.887 415.753Z" fill="#C63846"/>
                <path d="M382.673 418.291C383.205 418.291 383.636 417.86 383.636 417.328C383.636 416.797 383.205 416.366 382.673 416.366C382.142 416.366 381.711 416.797 381.711 417.328C381.711 417.86 382.142 418.291 382.673 418.291Z" fill="#C63846"/>
                <path d="M389.445 420.83C390.016 420.83 390.479 420.366 390.479 419.795C390.479 419.224 390.016 418.76 389.445 418.76C388.873 418.76 388.41 419.224 388.41 419.795C388.41 420.366 388.873 420.83 389.445 420.83Z" fill="#C63846"/>
                <path d="M396.232 423.367C396.843 423.367 397.339 422.872 397.339 422.261C397.339 421.649 396.843 421.154 396.232 421.154C395.621 421.154 395.125 421.649 395.125 422.261C395.125 422.872 395.621 423.367 396.232 423.367Z" fill="#C63846"/>
                <path d="M403.017 425.894C403.661 425.894 404.183 425.372 404.183 424.727C404.183 424.083 403.661 423.56 403.017 423.56C402.372 423.56 401.85 424.083 401.85 424.727C401.85 425.372 402.372 425.894 403.017 425.894Z" fill="#C63846"/>
                <path d="M409.802 428.433C410.486 428.433 411.041 427.878 411.041 427.193C411.041 426.509 410.486 425.954 409.802 425.954C409.117 425.954 408.562 426.509 408.562 427.193C408.563 427.878 409.117 428.433 409.802 428.433Z" fill="#C63846"/>
                <path d="M416.587 430.983C417.311 430.983 417.898 430.396 417.898 429.672C417.898 428.948 417.311 428.361 416.587 428.361C415.862 428.361 415.275 428.948 415.275 429.672C415.275 430.396 415.862 430.983 416.587 430.983Z" fill="#C63846"/>
                <path d="M423.36 433.521C424.124 433.521 424.744 432.902 424.744 432.138C424.744 431.374 424.124 430.754 423.36 430.754C422.596 430.754 421.977 431.374 421.977 432.138C421.977 432.902 422.596 433.521 423.36 433.521Z" fill="#C63846"/>
                <path d="M430.143 436.06C430.947 436.06 431.599 435.408 431.599 434.604C431.599 433.8 430.947 433.148 430.143 433.148C429.339 433.148 428.688 433.8 428.688 434.604C428.688 435.408 429.339 436.06 430.143 436.06Z" fill="#C63846"/>
                <path d="M436.928 438.586C437.765 438.586 438.444 437.908 438.444 437.071C438.444 436.233 437.765 435.555 436.928 435.555C436.091 435.555 435.412 436.233 435.412 437.071C435.412 437.908 436.091 438.586 436.928 438.586Z" fill="#C63846"/>
                <path d="M443.713 441.112C444.583 441.112 445.289 440.407 445.289 439.536C445.289 438.666 444.583 437.96 443.713 437.96C442.842 437.96 442.137 438.666 442.137 439.536C442.137 440.407 442.842 441.112 443.713 441.112Z" fill="#C63846"/>
                <path d="M450.5 443.662C451.41 443.662 452.148 442.924 452.148 442.014C452.148 441.104 451.41 440.366 450.5 440.366C449.589 440.366 448.852 441.104 448.852 442.014C448.852 442.924 449.589 443.662 450.5 443.662Z" fill="#C63846"/>
                <path d="M457.285 446.201C458.235 446.201 459.005 445.43 459.005 444.48C459.005 443.53 458.235 442.76 457.285 442.76C456.335 442.76 455.564 443.53 455.564 444.48C455.564 445.43 456.335 446.201 457.285 446.201Z" fill="#C63846"/>
                <path d="M464.058 448.739C465.048 448.739 465.851 447.937 465.851 446.947C465.851 445.957 465.048 445.154 464.058 445.154C463.068 445.154 462.266 445.957 462.266 446.947C462.266 447.937 463.068 448.739 464.058 448.739Z" fill="#C63846"/>
                <path d="M470.841 451.278C471.871 451.278 472.706 450.443 472.706 449.413C472.706 448.383 471.871 447.548 470.841 447.548C469.811 447.548 468.977 448.383 468.977 449.413C468.977 450.443 469.811 451.278 470.841 451.278Z" fill="#C63846"/>
                <path d="M305.581 397.019C305.62 397.019 305.652 396.987 305.652 396.948C305.652 396.909 305.62 396.877 305.581 396.877C305.542 396.877 305.51 396.909 305.51 396.948C305.51 396.987 305.542 397.019 305.581 397.019Z" fill="#C63846"/>
                <path d="M312.367 399.715C312.533 399.715 312.668 399.581 312.668 399.415C312.668 399.249 312.533 399.114 312.367 399.114C312.201 399.114 312.066 399.249 312.066 399.415C312.066 399.581 312.201 399.715 312.367 399.715Z" fill="#C63846"/>
                <path d="M319.154 402.255C319.36 402.255 319.527 402.088 319.527 401.882C319.527 401.676 319.36 401.509 319.154 401.509C318.948 401.509 318.781 401.676 318.781 401.882C318.781 402.088 318.948 402.255 319.154 402.255Z" fill="#C63846"/>
                <path d="M325.937 404.805C326.183 404.805 326.382 404.606 326.382 404.36C326.382 404.114 326.183 403.915 325.937 403.915C325.691 403.915 325.492 404.114 325.492 404.36C325.492 404.606 325.691 404.805 325.937 404.805Z" fill="#C63846"/>
                <path d="M332.722 407.331C333.001 407.331 333.227 407.105 333.227 406.826C333.227 406.547 333.001 406.321 332.722 406.321C332.443 406.321 332.217 406.547 332.217 406.826C332.217 407.105 332.443 407.331 332.722 407.331Z" fill="#C63846"/>
                <path d="M339.495 409.87C339.814 409.87 340.073 409.612 340.073 409.293C340.073 408.974 339.814 408.716 339.495 408.716C339.176 408.716 338.918 408.974 338.918 409.293C338.918 409.612 339.176 409.87 339.495 409.87Z" fill="#C63846"/>
                <path d="M346.28 412.408C346.639 412.408 346.93 412.118 346.93 411.759C346.93 411.4 346.639 411.109 346.28 411.109C345.922 411.109 345.631 411.4 345.631 411.759C345.631 412.118 345.922 412.408 346.28 412.408Z" fill="#C63846"/>
                <path d="M353.068 414.946C353.466 414.946 353.789 414.623 353.789 414.225C353.789 413.826 353.466 413.503 353.068 413.503C352.669 413.503 352.346 413.826 352.346 414.225C352.346 414.623 352.669 414.946 353.068 414.946Z" fill="#C63846"/>
                <path d="M359.851 417.497C360.289 417.497 360.645 417.141 360.645 416.703C360.645 416.264 360.289 415.909 359.851 415.909C359.412 415.909 359.057 416.264 359.057 416.703C359.057 417.141 359.412 417.497 359.851 417.497Z" fill="#C63846"/>
                <path d="M366.635 420.023C367.107 420.023 367.49 419.641 367.49 419.169C367.49 418.698 367.107 418.315 366.635 418.315C366.164 418.315 365.781 418.698 365.781 419.169C365.781 419.641 366.164 420.023 366.635 420.023Z" fill="#C63846"/>
                <path d="M373.422 422.561C373.934 422.561 374.349 422.147 374.349 421.635C374.349 421.123 373.934 420.709 373.422 420.709C372.911 420.709 372.496 421.123 372.496 421.635C372.496 422.147 372.911 422.561 373.422 422.561Z" fill="#C63846"/>
                <path d="M380.195 425.088C380.74 425.088 381.182 424.646 381.182 424.101C381.182 423.557 380.74 423.115 380.195 423.115C379.651 423.115 379.209 423.557 379.209 424.101C379.209 424.646 379.651 425.088 380.195 425.088Z" fill="#C63846"/>
                <path d="M386.981 427.639C387.565 427.639 388.039 427.165 388.039 426.58C388.039 425.995 387.565 425.521 386.981 425.521C386.396 425.521 385.922 425.995 385.922 426.58C385.922 427.165 386.396 427.639 386.981 427.639Z" fill="#C63846"/>
                <path d="M393.766 430.177C394.39 430.177 394.896 429.671 394.896 429.046C394.896 428.422 394.39 427.916 393.766 427.916C393.141 427.916 392.635 428.422 392.635 429.046C392.635 429.671 393.141 430.177 393.766 430.177Z" fill="#C63846"/>
                <path d="M400.549 432.714C401.213 432.714 401.752 432.176 401.752 431.511C401.752 430.847 401.213 430.308 400.549 430.308C399.884 430.308 399.346 430.847 399.346 431.511C399.346 432.176 399.884 432.714 400.549 432.714Z" fill="#C63846"/>
                <path d="M407.336 435.254C408.04 435.254 408.611 434.683 408.611 433.978C408.611 433.274 408.04 432.703 407.336 432.703C406.631 432.703 406.061 433.274 406.061 433.978C406.061 434.683 406.631 435.254 407.336 435.254Z" fill="#C63846"/>
                <path d="M414.107 437.78C414.844 437.78 415.442 437.182 415.442 436.445C415.442 435.707 414.844 435.109 414.107 435.109C413.369 435.109 412.771 435.707 412.771 436.445C412.771 437.182 413.369 437.78 414.107 437.78Z" fill="#C63846"/>
                <path d="M420.892 440.318C421.662 440.318 422.287 439.693 422.287 438.923C422.287 438.152 421.662 437.527 420.892 437.527C420.121 437.527 419.496 438.152 419.496 438.923C419.496 439.693 420.121 440.318 420.892 440.318Z" fill="#C63846"/>
                <path d="M427.679 442.857C428.489 442.857 429.146 442.2 429.146 441.389C429.146 440.578 428.489 439.921 427.679 439.921C426.868 439.921 426.211 440.578 426.211 441.389C426.211 442.2 426.868 442.857 427.679 442.857Z" fill="#C63846"/>
                <path d="M434.464 445.395C435.314 445.395 436.004 444.706 436.004 443.855C436.004 443.005 435.314 442.316 434.464 442.316C433.613 442.316 432.924 443.005 432.924 443.855C432.924 444.706 433.613 445.395 434.464 445.395Z" fill="#C63846"/>
                <path d="M441.249 447.933C442.139 447.933 442.861 447.211 442.861 446.321C442.861 445.431 442.139 444.709 441.249 444.709C440.358 444.709 439.637 445.431 439.637 446.321C439.637 447.211 440.358 447.933 441.249 447.933Z" fill="#C63846"/>
                <path d="M448.034 450.472C448.964 450.472 449.718 449.718 449.718 448.788C449.718 447.858 448.964 447.104 448.034 447.104C447.104 447.104 446.35 447.858 446.35 448.788C446.35 449.718 447.104 450.472 448.034 450.472Z" fill="#C63846"/>
                <path d="M454.805 453.023C455.775 453.023 456.562 452.237 456.562 451.267C456.562 450.297 455.775 449.51 454.805 449.51C453.835 449.51 453.049 450.297 453.049 451.267C453.049 452.237 453.835 453.023 454.805 453.023Z" fill="#C63846"/>
                <path d="M461.59 455.548C462.593 455.548 463.407 454.735 463.407 453.732C463.407 452.728 462.593 451.915 461.59 451.915C460.587 451.915 459.773 452.728 459.773 453.732C459.773 454.735 460.587 455.548 461.59 455.548Z" fill="#C63846"/>
                <path d="M468.377 458.075C469.413 458.075 470.253 457.235 470.253 456.198C470.253 455.162 469.413 454.321 468.377 454.321C467.34 454.321 466.5 455.162 466.5 456.198C466.5 457.235 467.34 458.075 468.377 458.075Z" fill="#C63846"/>
                <path d="M475.162 460.396C476.119 460.396 476.894 459.621 476.894 458.664C476.894 457.707 476.119 456.932 475.162 456.932C474.205 456.932 473.43 457.707 473.43 458.664C473.43 459.621 474.205 460.396 475.162 460.396Z" fill="#C63846"/>
                <path d="M309.902 406.489C310.061 406.489 310.191 406.36 310.191 406.2C310.191 406.041 310.061 405.912 309.902 405.912C309.743 405.912 309.613 406.041 309.613 406.2C309.613 406.36 309.743 406.489 309.902 406.489Z" fill="#C63846"/>
                <path d="M316.686 409.064C316.905 409.064 317.083 408.886 317.083 408.667C317.083 408.448 316.905 408.27 316.686 408.27C316.467 408.27 316.289 408.448 316.289 408.667C316.289 408.886 316.467 409.064 316.686 409.064Z" fill="#C63846"/>
                <path d="M323.471 411.602C323.73 411.602 323.94 411.392 323.94 411.132C323.94 410.873 323.73 410.663 323.471 410.663C323.212 410.663 323.002 410.873 323.002 411.132C323.002 411.392 323.212 411.602 323.471 411.602Z" fill="#C63846"/>
                <path d="M330.245 414.152C330.543 414.152 330.786 413.91 330.786 413.611C330.786 413.312 330.543 413.07 330.245 413.07C329.946 413.07 329.703 413.312 329.703 413.611C329.703 413.91 329.946 414.152 330.245 414.152Z" fill="#C63846"/>
                <path d="M337.029 416.679C337.361 416.679 337.631 416.409 337.631 416.077C337.631 415.745 337.361 415.476 337.029 415.476C336.697 415.476 336.428 415.745 336.428 416.077C336.428 416.409 336.697 416.679 337.029 416.679Z" fill="#C63846"/>
                <path d="M343.814 419.217C344.186 419.217 344.488 418.916 344.488 418.544C344.488 418.172 344.186 417.87 343.814 417.87C343.442 417.87 343.141 418.172 343.141 418.544C343.141 418.916 343.442 419.217 343.814 419.217Z" fill="#C63846"/>
                <path d="M350.599 421.756C351.011 421.756 351.345 421.422 351.345 421.01C351.345 420.598 351.011 420.264 350.599 420.264C350.187 420.264 349.854 420.598 349.854 421.01C349.854 421.422 350.187 421.756 350.599 421.756Z" fill="#C63846"/>
                <path d="M357.384 424.294C357.829 424.294 358.19 423.933 358.19 423.488C358.19 423.043 357.829 422.682 357.384 422.682C356.939 422.682 356.578 423.043 356.578 423.488C356.578 423.933 356.939 424.294 357.384 424.294Z" fill="#C63846"/>
                <path d="M364.171 426.832C364.656 426.832 365.049 426.439 365.049 425.954C365.049 425.469 364.656 425.076 364.171 425.076C363.686 425.076 363.293 425.469 363.293 425.954C363.293 426.439 363.686 426.832 364.171 426.832Z" fill="#C63846"/>
                <path d="M370.943 429.37C371.467 429.37 371.893 428.945 371.893 428.42C371.893 427.895 371.467 427.47 370.943 427.47C370.418 427.47 369.992 427.895 369.992 428.42C369.992 428.945 370.418 429.37 370.943 429.37Z" fill="#C63846"/>
                <path d="M377.73 431.909C378.294 431.909 378.752 431.451 378.752 430.886C378.752 430.322 378.294 429.864 377.73 429.864C377.165 429.864 376.707 430.322 376.707 430.886C376.707 431.451 377.165 431.909 377.73 431.909Z" fill="#C63846"/>
                <path d="M384.513 434.447C385.117 434.447 385.607 433.957 385.607 433.352C385.607 432.748 385.117 432.257 384.513 432.257C383.908 432.257 383.418 432.748 383.418 433.352C383.418 433.957 383.908 434.447 384.513 434.447Z" fill="#C63846"/>
                <path d="M391.297 436.985C391.935 436.985 392.452 436.468 392.452 435.831C392.452 435.193 391.935 434.676 391.297 434.676C390.66 434.676 390.143 435.193 390.143 435.831C390.143 436.468 390.66 436.985 391.297 436.985Z" fill="#C63846"/>
                <path d="M398.082 439.512C398.753 439.512 399.297 438.968 399.297 438.297C399.297 437.626 398.753 437.082 398.082 437.082C397.411 437.082 396.867 437.626 396.867 438.297C396.867 438.968 397.411 439.512 398.082 439.512Z" fill="#C63846"/>
                <path d="M404.858 442.051C405.568 442.051 406.145 441.474 406.145 440.763C406.145 440.053 405.568 439.476 404.858 439.476C404.147 439.476 403.57 440.053 403.57 440.763C403.57 441.474 404.147 442.051 404.858 442.051Z" fill="#C63846"/>
                <path d="M411.643 444.589C412.393 444.589 413.002 443.981 413.002 443.23C413.002 442.479 412.393 441.87 411.643 441.87C410.892 441.87 410.283 442.479 410.283 443.23C410.283 443.981 410.892 444.589 411.643 444.589Z" fill="#C63846"/>
                <path d="M418.426 447.127C419.216 447.127 419.857 446.486 419.857 445.695C419.857 444.905 419.216 444.264 418.426 444.264C417.635 444.264 416.994 444.905 416.994 445.695C416.994 446.486 417.635 447.127 418.426 447.127Z" fill="#C63846"/>
                <path d="M425.211 449.678C426.041 449.678 426.715 449.005 426.715 448.174C426.715 447.344 426.041 446.67 425.211 446.67C424.38 446.67 423.707 447.344 423.707 448.174C423.707 449.005 424.38 449.678 425.211 449.678Z" fill="#C63846"/>
                <path d="M431.998 452.216C432.868 452.216 433.574 451.511 433.574 450.64C433.574 449.77 432.868 449.064 431.998 449.064C431.127 449.064 430.422 449.77 430.422 450.64C430.422 451.511 431.127 452.216 431.998 452.216Z" fill="#C63846"/>
                <path d="M438.769 454.742C439.673 454.742 440.405 454.01 440.405 453.106C440.405 452.202 439.673 451.47 438.769 451.47C437.865 451.47 437.133 452.202 437.133 453.106C437.133 454.01 437.865 454.742 438.769 454.742Z" fill="#C63846"/>
                <path d="M445.554 457.269C446.491 457.269 447.25 456.509 447.25 455.572C447.25 454.636 446.491 453.876 445.554 453.876C444.617 453.876 443.857 454.636 443.857 455.572C443.857 456.509 444.617 457.269 445.554 457.269Z" fill="#C63846"/>
                <path d="M452.341 459.807C453.317 459.807 454.109 459.016 454.109 458.039C454.109 457.062 453.317 456.271 452.341 456.271C451.364 456.271 450.572 457.062 450.572 458.039C450.572 459.016 451.364 459.807 452.341 459.807Z" fill="#C63846"/>
                <path d="M459.126 462.358C460.142 462.358 460.966 461.534 460.966 460.517C460.966 459.501 460.142 458.677 459.126 458.677C458.109 458.677 457.285 459.501 457.285 460.517C457.285 461.534 458.109 462.358 459.126 462.358Z" fill="#C63846"/>
                <path d="M465.911 464.895C466.967 464.895 467.824 464.039 467.824 462.982C467.824 461.926 466.967 461.07 465.911 461.07C464.854 461.07 463.998 461.926 463.998 462.982C463.998 464.039 464.854 464.895 465.911 464.895Z" fill="#C63846"/>
                <path d="M472.696 467.434C473.792 467.434 474.681 466.545 474.681 465.449C474.681 464.352 473.792 463.464 472.696 463.464C471.6 463.464 470.711 464.352 470.711 465.449C470.711 466.545 471.6 467.434 472.696 467.434Z" fill="#C63846"/>
                <path d="M307.435 413.19C307.547 413.19 307.639 413.098 307.639 412.986C307.639 412.873 307.547 412.781 307.435 412.781C307.322 412.781 307.23 412.873 307.23 412.986C307.23 413.098 307.322 413.19 307.435 413.19Z" fill="#C63846"/>
                <path d="M314.22 415.874C314.452 415.874 314.641 415.685 314.641 415.452C314.641 415.22 314.452 415.031 314.22 415.031C313.987 415.031 313.799 415.22 313.799 415.452C313.799 415.685 313.987 415.874 314.22 415.874Z" fill="#C63846"/>
                <path d="M320.993 418.411C321.266 418.411 321.486 418.191 321.486 417.918C321.486 417.646 321.266 417.425 320.993 417.425C320.721 417.425 320.5 417.646 320.5 417.918C320.5 418.191 320.721 418.411 320.993 418.411Z" fill="#C63846"/>
                <path d="M327.778 420.95C328.091 420.95 328.344 420.697 328.344 420.385C328.344 420.072 328.091 419.819 327.778 419.819C327.466 419.819 327.213 420.072 327.213 420.385C327.213 420.697 327.466 420.95 327.778 420.95Z" fill="#C63846"/>
                <path d="M334.563 423.489C334.909 423.489 335.189 423.209 335.189 422.863C335.189 422.518 334.909 422.237 334.563 422.237C334.218 422.237 333.938 422.518 333.938 422.863C333.938 423.209 334.218 423.489 334.563 423.489Z" fill="#C63846"/>
                <path d="M341.348 426.027C341.734 426.027 342.046 425.715 342.046 425.329C342.046 424.944 341.734 424.632 341.348 424.632C340.963 424.632 340.65 424.944 340.65 425.329C340.65 425.715 340.963 426.027 341.348 426.027Z" fill="#C63846"/>
                <path d="M348.133 428.564C348.558 428.564 348.903 428.22 348.903 427.794C348.903 427.369 348.558 427.025 348.133 427.025C347.708 427.025 347.363 427.369 347.363 427.794C347.363 428.22 347.708 428.564 348.133 428.564Z" fill="#C63846"/>
                <path d="M354.907 431.104C355.372 431.104 355.749 430.727 355.749 430.262C355.749 429.796 355.372 429.419 354.907 429.419C354.441 429.419 354.064 429.796 354.064 430.262C354.064 430.727 354.441 431.104 354.907 431.104Z" fill="#C63846"/>
                <path d="M361.692 433.654C362.197 433.654 362.606 433.244 362.606 432.739C362.606 432.234 362.197 431.825 361.692 431.825C361.187 431.825 360.777 432.234 360.777 432.739C360.777 433.244 361.187 433.654 361.692 433.654Z" fill="#C63846"/>
                <path d="M368.476 436.18C369.015 436.18 369.451 435.744 369.451 435.206C369.451 434.667 369.015 434.231 368.476 434.231C367.938 434.231 367.502 434.667 367.502 435.206C367.502 435.744 367.938 436.18 368.476 436.18Z" fill="#C63846"/>
                <path d="M375.261 438.718C375.84 438.718 376.308 438.249 376.308 437.671C376.308 437.093 375.84 436.625 375.261 436.625C374.683 436.625 374.215 437.093 374.215 437.671C374.215 438.249 374.683 438.718 375.261 438.718Z" fill="#C63846"/>
                <path d="M382.046 441.245C382.658 441.245 383.153 440.75 383.153 440.138C383.153 439.527 382.658 439.032 382.046 439.032C381.435 439.032 380.939 439.527 380.939 440.138C380.939 440.75 381.435 441.245 382.046 441.245Z" fill="#C63846"/>
                <path d="M388.833 443.782C389.484 443.782 390.012 443.255 390.012 442.604C390.012 441.952 389.484 441.425 388.833 441.425C388.182 441.425 387.654 441.952 387.654 442.604C387.654 443.255 388.182 443.782 388.833 443.782Z" fill="#C63846"/>
                <path d="M395.605 446.333C396.296 446.333 396.856 445.773 396.856 445.082C396.856 444.391 396.296 443.831 395.605 443.831C394.914 443.831 394.354 444.391 394.354 445.082C394.354 445.773 394.914 446.333 395.605 446.333Z" fill="#C63846"/>
                <path d="M402.392 448.872C403.123 448.872 403.715 448.279 403.715 447.548C403.715 446.817 403.123 446.225 402.392 446.225C401.661 446.225 401.068 446.817 401.068 447.548C401.068 448.279 401.661 448.872 402.392 448.872Z" fill="#C63846"/>
                <path d="M409.175 451.41C409.946 451.41 410.57 450.785 410.57 450.015C410.57 449.244 409.946 448.619 409.175 448.619C408.404 448.619 407.779 449.244 407.779 450.015C407.779 450.785 408.404 451.41 409.175 451.41Z" fill="#C63846"/>
                <path d="M415.96 453.936C416.763 453.936 417.415 453.284 417.415 452.481C417.415 451.677 416.763 451.025 415.96 451.025C415.156 451.025 414.504 451.677 414.504 452.481C414.504 453.284 415.156 453.936 415.96 453.936Z" fill="#C63846"/>
                <path d="M422.744 456.463C423.581 456.463 424.26 455.784 424.26 454.947C424.26 454.11 423.581 453.431 422.744 453.431C421.907 453.431 421.229 454.11 421.229 454.947C421.229 455.784 421.907 456.463 422.744 456.463Z" fill="#C63846"/>
                <path d="M429.518 459.013C430.395 459.013 431.106 458.302 431.106 457.425C431.106 456.548 430.395 455.837 429.518 455.837C428.641 455.837 427.93 456.548 427.93 457.425C427.93 458.302 428.641 459.013 429.518 459.013Z" fill="#C63846"/>
                <path d="M436.305 461.551C437.222 461.551 437.965 460.808 437.965 459.891C437.965 458.974 437.222 458.231 436.305 458.231C435.388 458.231 434.645 458.974 434.645 459.891C434.645 460.808 435.388 461.551 436.305 461.551Z" fill="#C63846"/>
                <path d="M443.088 464.091C444.045 464.091 444.82 463.315 444.82 462.358C444.82 461.401 444.045 460.626 443.088 460.626C442.131 460.626 441.355 461.401 441.355 462.358C441.355 463.315 442.131 464.091 443.088 464.091Z" fill="#C63846"/>
                <path d="M449.873 466.628C450.87 466.628 451.677 465.82 451.677 464.824C451.677 463.827 450.87 463.019 449.873 463.019C448.876 463.019 448.068 463.827 448.068 464.824C448.068 465.82 448.876 466.628 449.873 466.628Z" fill="#C63846"/>
                <path d="M456.66 469.167C457.696 469.167 458.537 468.327 458.537 467.29C458.537 466.254 457.696 465.414 456.66 465.414C455.623 465.414 454.783 466.254 454.783 467.29C454.783 468.327 455.623 469.167 456.66 469.167Z" fill="#C63846"/>
                <path d="M463.445 471.706C464.514 471.706 465.382 470.838 465.382 469.769C465.382 468.699 464.514 467.832 463.445 467.832C462.375 467.832 461.508 468.699 461.508 469.769C461.508 470.838 462.375 471.706 463.445 471.706Z" fill="#C63846"/>
                <path d="M470.218 474.244C471.328 474.244 472.227 473.344 472.227 472.235C472.227 471.125 471.328 470.225 470.218 470.225C469.108 470.225 468.209 471.125 468.209 472.235C468.209 473.344 469.108 474.244 470.218 474.244Z" fill="#C63846"/>
                <path d="M304.97 419.841C305.009 419.841 305.041 419.809 305.041 419.769C305.041 419.73 305.009 419.698 304.97 419.698C304.93 419.698 304.898 419.73 304.898 419.769C304.898 419.809 304.93 419.841 304.97 419.841Z" fill="#C63846"/>
                <path d="M311.742 422.682C311.988 422.682 312.187 422.482 312.187 422.237C312.187 421.991 311.988 421.792 311.742 421.792C311.496 421.792 311.297 421.991 311.297 422.237C311.297 422.482 311.496 422.682 311.742 422.682Z" fill="#C63846"/>
                <path d="M318.527 425.22C318.813 425.22 319.044 424.989 319.044 424.703C319.044 424.417 318.813 424.186 318.527 424.186C318.241 424.186 318.01 424.417 318.01 424.703C318.01 424.989 318.241 425.22 318.527 425.22Z" fill="#C63846"/>
                <path d="M325.312 427.758C325.638 427.758 325.902 427.494 325.902 427.169C325.902 426.843 325.638 426.579 325.312 426.579C324.987 426.579 324.723 426.843 324.723 427.169C324.723 427.494 324.987 427.758 325.312 427.758Z" fill="#C63846"/>
                <path d="M332.097 430.297C332.463 430.297 332.759 430.001 332.759 429.635C332.759 429.27 332.463 428.974 332.097 428.974C331.732 428.974 331.436 429.27 331.436 429.635C331.436 430.001 331.732 430.297 332.097 430.297Z" fill="#C63846"/>
                <path d="M338.882 432.847C339.288 432.847 339.616 432.519 339.616 432.114C339.616 431.708 339.288 431.38 338.882 431.38C338.477 431.38 338.148 431.708 338.148 432.114C338.148 432.519 338.477 432.847 338.882 432.847Z" fill="#C63846"/>
                <path d="M345.655 435.374C346.094 435.374 346.449 435.019 346.449 434.58C346.449 434.141 346.094 433.786 345.655 433.786C345.217 433.786 344.861 434.141 344.861 434.58C344.861 435.019 345.217 435.374 345.655 435.374Z" fill="#C63846"/>
                <path d="M352.44 437.912C352.919 437.912 353.307 437.524 353.307 437.046C353.307 436.567 352.919 436.18 352.44 436.18C351.962 436.18 351.574 436.567 351.574 437.046C351.574 437.524 351.962 437.912 352.44 437.912Z" fill="#C63846"/>
                <path d="M359.225 440.439C359.737 440.439 360.151 440.024 360.151 439.512C360.151 439.001 359.737 438.586 359.225 438.586C358.714 438.586 358.299 439.001 358.299 439.512C358.299 440.024 358.714 440.439 359.225 440.439Z" fill="#C63846"/>
                <path d="M366.01 442.988C366.562 442.988 367.009 442.541 367.009 441.99C367.009 441.438 366.562 440.991 366.01 440.991C365.459 440.991 365.012 441.438 365.012 441.99C365.012 442.541 365.459 442.988 366.01 442.988Z" fill="#C63846"/>
                <path d="M372.795 445.528C373.387 445.528 373.866 445.048 373.866 444.457C373.866 443.866 373.387 443.386 372.795 443.386C372.204 443.386 371.725 443.866 371.725 444.457C371.725 445.048 372.204 445.528 372.795 445.528Z" fill="#C63846"/>
                <path d="M379.58 448.066C380.212 448.066 380.723 447.554 380.723 446.923C380.723 446.292 380.212 445.78 379.58 445.78C378.949 445.78 378.438 446.292 378.438 446.923C378.438 447.554 378.949 448.066 379.58 448.066Z" fill="#C63846"/>
                <path d="M386.354 450.604C387.025 450.604 387.569 450.06 387.569 449.389C387.569 448.718 387.025 448.174 386.354 448.174C385.683 448.174 385.139 448.718 385.139 449.389C385.139 450.06 385.683 450.604 386.354 450.604Z" fill="#C63846"/>
                <path d="M393.138 453.131C393.843 453.131 394.414 452.56 394.414 451.856C394.414 451.151 393.843 450.58 393.138 450.58C392.434 450.58 391.863 451.151 391.863 451.856C391.863 452.56 392.434 453.131 393.138 453.131Z" fill="#C63846"/>
                <path d="M399.925 455.669C400.663 455.669 401.261 455.071 401.261 454.333C401.261 453.596 400.663 452.998 399.925 452.998C399.188 452.998 398.59 453.596 398.59 454.333C398.59 455.071 399.188 455.669 399.925 455.669Z" fill="#C63846"/>
                <path d="M406.708 458.207C407.486 458.207 408.116 457.576 408.116 456.799C408.116 456.022 407.486 455.391 406.708 455.391C405.931 455.391 405.301 456.022 405.301 456.799C405.301 457.576 405.931 458.207 406.708 458.207Z" fill="#C63846"/>
                <path d="M413.495 460.745C414.313 460.745 414.975 460.083 414.975 459.265C414.975 458.448 414.313 457.786 413.495 457.786C412.678 457.786 412.016 458.448 412.016 459.265C412.016 460.083 412.678 460.745 413.495 460.745Z" fill="#C63846"/>
                <path d="M420.267 463.284C421.124 463.284 421.819 462.589 421.819 461.732C421.819 460.875 421.124 460.18 420.267 460.18C419.41 460.18 418.715 460.875 418.715 461.732C418.715 462.589 419.41 463.284 420.267 463.284Z" fill="#C63846"/>
                <path d="M427.052 465.822C427.949 465.822 428.676 465.095 428.676 464.198C428.676 463.301 427.949 462.574 427.052 462.574C426.155 462.574 425.428 463.301 425.428 464.198C425.428 465.095 426.155 465.822 427.052 465.822Z" fill="#C63846"/>
                <path d="M433.837 468.372C434.774 468.372 435.533 467.613 435.533 466.676C435.533 465.739 434.774 464.98 433.837 464.98C432.9 464.98 432.141 465.739 432.141 466.676C432.141 467.613 432.9 468.372 433.837 468.372Z" fill="#C63846"/>
                <path d="M440.622 470.899C441.592 470.899 442.378 470.112 442.378 469.142C442.378 468.172 441.592 467.386 440.622 467.386C439.652 467.386 438.865 468.172 438.865 469.142C438.865 470.112 439.652 470.899 440.622 470.899Z" fill="#C63846"/>
                <path d="M447.406 473.425C448.41 473.425 449.223 472.612 449.223 471.609C449.223 470.606 448.41 469.792 447.406 469.792C446.403 469.792 445.59 470.606 445.59 471.609C445.59 472.612 446.403 473.425 447.406 473.425Z" fill="#C63846"/>
                <path d="M454.193 475.963C455.237 475.963 456.082 475.118 456.082 474.075C456.082 473.031 455.237 472.186 454.193 472.186C453.15 472.186 452.305 473.031 452.305 474.075C452.305 475.118 453.15 475.963 454.193 475.963Z" fill="#C63846"/>
                <path d="M460.967 478.514C462.05 478.514 462.928 477.636 462.928 476.553C462.928 475.47 462.05 474.592 460.967 474.592C459.884 474.592 459.006 475.47 459.006 476.553C459.006 477.636 459.884 478.514 460.967 478.514Z" fill="#C63846"/>
                <path d="M309.276 429.407C309.488 429.407 309.661 429.235 309.661 429.022C309.661 428.81 309.488 428.637 309.276 428.637C309.063 428.637 308.891 428.81 308.891 429.022C308.891 429.235 309.063 429.407 309.276 429.407Z" fill="#C63846"/>
                <path d="M316.063 432.029C316.362 432.029 316.604 431.787 316.604 431.488C316.604 431.189 316.362 430.947 316.063 430.947C315.764 430.947 315.521 431.189 315.521 431.488C315.521 431.787 315.764 432.029 316.063 432.029Z" fill="#C63846"/>
                <path d="M322.848 434.569C323.187 434.569 323.461 434.294 323.461 433.955C323.461 433.616 323.187 433.342 322.848 433.342C322.509 433.342 322.234 433.616 322.234 433.955C322.234 434.294 322.509 434.569 322.848 434.569Z" fill="#C63846"/>
                <path d="M329.631 437.106C330.01 437.106 330.317 436.799 330.317 436.42C330.317 436.042 330.01 435.734 329.631 435.734C329.252 435.734 328.945 436.042 328.945 436.42C328.945 436.799 329.252 437.106 329.631 437.106Z" fill="#C63846"/>
                <path d="M336.404 439.644C336.816 439.644 337.15 439.311 337.15 438.899C337.15 438.487 336.816 438.153 336.404 438.153C335.992 438.153 335.658 438.487 335.658 438.899C335.658 439.311 335.992 439.644 336.404 439.644Z" fill="#C63846"/>
                <path d="M343.189 442.183C343.641 442.183 344.007 441.817 344.007 441.365C344.007 440.913 343.641 440.547 343.189 440.547C342.737 440.547 342.371 440.913 342.371 441.365C342.371 441.817 342.737 442.183 343.189 442.183Z" fill="#C63846"/>
                <path d="M349.974 444.722C350.466 444.722 350.864 444.323 350.864 443.831C350.864 443.34 350.466 442.941 349.974 442.941C349.483 442.941 349.084 443.34 349.084 443.831C349.084 444.323 349.483 444.722 349.974 444.722Z" fill="#C63846"/>
                <path d="M356.759 447.26C357.291 447.26 357.722 446.829 357.722 446.298C357.722 445.766 357.291 445.335 356.759 445.335C356.228 445.335 355.797 445.766 355.797 446.298C355.797 446.829 356.228 447.26 356.759 447.26Z" fill="#C63846"/>
                <path d="M363.544 449.799C364.116 449.799 364.579 449.336 364.579 448.764C364.579 448.193 364.116 447.73 363.544 447.73C362.973 447.73 362.51 448.193 362.51 448.764C362.51 449.336 362.973 449.799 363.544 449.799Z" fill="#C63846"/>
                <path d="M370.329 452.336C370.934 452.336 371.424 451.846 371.424 451.241C371.424 450.637 370.934 450.147 370.329 450.147C369.725 450.147 369.234 450.637 369.234 451.241C369.234 451.846 369.725 452.336 370.329 452.336Z" fill="#C63846"/>
                <path d="M377.102 454.863C377.74 454.863 378.257 454.345 378.257 453.708C378.257 453.07 377.74 452.553 377.102 452.553C376.464 452.553 375.947 453.07 375.947 453.708C375.947 454.345 376.464 454.863 377.102 454.863Z" fill="#C63846"/>
                <path d="M383.887 457.401C384.565 457.401 385.114 456.852 385.114 456.174C385.114 455.496 384.565 454.947 383.887 454.947C383.21 454.947 382.66 455.496 382.66 456.174C382.66 456.852 383.21 457.401 383.887 457.401Z" fill="#C63846"/>
                <path d="M390.672 459.939C391.39 459.939 391.972 459.357 391.972 458.64C391.972 457.922 391.39 457.341 390.672 457.341C389.955 457.341 389.373 457.922 389.373 458.64C389.373 459.357 389.955 459.939 390.672 459.939Z" fill="#C63846"/>
                <path d="M397.457 462.478C398.215 462.478 398.829 461.864 398.829 461.107C398.829 460.35 398.215 459.736 397.457 459.736C396.7 459.736 396.086 460.35 396.086 461.107C396.086 461.864 396.7 462.478 397.457 462.478Z" fill="#C63846"/>
                <path d="M404.242 465.028C405.04 465.028 405.686 464.382 405.686 463.585C405.686 462.787 405.04 462.141 404.242 462.141C403.445 462.141 402.799 462.787 402.799 463.585C402.799 464.382 403.445 465.028 404.242 465.028Z" fill="#C63846"/>
                <path d="M411.016 467.568C411.853 467.568 412.532 466.889 412.532 466.052C412.532 465.215 411.853 464.536 411.016 464.536C410.179 464.536 409.5 465.215 409.5 466.052C409.5 466.889 410.179 467.568 411.016 467.568Z" fill="#C63846"/>
                <path d="M417.801 470.094C418.671 470.094 419.377 469.389 419.377 468.518C419.377 467.648 418.671 466.942 417.801 466.942C416.93 466.942 416.225 467.648 416.225 468.518C416.225 469.389 416.93 470.094 417.801 470.094Z" fill="#C63846"/>
                <path d="M424.585 472.619C425.489 472.619 426.221 471.887 426.221 470.983C426.221 470.08 425.489 469.347 424.585 469.347C423.682 469.347 422.949 470.08 422.949 470.983C422.949 471.887 423.682 472.619 424.585 472.619Z" fill="#C63846"/>
                <path d="M431.37 475.158C432.314 475.158 433.079 474.393 433.079 473.45C433.079 472.506 432.314 471.741 431.37 471.741C430.427 471.741 429.662 472.506 429.662 473.45C429.662 474.393 430.427 475.158 431.37 475.158Z" fill="#C63846"/>
                <path d="M438.157 477.709C439.141 477.709 439.938 476.911 439.938 475.928C439.938 474.945 439.141 474.148 438.157 474.148C437.174 474.148 436.377 474.945 436.377 475.928C436.377 476.911 437.174 477.709 438.157 477.709Z" fill="#C63846"/>
                <path d="M306.811 436.025C306.938 436.025 307.04 435.922 307.04 435.796C307.04 435.669 306.938 435.567 306.811 435.567C306.685 435.567 306.582 435.669 306.582 435.796C306.582 435.922 306.685 436.025 306.811 436.025Z" fill="#C63846"/>
                <path d="M313.595 438.838C313.907 438.838 314.16 438.585 314.16 438.273C314.16 437.961 313.907 437.708 313.595 437.708C313.282 437.708 313.029 437.961 313.029 438.273C313.029 438.585 313.282 438.838 313.595 438.838Z" fill="#C63846"/>
                <path d="M320.38 441.377C320.732 441.377 321.017 441.092 321.017 440.739C321.017 440.387 320.732 440.102 320.38 440.102C320.028 440.102 319.742 440.387 319.742 440.739C319.742 441.092 320.028 441.377 320.38 441.377Z" fill="#C63846"/>
                <path d="M327.153 443.916C327.545 443.916 327.863 443.598 327.863 443.206C327.863 442.814 327.545 442.496 327.153 442.496C326.761 442.496 326.443 442.814 326.443 443.206C326.443 443.598 326.761 443.916 327.153 443.916Z" fill="#C63846"/>
                <path d="M333.938 446.453C334.37 446.453 334.72 446.103 334.72 445.671C334.72 445.24 334.37 444.89 333.938 444.89C333.506 444.89 333.156 445.24 333.156 445.671C333.156 446.103 333.506 446.453 333.938 446.453Z" fill="#C63846"/>
                <path d="M340.721 449.004C341.193 449.004 341.575 448.622 341.575 448.15C341.575 447.678 341.193 447.296 340.721 447.296C340.25 447.296 339.867 447.678 339.867 448.15C339.867 448.622 340.25 449.004 340.721 449.004Z" fill="#C63846"/>
                <path d="M347.51 451.531C348.015 451.531 348.424 451.121 348.424 450.616C348.424 450.111 348.015 449.702 347.51 449.702C347.005 449.702 346.596 450.111 346.596 450.616C346.596 451.121 347.005 451.531 347.51 451.531Z" fill="#C63846"/>
                <path d="M354.293 454.069C354.838 454.069 355.28 453.627 355.28 453.082C355.28 452.537 354.838 452.096 354.293 452.096C353.748 452.096 353.307 452.537 353.307 453.082C353.307 453.627 353.748 454.069 354.293 454.069Z" fill="#C63846"/>
                <path d="M361.066 456.595C361.644 456.595 362.113 456.127 362.113 455.549C362.113 454.971 361.644 454.502 361.066 454.502C360.488 454.502 360.02 454.971 360.02 455.549C360.02 456.127 360.488 456.595 361.066 456.595Z" fill="#C63846"/>
                <path d="M367.851 459.134C368.469 459.134 368.97 458.633 368.97 458.015C368.97 457.397 368.469 456.896 367.851 456.896C367.233 456.896 366.732 457.397 366.732 458.015C366.732 458.633 367.233 459.134 367.851 459.134Z" fill="#C63846"/>
                <path d="M374.636 461.684C375.294 461.684 375.827 461.151 375.827 460.493C375.827 459.836 375.294 459.302 374.636 459.302C373.979 459.302 373.445 459.836 373.445 460.493C373.445 461.151 373.979 461.684 374.636 461.684Z" fill="#C63846"/>
                <path d="M381.421 464.222C382.119 464.222 382.685 463.656 382.685 462.958C382.685 462.261 382.119 461.695 381.421 461.695C380.724 461.695 380.158 462.261 380.158 462.958C380.158 463.656 380.724 464.222 381.421 464.222Z" fill="#C63846"/>
                <path d="M388.206 466.76C388.944 466.76 389.542 466.162 389.542 465.425C389.542 464.687 388.944 464.089 388.206 464.089C387.469 464.089 386.871 464.687 386.871 465.425C386.871 466.162 387.469 466.76 388.206 466.76Z" fill="#C63846"/>
                <path d="M394.991 469.287C395.762 469.287 396.387 468.662 396.387 467.891C396.387 467.121 395.762 466.496 394.991 466.496C394.22 466.496 393.596 467.121 393.596 467.891C393.596 468.662 394.22 469.287 394.991 469.287Z" fill="#C63846"/>
                <path d="M401.764 471.813C402.568 471.813 403.22 471.162 403.22 470.358C403.22 469.554 402.568 468.902 401.764 468.902C400.96 468.902 400.309 469.554 400.309 470.358C400.309 471.162 400.96 471.813 401.764 471.813Z" fill="#C63846"/>
                <path d="M408.549 474.364C409.393 474.364 410.077 473.68 410.077 472.836C410.077 471.992 409.393 471.308 408.549 471.308C407.706 471.308 407.021 471.992 407.021 472.836C407.021 473.68 407.706 474.364 408.549 474.364Z" fill="#C63846"/>
                <path d="M415.334 476.902C416.218 476.902 416.934 476.186 416.934 475.302C416.934 474.419 416.218 473.702 415.334 473.702C414.451 473.702 413.734 474.419 413.734 475.302C413.734 476.186 414.451 476.902 415.334 476.902Z" fill="#C63846"/>
                <path d="M304.343 442.638C304.376 442.638 304.402 442.612 304.402 442.579C304.402 442.546 304.376 442.519 304.343 442.519C304.31 442.519 304.283 442.546 304.283 442.579C304.283 442.612 304.31 442.638 304.343 442.638Z" fill="#C63846"/>
                <path d="M311.128 445.636C311.447 445.636 311.706 445.378 311.706 445.059C311.706 444.74 311.447 444.481 311.128 444.481C310.809 444.481 310.551 444.74 310.551 445.059C310.551 445.378 310.809 445.636 311.128 445.636Z" fill="#C63846"/>
                <path d="M317.902 448.186C318.267 448.186 318.564 447.89 318.564 447.524C318.564 447.159 318.267 446.863 317.902 446.863C317.536 446.863 317.24 447.159 317.24 447.524C317.24 447.89 317.536 448.186 317.902 448.186Z" fill="#C63846"/>
                <path d="M324.687 450.725C325.092 450.725 325.421 450.396 325.421 449.991C325.421 449.585 325.092 449.257 324.687 449.257C324.282 449.257 323.953 449.585 323.953 449.991C323.953 450.396 324.282 450.725 324.687 450.725Z" fill="#C63846"/>
                <path d="M331.472 453.263C331.917 453.263 332.278 452.902 332.278 452.457C332.278 452.012 331.917 451.651 331.472 451.651C331.027 451.651 330.666 452.012 330.666 452.457C330.666 452.902 331.027 453.263 331.472 453.263Z" fill="#C63846"/>
                <path d="M338.257 455.789C338.735 455.789 339.123 455.401 339.123 454.923C339.123 454.445 338.735 454.057 338.257 454.057C337.778 454.057 337.391 454.445 337.391 454.923C337.391 455.401 337.778 455.789 338.257 455.789Z" fill="#C63846"/>
                <path d="M345.042 458.34C345.56 458.34 345.98 457.92 345.98 457.401C345.98 456.883 345.56 456.463 345.042 456.463C344.524 456.463 344.104 456.883 344.104 457.401C344.104 457.92 344.524 458.34 345.042 458.34Z" fill="#C63846"/>
                <path d="M351.815 460.878C352.373 460.878 352.826 460.425 352.826 459.867C352.826 459.309 352.373 458.857 351.815 458.857C351.257 458.857 350.805 459.309 350.805 459.867C350.805 460.425 351.257 460.878 351.815 460.878Z" fill="#C63846"/>
                <path d="M358.6 463.416C359.198 463.416 359.683 462.931 359.683 462.333C359.683 461.735 359.198 461.251 358.6 461.251C358.002 461.251 357.518 461.735 357.518 462.333C357.518 462.931 358.002 463.416 358.6 463.416Z" fill="#C63846"/>
                <path d="M365.383 465.955C366.021 465.955 366.538 465.438 366.538 464.8C366.538 464.162 366.021 463.645 365.383 463.645C364.746 463.645 364.229 464.162 364.229 464.8C364.229 465.438 364.746 465.955 365.383 465.955Z" fill="#C63846"/>
                <path d="M372.168 468.481C372.839 468.481 373.383 467.937 373.383 467.266C373.383 466.595 372.839 466.051 372.168 466.051C371.497 466.051 370.953 466.595 370.953 467.266C370.953 467.937 371.497 468.481 372.168 468.481Z" fill="#C63846"/>
                <path d="M378.957 471.019C379.661 471.019 380.232 470.448 380.232 469.744C380.232 469.04 379.661 468.469 378.957 468.469C378.253 468.469 377.682 469.04 377.682 469.744C377.682 470.448 378.253 471.019 378.957 471.019Z" fill="#C63846"/>
                <path d="M385.742 473.558C386.486 473.558 387.089 472.955 387.089 472.21C387.089 471.466 386.486 470.863 385.742 470.863C384.998 470.863 384.395 471.466 384.395 472.21C384.395 472.955 384.998 473.558 385.742 473.558Z" fill="#C63846"/>
                <path d="M392.513 476.096C393.297 476.096 393.933 475.461 393.933 474.677C393.933 473.893 393.297 473.257 392.513 473.257C391.729 473.257 391.094 473.893 391.094 474.677C391.094 475.461 391.729 476.096 392.513 476.096Z" fill="#C63846"/>
                <path d="M308.65 452.276C308.896 452.276 309.095 452.077 309.095 451.831C309.095 451.585 308.896 451.386 308.65 451.386C308.404 451.386 308.205 451.585 308.205 451.831C308.205 452.077 308.404 452.276 308.65 452.276Z" fill="#C63846"/>
                <path d="M315.436 454.995C315.814 454.995 316.121 454.688 316.121 454.309C316.121 453.931 315.814 453.624 315.436 453.624C315.057 453.624 314.75 453.931 314.75 454.309C314.75 454.688 315.057 454.995 315.436 454.995Z" fill="#C63846"/>
                <path d="M322.221 457.533C322.639 457.533 322.979 457.194 322.979 456.775C322.979 456.356 322.639 456.017 322.221 456.017C321.802 456.017 321.463 456.356 321.463 456.775C321.463 457.194 321.802 457.533 322.221 457.533Z" fill="#C63846"/>
                <path d="M329.006 460.072C329.464 460.072 329.836 459.701 329.836 459.242C329.836 458.784 329.464 458.412 329.006 458.412C328.547 458.412 328.176 458.784 328.176 459.242C328.176 459.701 328.547 460.072 329.006 460.072Z" fill="#C63846"/>
                <path d="M335.791 462.61C336.289 462.61 336.693 462.206 336.693 461.708C336.693 461.21 336.289 460.806 335.791 460.806C335.293 460.806 334.889 461.21 334.889 461.708C334.889 462.206 335.293 462.61 335.791 462.61Z" fill="#C63846"/>
                <path d="M342.564 465.149C343.102 465.149 343.539 464.712 343.539 464.174C343.539 463.636 343.102 463.2 342.564 463.2C342.026 463.2 341.59 463.636 341.59 464.174C341.59 464.712 342.026 465.149 342.564 465.149Z" fill="#C63846"/>
                <path d="M349.349 467.687C349.92 467.687 350.384 467.224 350.384 466.653C350.384 466.081 349.92 465.618 349.349 465.618C348.778 465.618 348.314 466.081 348.314 466.653C348.314 467.224 348.778 467.687 349.349 467.687Z" fill="#C63846"/>
                <path d="M356.134 470.226C356.745 470.226 357.241 469.73 357.241 469.119C357.241 468.508 356.745 468.012 356.134 468.012C355.523 468.012 355.027 468.508 355.027 469.119C355.027 469.73 355.523 470.226 356.134 470.226Z" fill="#C63846"/>
                <path d="M362.919 472.752C363.563 472.752 364.086 472.23 364.086 471.586C364.086 470.941 363.563 470.419 362.919 470.419C362.274 470.419 361.752 470.941 361.752 471.586C361.752 472.23 362.274 472.752 362.919 472.752Z" fill="#C63846"/>
                <path d="M369.704 475.29C370.388 475.29 370.943 474.735 370.943 474.051C370.943 473.366 370.388 472.811 369.704 472.811C369.02 472.811 368.465 473.366 368.465 474.051C368.465 474.735 369.02 475.29 369.704 475.29Z" fill="#C63846"/>
                <path d="M376.477 477.828C377.202 477.828 377.789 477.241 377.789 476.517C377.789 475.793 377.202 475.206 376.477 475.206C375.753 475.206 375.166 475.793 375.166 476.517C375.166 477.241 375.753 477.828 376.477 477.828Z" fill="#C63846"/>
                <path d="M306.184 458.846C306.311 458.846 306.413 458.744 306.413 458.617C306.413 458.491 306.311 458.388 306.184 458.388C306.058 458.388 305.955 458.491 305.955 458.617C305.955 458.744 306.058 458.846 306.184 458.846Z" fill="#C63846"/>
                <path d="M312.97 461.805C313.369 461.805 313.692 461.482 313.692 461.083C313.692 460.684 313.369 460.361 312.97 460.361C312.571 460.361 312.248 460.684 312.248 461.083C312.248 461.482 312.571 461.805 312.97 461.805Z" fill="#C63846"/>
                <path d="M319.755 464.355C320.193 464.355 320.549 463.999 320.549 463.561C320.549 463.122 320.193 462.767 319.755 462.767C319.316 462.767 318.961 463.122 318.961 463.561C318.961 463.999 319.316 464.355 319.755 464.355Z" fill="#C63846"/>
                <path d="M326.54 466.881C327.011 466.881 327.394 466.499 327.394 466.027C327.394 465.555 327.011 465.173 326.54 465.173C326.068 465.173 325.686 465.555 325.686 466.027C325.686 466.499 326.068 466.881 326.54 466.881Z" fill="#C63846"/>
                <path d="M333.313 469.419C333.825 469.419 334.239 469.004 334.239 468.493C334.239 467.981 333.825 467.567 333.313 467.567C332.801 467.567 332.387 467.981 332.387 468.493C332.387 469.004 332.801 469.419 333.313 469.419Z" fill="#C63846"/>
                <path d="M340.098 471.945C340.643 471.945 341.084 471.503 341.084 470.958C341.084 470.414 340.643 469.972 340.098 469.972C339.553 469.972 339.111 470.414 339.111 470.958C339.111 471.503 339.553 471.945 340.098 471.945Z" fill="#C63846"/>
                <path d="M346.883 474.484C347.468 474.484 347.942 474.01 347.942 473.425C347.942 472.84 347.468 472.366 346.883 472.366C346.298 472.366 345.824 472.84 345.824 473.425C345.824 474.01 346.298 474.484 346.883 474.484Z" fill="#C63846"/>
                <path d="M353.668 477.034C354.293 477.034 354.799 476.528 354.799 475.903C354.799 475.279 354.293 474.773 353.668 474.773C353.043 474.773 352.537 475.279 352.537 475.903C352.537 476.528 353.043 477.034 353.668 477.034Z" fill="#C63846"/>
                <path d="M303.717 465.435C303.736 465.435 303.752 465.419 303.752 465.4C303.752 465.38 303.736 465.365 303.717 465.365C303.697 465.365 303.682 465.38 303.682 465.4C303.682 465.419 303.697 465.435 303.717 465.435Z" fill="#C63846"/>
                <path d="M310.502 468.554C310.881 468.554 311.188 468.247 311.188 467.868C311.188 467.489 310.881 467.182 310.502 467.182C310.123 467.182 309.816 467.489 309.816 467.868C309.816 468.247 310.123 468.554 310.502 468.554Z" fill="#C63846"/>
                <path d="M317.288 471.14C317.734 471.14 318.094 470.779 318.094 470.334C318.094 469.888 317.734 469.528 317.288 469.528C316.843 469.528 316.482 469.888 316.482 470.334C316.482 470.779 316.843 471.14 317.288 471.14Z" fill="#C63846"/>
                <path d="M324.062 473.69C324.547 473.69 324.94 473.297 324.94 472.812C324.94 472.327 324.547 471.934 324.062 471.934C323.577 471.934 323.184 472.327 323.184 472.812C323.184 473.297 323.577 473.69 324.062 473.69Z" fill="#C63846"/>
                <path d="M330.847 476.229C331.372 476.229 331.797 475.803 331.797 475.279C331.797 474.754 331.372 474.328 330.847 474.328C330.322 474.328 329.896 474.754 329.896 475.279C329.896 475.803 330.322 476.229 330.847 476.229Z" fill="#C63846"/>
                <path d="M308.038 475.146C308.311 475.146 308.531 474.925 308.531 474.653C308.531 474.381 308.311 474.16 308.038 474.16C307.766 474.16 307.545 474.381 307.545 474.653C307.545 474.925 307.766 475.146 308.038 475.146Z" fill="#C63846"/>
            </g>
            <path d="M477.794 311.413V355.336C474.173 354.975 470.516 354.782 466.799 354.782H461.998C408.452 354.782 365.251 313.952 359.621 261.825C371.375 277.235 389.913 287.184 410.786 287.184C436.266 287.184 459.628 296.279 477.794 311.413Z" fill="#C63846"/>
            <mask id="mask1_1290_5134" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="157" y="467" width="222" height="172">
                <path d="M339.904 601.571C324.782 592.981 307.29 588.073 288.655 588.073C280.871 588.073 273.28 587.218 265.978 585.582C257.244 583.645 248.931 580.602 241.171 576.62C207.643 559.38 184.713 524.433 184.713 484.131V467.397C167.931 481.725 157.309 503.031 157.309 526.815C157.309 552.559 169.76 575.393 188.972 589.624C192.244 592.055 195.444 594.569 198.416 597.336C208.906 607.153 219.962 620.771 230.116 638.985H378.064C368.824 623.429 355.639 610.497 339.904 601.571Z" fill="white"/>
            </mask>
            <g mask="url(#mask1_1290_5134)">
                <path d="M339.904 601.57C355.639 610.497 368.824 623.429 378.064 638.984H230.116C219.962 620.771 208.906 607.152 198.416 597.336C195.444 594.569 192.244 592.054 188.972 589.624C169.76 575.393 157.309 552.559 157.309 526.814C157.309 503.031 167.931 481.725 184.713 467.397V484.131C184.713 524.432 207.643 559.38 241.171 576.62C248.931 580.602 257.244 583.645 265.978 585.582C273.28 587.218 280.871 588.072 288.655 588.072C307.29 588.072 324.782 592.981 339.904 601.57Z" fill="#DB4B55"/>
            </g>
            <path d="M247.896 519.453L247.27 519.621L241.171 576.62C207.643 559.381 184.713 524.433 184.713 484.132L184.713 406.477L307.241 406.477V484.132C307.241 517.071 317.527 547.615 335.079 572.711L339.903 601.571C324.781 592.982 307.289 588.073 288.654 588.073C280.871 588.073 273.28 587.219 265.977 585.583L247.896 519.453Z" fill="#131200"/>
            <path d="M318.756 153.901L300.638 199.833L255.477 190.774L270.165 153.901L318.756 153.901Z" fill="#F7F0F5"/>
            <path d="M307.243 377.664V406.476H225.004L217.016 297.855L212.745 239.906C212.48 237.945 212.336 235.948 212.336 233.914C212.336 230.955 212.637 228.068 213.202 225.289C217.196 205.595 234.604 190.774 255.476 190.774L300.638 199.833C275.675 203.033 256.09 224.278 255.488 249.927V255.593C255.476 277.055 259.519 287.714 265.293 296.821C270.093 304.412 275.711 310.607 281.63 316.286H281.606C297.57 331.756 307.243 353.688 307.243 377.664Z" fill="#F7F0F5"/>
            <mask id="mask2_1290_5134" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="206" y="54" width="95" height="100">
                <path d="M286.175 143.496C293.971 134.858 299.072 124.055 300.503 112.506C300.515 112.421 300.527 112.349 300.527 112.265C300.768 110.28 300.888 108.283 300.888 106.298C300.888 100.584 299.902 94.8813 297.953 89.4798C296.425 85.2211 294.32 81.227 291.733 77.5698C289.135 73.9126 286.055 70.6163 282.542 67.7652C277.333 63.5305 271.474 60.2583 265.243 58.0447C259.011 55.8312 252.407 54.6763 245.694 54.6763C233.567 54.6763 221.729 58.4056 211.804 65.3712L210.613 66.2012C207.63 68.2825 206.006 71.6389 206.006 75.0555C206.006 75.9818 206.126 76.9202 206.367 77.8345C206.595 78.6766 206.932 79.5187 207.377 80.3127C208.075 81.5639 208.773 82.7789 209.447 83.9579C213.453 90.8151 217.29 96.4693 221.513 101.859C226.457 108.163 231.895 114.094 238.668 121.167C241.639 124.271 245.489 125.907 249.399 126.136V125.992C249.664 125.342 249.976 124.704 250.349 124.079C253.994 118.052 261.959 116.235 267.865 120.205C273.255 123.826 274.915 131.201 271.619 136.783C271.402 137.156 271.162 137.504 270.921 137.841C269.91 139.213 269.104 140.717 268.479 142.293L270.163 153.902H296.798L300.636 149.583L285.947 143.736L286.175 143.496Z" fill="white"/>
            </mask>
            <g mask="url(#mask2_1290_5134)">
                <path d="M300.89 106.298C300.89 108.283 300.77 110.28 300.529 112.265C300.529 112.349 300.517 112.421 300.505 112.506C299.074 124.055 293.973 134.858 286.177 143.495L285.949 143.736L300.638 149.583L296.8 153.902H270.165L268.481 142.292C269.106 140.716 269.912 139.213 270.923 137.841C271.164 137.504 271.404 137.155 271.621 136.783C274.917 131.201 273.257 123.826 267.867 120.205C261.96 116.235 253.996 118.051 250.351 124.079C249.978 124.704 249.666 125.342 249.413 125.991V126.136C245.491 125.907 241.641 124.271 238.67 121.167C231.897 114.094 226.459 108.163 221.515 101.859C217.292 96.4692 213.455 90.815 209.448 83.9578C208.775 82.7788 208.077 81.5637 207.379 80.3126C206.934 79.5186 206.597 78.6765 206.369 77.8344C206.128 76.9201 206.008 75.9817 206.008 75.0554C206.008 71.6388 207.632 68.2823 210.615 66.2011L211.806 65.371C221.731 58.4055 233.569 54.6761 245.696 54.6761C252.408 54.6761 259.013 55.831 265.245 58.0446C271.476 60.2582 277.335 63.5304 282.544 67.765C286.057 70.6162 289.137 73.9125 291.735 77.5697C294.322 81.2269 296.427 85.2209 297.955 89.4796C299.904 94.8812 300.89 100.584 300.89 106.298Z" fill="#DD9445"/>
                <path d="M270.728 56.9496C270.808 56.9496 270.872 56.8851 270.872 56.8055C270.872 56.7259 270.808 56.6614 270.728 56.6614C270.649 56.6614 270.584 56.7259 270.584 56.8055C270.584 56.8851 270.649 56.9496 270.728 56.9496Z" fill="#E8C059"/>
                <path d="M277.513 59.3052C277.526 59.3052 277.537 59.2948 277.537 59.282C277.537 59.2692 277.526 59.2588 277.513 59.2588C277.501 59.2588 277.49 59.2692 277.49 59.282C277.49 59.2948 277.501 59.3052 277.513 59.3052Z" fill="#E8C059"/>
                <path d="M247.908 57.1778C248.453 57.1778 248.895 56.7361 248.895 56.1913C248.895 55.6465 248.453 55.2048 247.908 55.2048C247.364 55.2048 246.922 55.6465 246.922 56.1913C246.922 56.7361 247.364 57.1778 247.908 57.1778Z" fill="#E8C059"/>
                <path d="M254.693 60.0887C255.484 60.0887 256.125 59.4477 256.125 58.6571C256.125 57.8664 255.484 57.2255 254.693 57.2255C253.903 57.2255 253.262 57.8664 253.262 58.6571C253.262 59.4477 253.903 60.0887 254.693 60.0887Z" fill="#E8C059"/>
                <path d="M261.477 62.6634C262.328 62.6634 263.017 61.9739 263.017 61.1235C263.017 60.273 262.328 59.5836 261.477 59.5836C260.627 59.5836 259.938 60.273 259.938 61.1235C259.938 61.9739 260.627 62.6634 261.477 62.6634Z" fill="#E8C059"/>
                <path d="M268.25 65.0342C269.048 65.0342 269.694 64.3879 269.694 63.5906C269.694 62.7933 269.048 62.147 268.25 62.147C267.453 62.147 266.807 62.7933 266.807 63.5906C266.807 64.3879 267.453 65.0342 268.25 65.0342Z" fill="#E8C059"/>
                <path d="M275.036 67.2352C275.688 67.2352 276.215 66.7074 276.215 66.0563C276.215 65.4052 275.688 64.8773 275.036 64.8773C274.385 64.8773 273.857 65.4052 273.857 66.0563C273.857 66.7074 274.385 67.2352 275.036 67.2352Z" fill="#E8C059"/>
                <path d="M281.821 69.2566C282.22 69.2566 282.543 68.9334 282.543 68.5348C282.543 68.1362 282.22 67.813 281.821 67.813C281.423 67.813 281.1 68.1362 281.1 68.5348C281.1 68.9334 281.423 69.2566 281.821 69.2566Z" fill="#E8C059"/>
                <path d="M288.606 71.2301C288.733 71.2301 288.835 71.1275 288.835 71.001C288.835 70.8745 288.733 70.772 288.606 70.772C288.48 70.772 288.377 70.8745 288.377 71.001C288.377 71.1275 288.48 71.2301 288.606 71.2301Z" fill="#E8C059"/>
                <path d="M225.088 55.6756C225.148 55.6756 225.197 55.6268 225.197 55.5666C225.197 55.5063 225.148 55.4575 225.088 55.4575C225.027 55.4575 224.979 55.5063 224.979 55.5666C224.979 55.6268 225.027 55.6756 225.088 55.6756Z" fill="#E8C059"/>
                <path d="M231.873 58.7787C232.285 58.7787 232.619 58.4448 232.619 58.0329C232.619 57.6209 232.285 57.287 231.873 57.287C231.461 57.287 231.127 57.6209 231.127 58.0329C231.127 58.4448 231.461 58.7787 231.873 58.7787Z" fill="#E8C059"/>
                <path d="M238.658 61.7136C239.329 61.7136 239.873 61.1696 239.873 60.4985C239.873 59.8274 239.329 59.2834 238.658 59.2834C237.987 59.2834 237.443 59.8274 237.443 60.4985C237.443 61.1696 237.987 61.7136 238.658 61.7136Z" fill="#E8C059"/>
                <path d="M245.444 64.4921C246.288 64.4921 246.972 63.8081 246.972 62.9642C246.972 62.1204 246.288 61.4364 245.444 61.4364C244.6 61.4364 243.916 62.1204 243.916 62.9642C243.916 63.8081 244.6 64.4921 245.444 64.4921Z" fill="#E8C059"/>
                <path d="M252.228 67.2232C253.211 67.2232 254.008 66.426 254.008 65.4427C254.008 64.4594 253.211 63.6622 252.228 63.6622C251.244 63.6622 250.447 64.4594 250.447 65.4427C250.447 66.426 251.244 67.2232 252.228 67.2232Z" fill="#E8C059"/>
                <path d="M259 69.7858C260.036 69.7858 260.876 68.9456 260.876 67.9091C260.876 66.8726 260.036 66.0323 259 66.0323C257.963 66.0323 257.123 66.8726 257.123 67.9091C257.123 68.9456 257.963 69.7858 259 69.7858Z" fill="#E8C059"/>
                <path d="M265.787 72.0952C266.737 72.0952 267.507 71.325 267.507 70.3749C267.507 69.4248 266.737 68.6545 265.787 68.6545C264.837 68.6545 264.066 69.4248 264.066 70.3749C264.066 71.325 264.837 72.0952 265.787 72.0952Z" fill="#E8C059"/>
                <path d="M272.571 74.2848C273.368 74.2848 274.014 73.6385 274.014 72.8412C274.014 72.0439 273.368 71.3976 272.571 71.3976C271.773 71.3976 271.127 72.0439 271.127 72.8412C271.127 73.6385 271.773 74.2848 272.571 74.2848Z" fill="#E8C059"/>
                <path d="M279.356 76.4499C279.987 76.4499 280.499 75.9382 280.499 75.3071C280.499 74.6759 279.987 74.1642 279.356 74.1642C278.725 74.1642 278.213 74.6759 278.213 75.3071C278.213 75.9382 278.725 76.4499 279.356 76.4499Z" fill="#E8C059"/>
                <path d="M286.14 78.5915C286.585 78.5915 286.946 78.2307 286.946 77.7855C286.946 77.3404 286.585 76.9795 286.14 76.9795C285.695 76.9795 285.334 77.3404 285.334 77.7855C285.334 78.2307 285.695 78.5915 286.14 78.5915Z" fill="#E8C059"/>
                <path d="M292.925 80.5889C293.111 80.5889 293.262 80.438 293.262 80.252C293.262 80.066 293.111 79.9152 292.925 79.9152C292.739 79.9152 292.588 80.066 292.588 80.252C292.588 80.438 292.739 80.5889 292.925 80.5889Z" fill="#E8C059"/>
                <path d="M299.697 82.7408C299.71 82.7408 299.72 82.7304 299.72 82.7176C299.72 82.7047 299.71 82.6943 299.697 82.6943C299.684 82.6943 299.674 82.7047 299.674 82.7176C299.674 82.7304 299.684 82.7408 299.697 82.7408Z" fill="#E8C059"/>
                <path d="M215.833 59.9433C215.872 59.9433 215.904 59.9114 215.904 59.872C215.904 59.8327 215.872 59.8008 215.833 59.8008C215.794 59.8008 215.762 59.8327 215.762 59.872C215.762 59.9114 215.794 59.9433 215.833 59.9433Z" fill="#E8C059"/>
                <path d="M222.62 62.9282C222.939 62.9282 223.198 62.6697 223.198 62.3508C223.198 62.0319 222.939 61.7733 222.62 61.7733C222.301 61.7733 222.043 62.0319 222.043 62.3508C222.043 62.6697 222.301 62.9282 222.62 62.9282Z" fill="#E8C059"/>
                <path d="M229.406 65.7796C229.937 65.7796 230.368 65.3487 230.368 64.8172C230.368 64.2856 229.937 63.8547 229.406 63.8547C228.874 63.8547 228.443 64.2856 228.443 64.8172C228.443 65.3487 228.874 65.7796 229.406 65.7796Z" fill="#E8C059"/>
                <path d="M236.192 68.5707C236.902 68.5707 237.479 67.9944 237.479 67.2835C237.479 66.5725 236.902 65.9962 236.192 65.9962C235.481 65.9962 234.904 66.5725 234.904 67.2835C234.904 67.9944 235.481 68.5707 236.192 68.5707Z" fill="#E8C059"/>
                <path d="M242.976 71.3853C243.88 71.3853 244.612 70.6528 244.612 69.7492C244.612 68.8456 243.88 68.113 242.976 68.113C242.072 68.113 241.34 68.8456 241.34 69.7492C241.34 70.6528 242.072 71.3853 242.976 71.3853Z" fill="#E8C059"/>
                <path d="M249.748 74.1645C250.824 74.1645 251.697 73.292 251.697 72.2156C251.697 71.1393 250.824 70.2667 249.748 70.2667C248.671 70.2667 247.799 71.1393 247.799 72.2156C247.799 73.292 248.671 74.1645 249.748 74.1645Z" fill="#E8C059"/>
                <path d="M256.533 76.8113C257.703 76.8113 258.651 75.8633 258.651 74.694C258.651 73.5246 257.703 72.5767 256.533 72.5767C255.364 72.5767 254.416 73.5246 254.416 74.694C254.416 75.8633 255.364 76.8113 256.533 76.8113Z" fill="#E8C059"/>
                <path d="M263.32 79.0244C264.35 79.0244 265.184 78.1896 265.184 77.1597C265.184 76.1299 264.35 75.295 263.32 75.295C262.29 75.295 261.455 76.1299 261.455 77.1597C261.455 78.1896 262.29 79.0244 263.32 79.0244Z" fill="#E8C059"/>
                <path d="M270.104 81.1541C270.948 81.1541 271.632 80.47 271.632 79.6262C271.632 78.7824 270.948 78.0984 270.104 78.0984C269.26 78.0984 268.576 78.7824 268.576 79.6262C268.576 80.47 269.26 81.1541 270.104 81.1541Z" fill="#E8C059"/>
                <path d="M276.891 83.2964C277.555 83.2964 278.094 82.7578 278.094 82.0934C278.094 81.429 277.555 80.8904 276.891 80.8904C276.226 80.8904 275.688 81.429 275.688 82.0934C275.688 82.7578 276.226 83.2964 276.891 83.2964Z" fill="#E8C059"/>
                <path d="M283.675 85.4254C284.153 85.4254 284.541 85.0375 284.541 84.5592C284.541 84.0808 284.153 83.693 283.675 83.693C283.196 83.693 282.809 84.0808 282.809 84.5592C282.809 85.0375 283.196 85.4254 283.675 85.4254Z" fill="#E8C059"/>
                <path d="M290.447 87.567C290.74 87.567 290.977 87.33 290.977 87.0376C290.977 86.7453 290.74 86.5083 290.447 86.5083C290.155 86.5083 289.918 86.7453 289.918 87.0376C289.918 87.33 290.155 87.567 290.447 87.567Z" fill="#E8C059"/>
                <path d="M297.232 89.6591C297.319 89.6591 297.388 89.5893 297.388 89.503C297.388 89.4168 297.319 89.3469 297.232 89.3469C297.146 89.3469 297.076 89.4168 297.076 89.503C297.076 89.5893 297.146 89.6591 297.232 89.6591Z" fill="#E8C059"/>
                <path d="M213.37 66.8986C213.502 66.8986 213.61 66.7909 213.61 66.658C213.61 66.5251 213.502 66.4174 213.37 66.4174C213.237 66.4174 213.129 66.5251 213.129 66.658C213.129 66.7909 213.237 66.8986 213.37 66.8986Z" fill="#E8C059"/>
                <path d="M220.156 69.7139C220.481 69.7139 220.745 69.45 220.745 69.1244C220.745 68.7988 220.481 68.5349 220.156 68.5349C219.83 68.5349 219.566 68.7988 219.566 69.1244C219.566 69.45 219.83 69.7139 220.156 69.7139Z" fill="#E8C059"/>
                <path d="M226.94 72.5292C227.452 72.5292 227.866 72.1144 227.866 71.6028C227.866 71.0913 227.452 70.6765 226.94 70.6765C226.428 70.6765 226.014 71.0913 226.014 71.6028C226.014 72.1144 226.428 72.5292 226.94 72.5292Z" fill="#E8C059"/>
                <path d="M233.725 75.3076C234.41 75.3076 234.965 74.7528 234.965 74.0685C234.965 73.3841 234.41 72.8293 233.725 72.8293C233.041 72.8293 232.486 73.3841 232.486 74.0685C232.486 74.7528 233.041 75.3076 233.725 75.3076Z" fill="#E8C059"/>
                <path d="M240.498 78.0981C241.361 78.0981 242.061 77.3979 242.061 76.5341C242.061 75.6704 241.361 74.9702 240.498 74.9702C239.634 74.9702 238.934 75.6704 238.934 76.5341C238.934 77.3979 239.634 78.0981 240.498 78.0981Z" fill="#E8C059"/>
                <path d="M247.284 80.8299C248.294 80.8299 249.112 80.0112 249.112 79.0013C249.112 77.9914 248.294 77.1727 247.284 77.1727C246.274 77.1727 245.455 77.9914 245.455 79.0013C245.455 80.0112 246.274 80.8299 247.284 80.8299Z" fill="#E8C059"/>
                <path d="M254.068 83.4045C255.137 83.4045 256.005 82.5373 256.005 81.4676C256.005 80.3979 255.137 79.5308 254.068 79.5308C252.998 79.5308 252.131 80.3979 252.131 81.4676C252.131 82.5373 252.998 83.4045 254.068 83.4045Z" fill="#E8C059"/>
                <path d="M260.852 85.7012C261.822 85.7012 262.609 84.9148 262.609 83.9448C262.609 82.9747 261.822 82.1884 260.852 82.1884C259.882 82.1884 259.096 82.9747 259.096 83.9448C259.096 84.9148 259.882 85.7012 260.852 85.7012Z" fill="#E8C059"/>
                <path d="M267.638 87.8789C268.448 87.8789 269.105 87.2218 269.105 86.4112C269.105 85.6006 268.448 84.9435 267.638 84.9435C266.827 84.9435 266.17 85.6006 266.17 86.4112C266.17 87.2218 266.827 87.8789 267.638 87.8789Z" fill="#E8C059"/>
                <path d="M274.411 90.0332C275.049 90.0332 275.566 89.5161 275.566 88.8783C275.566 88.2405 275.049 87.7234 274.411 87.7234C273.773 87.7234 273.256 88.2405 273.256 88.8783C273.256 89.5161 273.773 90.0332 274.411 90.0332Z" fill="#E8C059"/>
                <path d="M281.195 92.1742C281.654 92.1742 282.025 91.8026 282.025 91.3441C282.025 90.8857 281.654 90.514 281.195 90.514C280.737 90.514 280.365 90.8857 280.365 91.3441C280.365 91.8026 280.737 92.1742 281.195 92.1742Z" fill="#E8C059"/>
                <path d="M287.982 94.3278C288.261 94.3278 288.487 94.1016 288.487 93.8225C288.487 93.5435 288.261 93.3173 287.982 93.3173C287.703 93.3173 287.477 93.5435 287.477 93.8225C287.477 94.1016 287.703 94.3278 287.982 94.3278Z" fill="#E8C059"/>
                <path d="M294.766 96.4568C294.859 96.4568 294.934 96.3816 294.934 96.2887C294.934 96.1959 294.859 96.1206 294.766 96.1206C294.673 96.1206 294.598 96.1959 294.598 96.2887C294.598 96.3816 294.673 96.4568 294.766 96.4568Z" fill="#E8C059"/>
                <path d="M210.903 73.6227C211.002 73.6227 211.083 73.5421 211.083 73.4426C211.083 73.3431 211.002 73.2625 210.903 73.2625C210.803 73.2625 210.723 73.3431 210.723 73.4426C210.723 73.5421 210.803 73.6227 210.903 73.6227Z" fill="#E8C059"/>
                <path d="M217.689 76.4139C217.968 76.4139 218.194 76.1876 218.194 75.9086C218.194 75.6295 217.968 75.4033 217.689 75.4033C217.41 75.4033 217.184 75.6295 217.184 75.9086C217.184 76.1876 217.41 76.4139 217.689 76.4139Z" fill="#E8C059"/>
                <path d="M224.474 79.181C224.919 79.181 225.28 78.8201 225.28 78.375C225.28 77.9298 224.919 77.569 224.474 77.569C224.029 77.569 223.668 77.9298 223.668 78.375C223.668 78.8201 224.029 79.181 224.474 79.181Z" fill="#E8C059"/>
                <path d="M231.247 81.961C231.859 81.961 232.354 81.4655 232.354 80.8542C232.354 80.243 231.859 79.7474 231.247 79.7474C230.636 79.7474 230.141 80.243 230.141 80.8542C230.141 81.4655 230.636 81.961 231.247 81.961Z" fill="#E8C059"/>
                <path d="M238.031 84.6793C238.782 84.6793 239.391 84.0706 239.391 83.3199C239.391 82.5691 238.782 81.9604 238.031 81.9604C237.28 81.9604 236.672 82.5691 236.672 83.3199C236.672 84.0706 237.28 84.6793 238.031 84.6793Z" fill="#E8C059"/>
                <path d="M244.816 87.3374C245.673 87.3374 246.367 86.6426 246.367 85.7855C246.367 84.9285 245.673 84.2336 244.816 84.2336C243.958 84.2336 243.264 84.9285 243.264 85.7855C243.264 86.6426 243.958 87.3374 244.816 87.3374Z" fill="#E8C059"/>
                <path d="M251.6 89.864C252.491 89.864 253.212 89.1423 253.212 88.2519C253.212 87.3616 252.491 86.6399 251.6 86.6399C250.71 86.6399 249.988 87.3616 249.988 88.2519C249.988 89.1423 250.71 89.864 251.6 89.864Z" fill="#E8C059"/>
                <path d="M258.387 92.2349C259.224 92.2349 259.903 91.5562 259.903 90.7191C259.903 89.8819 259.224 89.2032 258.387 89.2032C257.55 89.2032 256.871 89.8819 256.871 90.7191C256.871 91.5562 257.55 92.2349 258.387 92.2349Z" fill="#E8C059"/>
                <path d="M265.16 94.4834C265.871 94.4834 266.448 93.9071 266.448 93.1962C266.448 92.4853 265.871 91.9089 265.16 91.9089C264.449 91.9089 263.873 92.4853 263.873 93.1962C263.873 93.9071 264.449 94.4834 265.16 94.4834Z" fill="#E8C059"/>
                <path d="M271.944 96.6852C272.509 96.6852 272.967 96.2273 272.967 95.6626C272.967 95.0978 272.509 94.64 271.944 94.64C271.38 94.64 270.922 95.0978 270.922 95.6626C270.922 96.2273 271.38 96.6852 271.944 96.6852Z" fill="#E8C059"/>
                <path d="M278.73 98.8509C279.128 98.8509 279.451 98.5277 279.451 98.129C279.451 97.7304 279.128 97.4072 278.73 97.4072C278.331 97.4072 278.008 97.7304 278.008 98.129C278.008 98.5277 278.331 98.8509 278.73 98.8509Z" fill="#E8C059"/>
                <path d="M285.516 101.004C285.742 101.004 285.925 100.821 285.925 100.595C285.925 100.369 285.742 100.186 285.516 100.186C285.291 100.186 285.107 100.369 285.107 100.595C285.107 100.821 285.291 101.004 285.516 101.004Z" fill="#E8C059"/>
                <path d="M292.3 103.183C292.361 103.183 292.409 103.134 292.409 103.074C292.409 103.014 292.361 102.965 292.3 102.965C292.24 102.965 292.191 103.014 292.191 103.074C292.191 103.134 292.24 103.183 292.3 103.183Z" fill="#E8C059"/>
                <path d="M208.436 80.3126C208.483 80.3126 208.521 80.2746 208.521 80.2278C208.521 80.1809 208.483 80.1429 208.436 80.1429C208.39 80.1429 208.352 80.1809 208.352 80.2278C208.352 80.2746 208.39 80.3126 208.436 80.3126Z" fill="#E8C059"/>
                <path d="M215.222 83.0432C215.415 83.0432 215.571 82.887 215.571 82.6943C215.571 82.5016 215.415 82.3455 215.222 82.3455C215.029 82.3455 214.873 82.5016 214.873 82.6943C214.873 82.887 215.029 83.0432 215.222 83.0432Z" fill="#E8C059"/>
                <path d="M221.995 85.7863C222.34 85.7863 222.62 85.5062 222.62 85.1607C222.62 84.8152 222.34 84.5352 221.995 84.5352C221.649 84.5352 221.369 84.8152 221.369 85.1607C221.369 85.5062 221.649 85.7863 221.995 85.7863Z" fill="#E8C059"/>
                <path d="M228.779 88.5046C229.264 88.5046 229.657 88.1114 229.657 87.6264C229.657 87.1414 229.264 86.7482 228.779 86.7482C228.294 86.7482 227.9 87.1414 227.9 87.6264C227.9 88.1114 228.294 88.5046 228.779 88.5046Z" fill="#E8C059"/>
                <path d="M235.564 91.2116C236.175 91.2116 236.671 90.7161 236.671 90.1048C236.671 89.4936 236.175 88.998 235.564 88.998C234.953 88.998 234.457 89.4936 234.457 90.1048C234.457 90.7161 234.953 91.2116 235.564 91.2116Z" fill="#E8C059"/>
                <path d="M242.348 93.8104C243.033 93.8104 243.588 93.2556 243.588 92.5713C243.588 91.8869 243.033 91.3322 242.348 91.3322C241.664 91.3322 241.109 91.8869 241.109 92.5713C241.109 93.2556 241.664 93.8104 242.348 93.8104Z" fill="#E8C059"/>
                <path d="M249.135 96.3121C249.839 96.3121 250.41 95.7412 250.41 95.0369C250.41 94.3326 249.839 93.7617 249.135 93.7617C248.43 93.7617 247.859 94.3326 247.859 95.0369C247.859 95.7412 248.43 96.3121 249.135 96.3121Z" fill="#E8C059"/>
                <path d="M255.908 98.7184C256.579 98.7184 257.123 98.1744 257.123 97.5034C257.123 96.8323 256.579 96.2883 255.908 96.2883C255.237 96.2883 254.693 96.8323 254.693 97.5034C254.693 98.1744 255.237 98.7184 255.908 98.7184Z" fill="#E8C059"/>
                <path d="M262.693 101.016C263.271 101.016 263.74 100.548 263.74 99.9698C263.74 99.3918 263.271 98.9232 262.693 98.9232C262.115 98.9232 261.646 99.3918 261.646 99.9698C261.646 100.548 262.115 101.016 262.693 101.016Z" fill="#E8C059"/>
                <path d="M269.476 103.266C269.928 103.266 270.294 102.899 270.294 102.448C270.294 101.996 269.928 101.63 269.476 101.63C269.024 101.63 268.658 101.996 268.658 102.448C268.658 102.899 269.024 103.266 269.476 103.266Z" fill="#E8C059"/>
                <path d="M276.263 105.479C276.575 105.479 276.828 105.226 276.828 104.914C276.828 104.602 276.575 104.349 276.263 104.349C275.95 104.349 275.697 104.602 275.697 104.914C275.697 105.226 275.95 105.479 276.263 105.479Z" fill="#E8C059"/>
                <path d="M283.048 107.644C283.194 107.644 283.313 107.526 283.313 107.38C283.313 107.234 283.194 107.115 283.048 107.115C282.902 107.115 282.783 107.234 282.783 107.38C282.783 107.526 282.902 107.644 283.048 107.644Z" fill="#E8C059"/>
                <path d="M289.834 109.869C289.847 109.869 289.857 109.858 289.857 109.845C289.857 109.833 289.847 109.822 289.834 109.822C289.821 109.822 289.811 109.833 289.811 109.845C289.811 109.858 289.821 109.869 289.834 109.869Z" fill="#E8C059"/>
                <path d="M212.745 89.6111C212.818 89.6111 212.877 89.552 212.877 89.479C212.877 89.4061 212.818 89.3469 212.745 89.3469C212.672 89.3469 212.613 89.4061 212.613 89.479C212.613 89.552 212.672 89.6111 212.745 89.6111Z" fill="#E8C059"/>
                <path d="M219.528 92.3547C219.754 92.3547 219.937 92.1715 219.937 91.9456C219.937 91.7197 219.754 91.5366 219.528 91.5366C219.302 91.5366 219.119 91.7197 219.119 91.9456C219.119 92.1715 219.302 92.3547 219.528 92.3547Z" fill="#E8C059"/>
                <path d="M226.315 95.0369C226.661 95.0369 226.941 94.7568 226.941 94.4113C226.941 94.0659 226.661 93.7858 226.315 93.7858C225.97 93.7858 225.689 94.0659 225.689 94.4113C225.689 94.7568 225.97 95.0369 226.315 95.0369Z" fill="#E8C059"/>
                <path d="M233.099 97.6838C233.544 97.6838 233.905 97.323 233.905 96.8778C233.905 96.4326 233.544 96.0718 233.099 96.0718C232.654 96.0718 232.293 96.4326 232.293 96.8778C232.293 97.323 232.654 97.6838 233.099 97.6838Z" fill="#E8C059"/>
                <path d="M239.885 100.27C240.39 100.27 240.799 99.8611 240.799 99.3562C240.799 98.8512 240.39 98.4419 239.885 98.4419C239.38 98.4419 238.971 98.8512 238.971 99.3562C238.971 99.8611 239.38 100.27 239.885 100.27Z" fill="#E8C059"/>
                <path d="M246.657 102.76C247.175 102.76 247.595 102.34 247.595 101.822C247.595 101.304 247.175 100.884 246.657 100.884C246.139 100.884 245.719 101.304 245.719 101.822C245.719 102.34 246.139 102.76 246.657 102.76Z" fill="#E8C059"/>
                <path d="M253.443 105.179C253.935 105.179 254.333 104.78 254.333 104.288C254.333 103.797 253.935 103.398 253.443 103.398C252.951 103.398 252.553 103.797 252.553 104.288C252.553 104.78 252.951 105.179 253.443 105.179Z" fill="#E8C059"/>
                <path d="M260.229 107.513C260.647 107.513 260.987 107.173 260.987 106.755C260.987 106.336 260.647 105.997 260.229 105.997C259.81 105.997 259.471 106.336 259.471 106.755C259.471 107.173 259.81 107.513 260.229 107.513Z" fill="#E8C059"/>
                <path d="M267.013 109.798C267.325 109.798 267.578 109.545 267.578 109.233C267.578 108.92 267.325 108.667 267.013 108.667C266.7 108.667 266.447 108.92 266.447 109.233C266.447 109.545 266.7 109.798 267.013 109.798Z" fill="#E8C059"/>
                <path d="M273.796 112.049C273.989 112.049 274.145 111.892 274.145 111.7C274.145 111.507 273.989 111.351 273.796 111.351C273.603 111.351 273.447 111.507 273.447 111.7C273.447 111.892 273.603 112.049 273.796 112.049Z" fill="#E8C059"/>
                <path d="M280.572 114.262C280.625 114.262 280.668 114.219 280.668 114.165C280.668 114.112 280.625 114.068 280.572 114.068C280.518 114.068 280.475 114.112 280.475 114.165C280.475 114.219 280.518 114.262 280.572 114.262Z" fill="#E8C059"/>
                <path d="M217.063 98.8161C217.11 98.8161 217.148 98.7781 217.148 98.7313C217.148 98.6845 217.11 98.6465 217.063 98.6465C217.016 98.6465 216.979 98.6845 216.979 98.7313C216.979 98.7781 217.016 98.8161 217.063 98.8161Z" fill="#E8C059"/>
                <path d="M223.846 101.522C224.026 101.522 224.171 101.377 224.171 101.197C224.171 101.018 224.026 100.872 223.846 100.872C223.667 100.872 223.521 101.018 223.521 101.197C223.521 101.377 223.667 101.522 223.846 101.522Z" fill="#E8C059"/>
                <path d="M230.634 104.157C230.906 104.157 231.127 103.936 231.127 103.664C231.127 103.391 230.906 103.17 230.634 103.17C230.361 103.17 230.141 103.391 230.141 103.664C230.141 103.936 230.361 104.157 230.634 104.157Z" fill="#E8C059"/>
                <path d="M237.406 106.707C237.724 106.707 237.983 106.448 237.983 106.129C237.983 105.81 237.724 105.552 237.406 105.552C237.087 105.552 236.828 105.81 236.828 106.129C236.828 106.448 237.087 106.707 237.406 106.707Z" fill="#E8C059"/>
                <path d="M244.192 109.221C244.531 109.221 244.805 108.947 244.805 108.608C244.805 108.269 244.531 107.994 244.192 107.994C243.853 107.994 243.578 108.269 243.578 108.608C243.578 108.947 243.853 109.221 244.192 109.221Z" fill="#E8C059"/>
                <path d="M250.976 111.638C251.288 111.638 251.541 111.385 251.541 111.073C251.541 110.76 251.288 110.507 250.976 110.507C250.663 110.507 250.41 110.76 250.41 111.073C250.41 111.385 250.663 111.638 250.976 111.638Z" fill="#E8C059"/>
                <path d="M257.76 113.997C258.012 113.997 258.217 113.792 258.217 113.54C258.217 113.287 258.012 113.083 257.76 113.083C257.507 113.083 257.303 113.287 257.303 113.54C257.303 113.792 257.507 113.997 257.76 113.997Z" fill="#E8C059"/>
                <path d="M264.547 116.294C264.706 116.294 264.835 116.165 264.835 116.006C264.835 115.846 264.706 115.717 264.547 115.717C264.387 115.717 264.258 115.846 264.258 116.006C264.258 116.165 264.387 116.294 264.547 116.294Z" fill="#E8C059"/>
                <path d="M271.318 118.595C271.378 118.595 271.427 118.546 271.427 118.486C271.427 118.425 271.378 118.376 271.318 118.376C271.258 118.376 271.209 118.425 271.209 118.486C271.209 118.546 271.258 118.595 271.318 118.595Z" fill="#E8C059"/>
                <path d="M228.156 110.58C228.228 110.58 228.288 110.521 228.288 110.448C228.288 110.375 228.228 110.316 228.156 110.316C228.083 110.316 228.023 110.375 228.023 110.448C228.023 110.521 228.083 110.58 228.156 110.58Z" fill="#E8C059"/>
                <path d="M234.94 113.167C235.08 113.167 235.193 113.054 235.193 112.914C235.193 112.775 235.08 112.662 234.94 112.662C234.801 112.662 234.688 112.775 234.688 112.914C234.688 113.054 234.801 113.167 234.94 113.167Z" fill="#E8C059"/>
                <path d="M241.724 115.657C241.87 115.657 241.988 115.539 241.988 115.393C241.988 115.246 241.87 115.128 241.724 115.128C241.577 115.128 241.459 115.246 241.459 115.393C241.459 115.539 241.577 115.657 241.724 115.657Z" fill="#E8C059"/>
                <path d="M248.51 118.099C248.643 118.099 248.751 117.991 248.751 117.858C248.751 117.726 248.643 117.618 248.51 117.618C248.377 117.618 248.27 117.726 248.27 117.858C248.27 117.991 248.377 118.099 248.51 118.099Z" fill="#E8C059"/>
                <path d="M255.297 120.435C255.357 120.435 255.406 120.386 255.406 120.326C255.406 120.265 255.357 120.217 255.297 120.217C255.236 120.217 255.188 120.265 255.188 120.326C255.188 120.386 255.236 120.435 255.297 120.435Z" fill="#E8C059"/>
                <path d="M239.258 122.187C239.27 122.187 239.281 122.177 239.281 122.164C239.281 122.151 239.27 122.141 239.258 122.141C239.245 122.141 239.234 122.151 239.234 122.164C239.234 122.177 239.245 122.187 239.258 122.187Z" fill="#E8C059"/>
            </g>
            <path d="M249.401 126.136V125.992C249.666 125.342 249.979 124.705 250.352 124.079C253.997 118.052 261.961 116.236 267.868 120.205C273.257 123.827 274.917 131.201 271.621 136.783C271.405 137.156 271.164 137.505 270.923 137.842C269.913 139.213 269.107 140.717 268.481 142.293C267.844 143.869 267.387 145.517 267.05 147.189C266.821 148.344 266.388 149.475 265.738 150.534C262.911 155.238 256.968 156.91 252.144 154.54L243.711 169.638C240.535 175.328 235.494 179.611 229.587 181.897C226.568 183.064 223.32 183.713 219.975 183.749H219.663C203.626 183.749 191.163 170.167 192.161 154.528C192.222 153.565 192.33 152.591 192.498 151.604L194.579 139.31L187.337 134.546C184.137 132.428 183.8 127.857 186.664 125.306L191.211 121.252C193.81 118.942 195.59 115.851 196.3 112.446C196.444 111.748 196.601 111.05 196.769 110.353C197.743 106.262 199.079 102.292 200.775 98.4789C202.76 94.0036 205.214 89.7449 208.113 85.7629L209.401 83.9824L209.449 83.9584C213.455 90.8156 217.293 96.4698 221.515 101.859C226.46 108.163 231.897 114.094 238.67 121.168C241.642 124.272 245.491 125.908 249.401 126.136ZM206.189 125.342C216.655 125.535 224.426 125.535 224.426 125.535C224.426 125.535 218.736 116.741 211.133 115.177C208.498 116.5 206.538 119.063 206.092 122.215C205.948 123.285 205.984 124.332 206.189 125.342Z" fill="#FFCAA6"/>
            <mask id="mask3_1290_5134" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="90" y="406" width="141" height="233">
                <path d="M198.417 597.336C195.446 594.569 192.246 592.054 188.973 589.624C169.761 575.393 157.31 552.559 157.31 526.814C157.31 503.031 167.932 481.725 184.715 467.397V406.476C184.715 420.672 174.477 449.063 135.415 476.384C108.467 495.235 90.7344 526.405 90.7344 561.786C90.7344 583.32 97.2788 603.315 108.491 619.904L108.623 620.109C113.087 625.944 116.936 632.259 120.076 638.984H230.117C219.963 620.771 208.907 607.152 198.417 597.336Z" fill="white"/>
            </mask>
            <g mask="url(#mask3_1290_5134)">
                <path d="M230.117 638.985H120.076C116.936 632.26 113.087 625.944 108.623 620.109L108.491 619.905C97.2788 603.315 90.7344 583.321 90.7344 561.787C90.7344 526.406 108.467 495.235 135.415 476.384C174.477 449.063 184.715 420.672 184.715 406.476V467.397C167.932 481.725 157.31 503.031 157.31 526.815C157.31 552.559 169.761 575.393 188.973 589.625C192.246 592.055 195.446 594.569 198.417 597.336C208.907 607.153 219.963 620.771 230.117 638.985Z" fill="#DB4B55"/>
            </g>
            <path d="M229.587 181.896C229.587 183.34 229.178 184.723 228.457 185.902C227.723 187.081 226.676 188.055 225.377 188.705C224.066 189.367 222.826 190.149 221.696 191.039L192.594 195.875L171.145 154.527H192.161C191.163 170.166 203.626 183.749 219.662 183.749H219.975C223.32 183.712 226.568 183.063 229.587 181.896Z" fill="#ADA1A1"/>
            <path d="M217.016 297.856L225.004 406.477H184.715C183.728 368.437 182.104 358.717 179.818 346.037C176.606 328.28 168.534 304.232 168.534 304.232L164.227 277.055L161.556 260.177C158.717 242.252 160.714 223.894 167.331 207.003L170.29 199.436V199.4L192.594 195.875L212.336 233.915C212.336 235.948 212.48 237.945 212.745 239.906L217.016 297.856Z" fill="#F7F0F5"/>
            <path d="M192.596 195.876L221.697 191.039C216.404 195.166 213.204 201.566 213.204 208.435V225.29C212.638 228.069 212.337 230.956 212.337 233.915L192.596 195.876Z" fill="#ADA1A1"/>
            <path d="M209.447 83.9579L209.399 83.9819L208.111 85.7624C205.212 89.7444 202.758 94.0031 200.773 98.4784C199.077 102.292 197.741 106.262 196.767 110.352L196.646 110.328V88.2406C196.646 82.7187 200.941 78.2074 206.367 77.8345C206.595 78.6766 206.932 79.5187 207.377 80.3127C208.075 81.5638 208.773 82.7789 209.447 83.9579Z" fill="#DD9445"/>
            <path d="M499.293 579.268C499.293 561.727 490.848 546.148 477.795 536.392C468.868 529.727 457.801 525.781 445.807 525.781C389.084 525.781 343.104 479.802 343.104 423.079C343.104 406.778 346.894 391.367 353.655 377.689C357.083 370.759 359.008 362.964 359.008 354.711C359.008 326.127 335.838 302.945 307.242 302.945" stroke="#E8C059" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M371.592 328.593C384.933 380.503 425.848 421.334 477.795 434.579C489.476 437.551 501.711 439.139 514.319 439.139" stroke="#E8C059" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M225.004 422.85C225.004 431.813 226.243 440.486 228.565 448.703L246.526 514.424" stroke="#3259E6" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M212.744 239.906L213.273 247.101" stroke="#E8C059" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M214.236 260.201L217.015 297.856L221.466 358.368" stroke="#E8C059" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M221.947 364.925L222.477 372.119" stroke="#E8C059" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M167.824 205.728L167.331 207.003C160.714 223.894 158.717 242.252 161.556 260.177L163.06 269.705" stroke="#E8C059" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M206.549 107.177C206.777 104.674 208.979 102.834 211.469 103.05C212.48 103.147 213.274 103.568 214.08 104.157L226.086 113.18" stroke="#DD9445" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M199.318 148.788H213.033" stroke="#D06A70" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M294.226 198.546L261.877 192.062" stroke="#E8C059" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M289.653 380.419C265.677 380.419 246.236 360.978 246.236 337.002" stroke="#E8C059" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M252.143 63.796C275.902 67.0322 292.54 88.9272 289.304 112.687" stroke="#E8C059" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M181.838 469.491C169.423 481.906 157.309 503.032 157.309 526.815C157.309 552.56 169.76 575.393 188.972 589.625C192.244 592.055 195.444 594.57 198.416 597.337C208.906 607.153 219.962 620.772 230.116 638.985C241.171 658.835 251.156 684.123 258.182 715.883C259.433 721.549 260.925 727.155 262.669 732.689" stroke="#E8C059" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M209.834 125.403C218.412 125.535 224.427 125.535 224.427 125.535C224.427 125.535 218.989 117.15 211.639 115.309C212.517 116.488 213.046 117.932 213.046 119.508C213.046 121.974 211.771 124.139 209.846 125.403H209.834Z" fill="#F7F0F5"/>
            <path d="M206.091 122.214C205.947 123.285 205.983 124.331 206.188 125.342C207.451 125.366 208.666 125.39 209.833 125.402C211.758 124.151 213.033 121.973 213.033 119.507C213.033 117.931 212.503 116.475 211.625 115.308C211.457 115.272 211.288 115.212 211.12 115.176C208.485 116.499 206.524 119.062 206.079 122.214H206.091Z" fill="#3259E6"/>
            <path d="M254.225 148.632C256.45 149.426 259.193 147.381 260.372 144.073C261.551 140.765 260.697 137.444 258.483 136.662C260.709 137.456 263.452 135.411 264.631 132.103C265.81 128.795 264.956 125.474 262.742 124.692" stroke="#DD9445" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M271.825 254.535C272.113 256.556 270.814 258.469 268.829 258.951L268.312 259.071L268.998 264.424C269.274 266.566 267.734 268.527 265.593 268.755L265.894 270.572C266.652 274.867 265.533 276.791 261.298 277.381C261.948 281.784 260.167 285.597 255.728 285.79L222.477 284.779V251.396L233.737 246.932C241.075 243.949 248.402 242.253 256.318 241.844C256.318 241.844 255.476 246.235 255.476 252.526C259.181 252.141 267.422 251.119 267.422 251.119C269.563 250.866 271.512 252.37 271.825 254.499V254.535Z" fill="#FFCAA6"/>
            <path d="M107.768 257.939L222.476 251.419V288.472L109.933 303.378L107.768 257.939Z" fill="#F7F0F5"/>
            <path d="M267.771 259.143L261.299 259.781" stroke="#E5B798" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M265.088 268.911L261.07 269.308" stroke="#E5B798" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M100.287 268.911C109.743 261.765 121.111 257.578 132.949 256.904L195.194 253.367" stroke="#E8C059" stroke-miterlimit="10" stroke-linecap="round"/>
            <mask id="mask4_1290_5134" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="255" y="153" width="64" height="47">
                <path d="M318.756 153.901L300.638 199.833L255.477 190.774L270.165 153.901L318.756 153.901Z" fill="white"/>
            </mask>
            <g mask="url(#mask4_1290_5134)">
                <path d="M278.691 162.32L270.898 154.528L263.106 162.32L270.898 170.112L278.691 162.32Z" stroke="#E8C059" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M294.23 162.327L286.438 154.535L278.645 162.327L286.438 170.119L294.23 162.327Z" stroke="#E8C059" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M309.759 162.314L301.967 154.522L294.175 162.314L301.967 170.106L309.759 162.314Z" stroke="#E8C059" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M325.308 162.321L317.516 154.529L309.724 162.321L317.516 170.113L325.308 162.321Z" stroke="#E8C059" stroke-miterlimit="10" stroke-linecap="round"/>
            </g>
            <mask id="mask5_1290_5134" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="107" y="251" width="116" height="53">
                <path d="M107.768 257.939L222.476 251.419V288.472L109.933 303.378L107.768 257.939Z" fill="white"/>
            </mask>
            <g mask="url(#mask5_1290_5134)">
                <path d="M221.7 251.98L213.908 244.188L206.116 251.98L213.908 259.772L221.7 251.98Z" stroke="#E8C059" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M221.691 267.568L213.898 259.776L206.106 267.568L213.898 275.36L221.691 267.568Z" stroke="#E8C059" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M221.692 283.144L213.9 275.352L206.108 283.144L213.9 290.936L221.692 283.144Z" stroke="#E8C059" stroke-miterlimit="10" stroke-linecap="round"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_1290_5134">
                <rect y="238" width="335.572" height="335.572" rx="20" transform="rotate(-45 0 238)" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<style scoped>

</style>
