<script setup>
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog
/**
 * HTML Dialog wrapper component
 */
import { ref } from "vue";

const dialog = ref(null);
function openDialog() {
    dialog.value.showModal();
}
function closeDialog() {
    dialog.value.close();
}

function closeOnOuterClick(e) {
    var rect = dialog.value.getBoundingClientRect();
    var isInDialog =
        rect.top <= e.clientY &&
        e.clientY <= rect.top + rect.height &&
        rect.left <= e.clientX &&
        e.clientX <= rect.left + rect.width;
    // console.debug(isInDialog);
    if (!isInDialog) {
        closeDialog();
    }
}

defineExpose({
    openDialog,
    closeDialog,
});
</script>

<template>
    <dialog ref="dialog" @click="closeOnOuterClick">
        <slot></slot>
        <!-- <form method="dialog">
            <button>OK</button>
        </form> -->
    </dialog>
</template>

<style scoped>
dialog {
    @apply bg-transparent absolute w-96 max-w-full max-h-full;
}
dialog::backdrop {
    background-color: #3259E6;
    opacity: 0.5;
}
</style>
