<script setup>
import AppAudio from "@/components/utilities/AppAudio.vue";
import {computed, ref} from "vue";
import ContentTextbox from "@/components/content/ContentTextbox.vue";

const emit = defineEmits(['play'])

const props = defineProps({
    src: { type: String, required: true },
});

const audio = ref();

const currentTime = computed({
    get() {
        return audio.value?.currentTime
    },
    set(newValue){
        audio.value.setCurrentTime(newValue)
    },
})

const duration = computed(() => audio.value?.duration)

const elapsedTime = computed(() => {
    const minutes = Math.floor(currentTime.value / 60);
    const seconds = Math.floor(currentTime.value - (minutes * 60));
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
})

const playPauseIcon = computed(() => {
    return audio.value?.playing ? `/assets/aria-player/player-pause-icon.png` : `/assets/aria-player/player-play-icon.png`
})

</script>

<template>
    <div class="px-6 py-4 max-w-screen-sm mx-auto">
        <div class="content-audioplayer">
            <slot name="player"></slot>
            <AppAudio @play="emit('play')" class="mt-6" ref="audio" :src="src"/>
            <div class="grid grid-cols-5 gap-3">
                <div class="col-span-1">
                    <button class="player-play-button" @click="audio.playPause">
                        <img :src="playPauseIcon" alt="player play/pause button image">
                    </button>
                </div>
                <div class="col-span-3 flex flex-col content-center ">
                    <input class="px-0 mb-0" type="range" :min="0" :max="duration" v-model="currentTime">
                </div>
                <div class="col-span-1">
                    <div class="pt-2.5">
                        {{elapsedTime}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <slot name="description"></slot>
</template>

<style scoped>
.content-audioplayer {
    @apply px-6 pb-8 pt-3 bg-warm-light rounded-3xl border-dashed border-warm border-2;
}

</style>
