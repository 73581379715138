<script setup>
import LayoutSecondaryPage from "@/components/home/LayoutSecondaryPage.vue";
import SecondaryPageHeader from "@/components/utilities/SecondaryPageHeader.vue";
import IconAchievements from "@/components/icons/IconAchievements.vue";
import AchievementsList from "@/components/achievements/AchievementsList.vue";
import {useUserNotificationsStore} from "@/stores/userNotificationStore.js";

const notificationStore = useUserNotificationsStore()
notificationStore.clearAchievementsNotifications()
</script>

<template>
    <LayoutSecondaryPage>
        <template #head>
            <SecondaryPageHeader>
                <template #icon>
                    <IconAchievements class="w-full h-full"/>
                </template>
                <template #text>Achievements</template>
            </SecondaryPageHeader>
        </template>
        <!-- page content -->
        <template #main>
            <div class="container max-w-screen-lg">
                <AchievementsList/>
            </div>
        </template>
    </LayoutSecondaryPage>
</template>
