<script setup>
import Title from "@/components/content/ContentTitle.vue";
import Textbox from "@/components/content/ContentTextbox.vue";
import GlossaryWord from "@/components/glossary/GlossaryWordTooltip.vue";
import FunFact from "@/components/content/ContentFunFact.vue";
import NextChapterCard from "@/components/content/ContentNextChapterCard.vue";
import {defineModel, defineProps, watch} from "vue";
import ContentImageBox from "@/components/content/ContentImageBox.vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {useUserNotificationsStore} from "@/stores/userNotificationStore.js";
import {extractChapterRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import ContentFunfactParagraph from "@/components/content/ContentFunfactParagraph.vue";

const props = defineProps({
    chapterIndex : {type : Number, required : true}
})

const readChapters = defineModel()

const UserProgression = useUserProgressionStore()
const UserNotifications = useUserNotificationsStore()

const routeInfo = extractChapterRouteInfo()

const operaIndex = routeInfo.operaInfo.index
const levelIndex = routeInfo.levelInfo.index;

watch(UserProgression.glossaryWords, () => {
    if(!UserProgression.isAchievementUnlocked(4) &&
        UserProgression.allLevelGlossaryWordFound(operaIndex, levelIndex)){
        UserProgression.unlockAchievementById(4);
        UserNotifications.pushNotification("achievement", 4);
    }
})
</script>

<template>
    <Title>Occhio alle fake news sul <i class="italic">Barbiere!</i></Title>
    <Textbox>
        Su personaggi celebri e vicende storiche vengono spesso raccontati aneddoti di ogni tipo.<br>
        Non sempre però sono del tutto veri, anzi, a volte sono completamente falsi.
    </Textbox>
    <Textbox>
        Anche sulla nascita del <i class="italic">Barbiere di Siviglia</i> di Rossini se ne sono sentite di tutti i colori.<br>
        Bisogna quindi stare attenti: il mondo dell’opera lirica non è immune dalle fake news!
    </Textbox>

    <Title right>Un’opera creata per un’occasione speciale.</Title>
    <Textbox>
        Fortunatamente, grazie ad una serie di documenti, possiamo avere delle certezze.<br>
    </Textbox>
    <Textbox>
        Una di queste è che l’idea del <i class="italic">Barbiere di Siviglia</i> cominciò a prendere forma tra la fine del 1815
        e l’inizio del 1816 in occasione di un'insolita stagione di <GlossaryWord :word-id="31">Carnevale</GlossaryWord> del Teatro Argentina:
        la prima in cui, anziché opere <GlossaryWord :word-id="11">serie</GlossaryWord> e <GlossaryWord :word-id="12">semiserie</GlossaryWord>, vennero rappresentate opere buffe.
    </Textbox>

    <ContentImageBox>
        <img src="/assets/operas/barbieredisiviglia/level-1/content-image-0-O0L1C0.png" alt="" />
    </ContentImageBox>

    <Title>Una stagione teatrale da organizzare in tempi record.</Title>

    <Textbox>
        A Roma l’apertura dei teatri era sotto il controllo della Chiesa e la loro gestione era affidata a personalità dell’aristocrazia.
        Nello specifico, la gestione del Teatro Argentina fu assegnata al duca <GlossaryWord :word-id="23">Francesco Sforza Cesarini</GlossaryWord>
        dal cardinale <GlossaryWord :word-id="24">Ercole Consalvi</GlossaryWord>.
    </Textbox>
    <Textbox>
        Il duca si mise subito al lavoro, alla ricerca di interpreti da impegnare nella stagione di Carnevale. Il tempo per organizzare tutto era pochissimo.
    </Textbox>

    <Title right>Francesco Sforza CesarIni: “Rossini, salvami tu!”</Title>
    <Textbox>
        A rendere tutto ancora più complesso era il fatto che, in quella particolare stagione teatrale,
        oltre alle due riprese della <i class="italic">Cameriera astuta, o sia Marco Tondo</i> di Ferdinando Paini e dell'<i class="italic">
        <GlossaryWord :word-id="27">Italiana in Algeri</GlossaryWord></i>
        di Gioachino Rossini, era prevista una nuova opera di cui si dovevano ancora individuare
        <GlossaryWord :word-id="13">compositore</GlossaryWord> e librettista.
    </Textbox>
    <Textbox>
        Fu in questo clima che, in una data imprecisata, Rossini venne contattato per comporre
        quella che sarebbe diventata una delle sue opere più famose: <i class="italic">Il barbiere di Siviglia.</i>
    </Textbox>

    <ContentImageBox align="right">
        <img src="/assets/operas/barbieredisiviglia/level-1/content-image-1-O0L1C0.png" alt="" />
    </ContentImageBox>

    <Title right>Un’opera last minute destinata a restare per sempre.</Title>
    <Textbox>
        Il 26 dicembre 1815, Francesco Sforza Cesarini presentò il contratto a Rossini in cui gli si chiedeva
        di impegnarsi a fare “tutte quelle variazioni che saranno necessarie tanto per il buon esito della Musica, quanto per le circostanze
        e convenienze de med. Sig.ri Cantanti, a sola richiesta del Sig.r Impresario”.
    </Textbox>
    <Textbox>
        Rossini, pur non avendo ancora ricevuto il libretto, si impegnò a consegnare al <GlossaryWord :word-id="14">copista</GlossaryWord>
        il primo atto entro il 20 gennaio 1816 e il secondo a inizio febbraio.
    </Textbox>

    <Title>Tutto pronto per la stagione di Carnevale. O quasi.</Title>
    <Textbox>
        In pochi giorni la <GlossaryWord :word-id="29">compagnia</GlossaryWord> riuscì a mettere in scena la prima delle tre opere previste:
        <i class="italic">L'Italiana in Algeri</i> venne rappresentata il 13 gennaio 1816, in un clima non proprio sereno a causa
        della presenza di una presunta <GlossaryWord :word-id="30">claque</GlossaryWord> ostile al Teatro Argentina, forse prezzolata dai gestori del
        <GlossaryWord :word-id="26">Teatro Valle</GlossaryWord>. Il 4 febbraio fu poi la volta della <i class="italic">Cameriera astuta.</i>
    </Textbox>
    <Textbox>
        E la nuova opera affidata a Rossini? Ferma ai box in attesa del libretto.
    </Textbox>

    <ContentImageBox>
        <img src="/assets/operas/barbieredisiviglia/level-1/content-image-2-O0L1C0.png" alt="" />
    </ContentImageBox>

    <Title>Habemus librettista! Anche se non è molto convinto…</Title>
    <Textbox>
        L’incarico della stesura del libretto fu affidato a Cesare Sterbini.
        Inizialmente non si mostrò felice del compito, dichiarando di averlo accettato soprattutto
        per le pressioni di Rossini con il quale aveva da poco collaborato per
        <GlossaryWord :word-id="28">Torvaldo e Dorliska</GlossaryWord>.
    </Textbox>
    <Textbox>
         Nonostante lo scetticismo iniziale, portò a termine il lavoro, e il 20 febbraio 1816
        <i class="italic">Il barbiere di Siviglia</i> fece il suo debutto sul palco del Teatro Argentina.
    </Textbox>

    <FunFact theme="green" hidden-legacy-p :hidden-top-desc="false">
        <template #counter>CURIOSITÀ (1 su 3)</template>
        <template #title>Il libretto inizialmente era stato affidato ad un’altra persona.</template>
        <template #upper-description>
            <Textbox class="mb-4">
                Cesare Sterbini non fu la prima scelta di Francesco Sforza Cesarini.
                I primi contatti per la scrittura del libretto del <i class="italic">Barbiere</i> vennero infatti presi con
                <GlossaryWord :word-id="25" theme="white">Jacopo Ferretti</GlossaryWord>.
            </Textbox>
        </template>
        <template #funcfact-p>
            <ContentFunfactParagraph>
                <template #image>
                    <img src="/assets/operas/barbieredisiviglia/level-1/funfact-image-0-O0L1C0.png" alt="fun-fact related image"/>
                </template>
                <template #image-description>
                    <p>
                        La collaborazione però non andò in porto, non si sa se per volontà di Sforza Cesarini o dello stesso Rossini.
                        Sterbini subentrò a Ferretti con grande ritardo, dopo un contenzioso avvenuto, secondo le sue stesse parole,
                        “senza di lui [Ferretti] colpa”.
                    </p>
                </template>
            </ContentFunfactParagraph>
        </template>
    </FunFact>

    <NextChapterCard v-model="readChapters" :chapter-index="chapterIndex"/>
</template>

<style scoped>

</style>
