<template>
  <svg width="40" height="52" viewBox="0 0 40 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.07349 11.485C3.07349 6.84474 6.87849 3.05669 11.6049 3.05669H28.3961C33.1226 3.05669 36.9276 6.84474 36.9276 11.485V40.5148C36.9276 45.1551 33.1226 48.9431 28.3961 48.9431H11.6049C6.87849 48.9431 3.07349 45.1551 3.07349 40.5148V11.485ZM11.6049 0.0566864C5.25095 0.0566864 0.0734863 5.15873 0.0734863 11.485V40.5148C0.0734863 46.8411 5.25095 51.9431 11.6049 51.9431H28.3961C34.7501 51.9431 39.9276 46.8411 39.9276 40.5148V11.485C39.9276 5.15873 34.7501 0.0566864 28.3961 0.0566864H11.6049ZM14.6653 16.7585C14.6653 14.358 16.6358 12.3856 19.0992 12.3856C21.5485 12.3856 23.5104 14.3353 23.533 16.717V16.8C23.5104 19.1817 21.5485 21.1315 19.0992 21.1315C16.6358 21.1315 14.6653 19.1591 14.6653 16.7585ZM19.0992 9.38563C20.7577 9.38563 22.2932 9.92574 23.533 10.8403V9.19261C23.533 8.36418 24.2045 7.69261 25.033 7.69261C25.8614 7.69261 26.533 8.36418 26.533 9.19261V16.7043L26.5332 16.7585L26.533 16.8128V23.0089C26.533 23.8374 25.8614 24.5089 25.033 24.5089C24.2045 24.5089 23.533 23.8374 23.533 23.0089V22.6768C22.2932 23.5913 20.7577 24.1315 19.0992 24.1315C15.0082 24.1315 11.6653 20.8451 11.6653 16.7585C11.6653 12.672 15.0082 9.38563 19.0992 9.38563ZM14.6206 28.5534C13.7921 28.5534 13.1206 29.225 13.1206 30.0534C13.1206 30.8819 13.7921 31.5534 14.6206 31.5534H21.3855L12.0357 40.8071C11.6035 41.2348 11.4723 41.8812 11.7036 42.4436C11.9348 43.0061 12.4828 43.3732 13.0909 43.3732H25.0335C25.8619 43.3732 26.5335 42.7016 26.5335 41.8732C26.5335 41.0448 25.8619 40.3732 25.0335 40.3732H16.7388L26.0886 31.1196C26.5208 30.6918 26.652 30.0454 26.4208 29.483C26.1895 28.9206 25.6416 28.5534 25.0335 28.5534H14.6206Z"
      fill="currentColor"
    />
  </svg>
</template>
