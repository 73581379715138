<script setup>
import AppDialog from "@/components/utilities/AppDialog.vue";
import {computed, ref} from "vue";
import PersonaggioCard from "@/components/content/characters/CharacterCard.vue";
import { useUserProgressionStore } from "@/stores/userProgressionStore.js";

const emit = defineEmits(['character-unlocked'])

const props = defineProps({
    character: { type: Object, required: true },
});

const cardBg = `url(${props.character.cardPicMini})`

const UserProgression = useUserProgressionStore();
const dialog = ref(null);
const isRevealed = computed(() => UserProgression.isCharacterRevealed(props.character.id));

function setRevealed() {
    UserProgression.revealCharacterById(props.character.id);
    emit('character-unlocked', props.character.id);
}
</script>

<template>
    <!-- reveal trigger -->
    <div
        class="character-card-trigger"
        :style="isRevealed ? `background-image: ${character.cardPic}` : ''"
        @click.self="dialog.openDialog()"
        :class="isRevealed ? 'revealed' : ''"
    >
        <!-- reveal trigger content -->
        <p v-if="!isRevealed" class="character-card-trigger-text">{{character.name}}</p>
        <!-- dialog shown on trigger's click -->
        <AppDialog ref="dialog" class="h-144">
            <!-- card  -->
            <PersonaggioCard :isRevealed="isRevealed" @revealed="setRevealed" @close-card="dialog.closeDialog()" :character="character"/>
        </AppDialog>
    </div>
</template>

<style scoped>

.character-card-trigger {
    @apply bg-black text-gold w-full rounded-2xl overflow-clip;
    aspect-ratio: 2/3;

    background-image: url("/assets/curtains/curtain-bg-left-new.png");
    background-size: cover;
    background-repeat: no-repeat;
}
.character-card-trigger.revealed {
    /* @apply bg-green ; */
    background-image: v-bind(cardBg);
    background-position: center;
    background-size: cover;
}

.character-card-trigger-text {
    @apply pt-1.5 text-center font-bold text-sm uppercase;
    /* font-size: 0.8rem; */
}
</style>
