<script setup>

</script>

<template>
    <span>
        Al termine di questo livello avrai una <slot></slot> del <i class="italic">Barbiere di Siviglia</i>.
    </span>
</template>

<style scoped>

</style>
