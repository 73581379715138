<template>
    <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.316062 3.53941C0.718975 3.16168 1.35181 3.18209 1.72954 3.585L8.5 10.8068L15.2705 3.585C15.6482 3.18209 16.281 3.16168 16.6839 3.53941C17.0869 3.91714 17.1073 4.54997 16.7295 4.95289L8.5 13.7311L0.270466 4.95289C-0.107264 4.54997 -0.08685 3.91714 0.316062 3.53941Z"
            fill="currentColor"
        />
    </svg>
</template>
