<script setup>
import LayoutSecondaryPage from "@/components/home/LayoutSecondaryPage.vue";
import SecondaryPageHeader from "@/components/utilities/SecondaryPageHeader.vue";
import IconProfilo from "@/components/icons/IconProfile.vue";
import UserProfileImage from "@/components/utilities/UserProfileImage.vue";
import UserImageSelector from "@/components/profile/UserImageSelector.vue";
import {useUserStore} from "@/stores/userStore.js";

const User = useUserStore();

</script>

<template>
    <LayoutSecondaryPage>
        <template #head>
            <SecondaryPageHeader>
                <template #icon><IconProfilo class="w-full h-full" />
                </template>
                <template #text>Profilo</template>
            </SecondaryPageHeader>
        </template>
        <!-- page content -->
        <template #main>
            <div class="container max-w-screen-lg">
                <div class="flex items-center mb-8">
                    <div class="profile-imgbox w-48 h-48 md:h-64 md:w-64">
                        <UserProfileImage></UserProfileImage>
                    </div>
                    <h2 class="pl-4" v-if="User.role === 'registered'">
                        {{ User.email }}
                    </h2>
                </div>
                <div class="max-w-screen-md mx-auto">
                    <div class="px-4">
                        <h3>Avatar disponibili</h3>
                        <p class="text-warm-light mb-14">Scegli uno tra gli avatar che hai sbloccato</p>
                    </div>
                    <UserImageSelector></UserImageSelector>
                </div>

            </div>
        </template>
    </LayoutSecondaryPage>
</template>
