<script setup>
import {AppContent} from "@/content/data/content.js";
import { useUserProgressionStore } from "@/stores/userProgressionStore.js";
const UserProgression = useUserProgressionStore();

import AppAccordion from "@/components/utilities/AppAccordion.vue";
</script>

<template>
    <AppAccordion
        v-for="(opera, oIndex) in AppContent.operas"
        :key="opera.title"
        :ariaTitle="opera.title"
        theme="operaAchievements"
        :preExpanded="oIndex === 0"
    >
        <template #trigger>
            <h2 class="text-2xl font-medium text-grey-dark">{{ opera.title }}</h2>
        </template>
        <template #content>
            <div class="achievements-list">
                <template v-for="(level, lIndex) in opera.levels">
                    <div
                        class="achievement-item"
                        v-for="(item, iIndex) in level.achievements"
                        :key="item.title"
                        :class="UserProgression.isAchievementUnlocked(item.id)? 'unlocked': ''">
                        <img class="achievement-item-img"
                             :src="AppContent.achievementProfilePictures[item.id.toString()][UserProgression.isAchievementUnlocked(item.id)? 'default' : 'locked']"
                             alt="achievement icon"/>
                        <div
                            class="achievement-item-textbox flex flex-col justify-center"
                        >
                            <h3 class="mb-1">
                                {{ item.title }}
                            </h3>
                            <p class="font-normal text-sm" v-html="item.description"></p>
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </AppAccordion>
</template>

<style scoped>

.achievements-list{
    @apply px-7 pt-2
}

.achievement-item {
    @apply flex p-4 bg-grey-light text-grey-dark mb-6 rounded-3xl;
}
.achievement-item.unlocked {
    @apply bg-green-light text-white;
}
/* .achievement-item-textbox {
    @apply flex flex-col;
} */
.achievement-item-img {
    @apply w-16 h-16 mr-4 opacity-35 rounded-full;
}

.achievement-item.unlocked .achievement-item-img {
    @apply opacity-100;
}
</style>
