export const instrumentsMap = {
    1: {
        title: "Archi",
        img: "/assets/operas/barbieredisiviglia/level-0/instrument-image-1-O0L0C2.png",
        source: "https://io-bunny-cdn.b-cdn.net/ROF/instruments/archi.mp3",
    },
    2: {
        title: "Chitarra",
        img: "/assets/operas/barbieredisiviglia/level-0/instrument-image-2-O0L0C2.png",
        source: "https://io-bunny-cdn.b-cdn.net/ROF/instruments/chitarra.mp3",
    },
    3: {
        title: "Corno",
        img: "/assets/operas/barbieredisiviglia/level-0/instrument-image-3-O0L0C2.png",
        source: "https://io-bunny-cdn.b-cdn.net/ROF/instruments/corno.mp3",
    },
    4: {
        title: "Fagotto",
        img: "/assets/operas/barbieredisiviglia/level-0/instrument-image-4-O0L0C2.png",
        source: "https://io-bunny-cdn.b-cdn.net/ROF/instruments/fagotto.mp3",
    },
    5: {
        title: "Oboe",
        img: "/assets/operas/barbieredisiviglia/level-0/instrument-image-5-O0L0C2.png",
        source: "https://io-bunny-cdn.b-cdn.net/ROF/instruments/oboe.mp3",
    },
    6: {
        title: "Pianoforte",
        img: "/assets/operas/barbieredisiviglia/level-0/instrument-image-6-O0L0C2.png",
        source: "https://io-bunny-cdn.b-cdn.net/ROF/instruments/pianoforte.mp3",
    },
}
