<script setup>
import {ref} from "vue";
import ContentTextbox from "@/components/content/ContentTextbox.vue";

const props = defineProps({
    theme : {type : String, required : false, default : "orange"},
    hiddenTopDesc : {type : Boolean, required : false, default : true},
    hiddenBotDesc : {type : Boolean, required : false, default : false},
    hiddenLegacyP : {type : Boolean, required : false, default : false},
})

const themes = {
    green : {
        backgroundColor : "#39AFA1",
        topDeco : `url("/assets/fun-fact-paragraph/fun-fact-top-deco-green.png")`,
        bottomDeco : `url("/assets/fun-fact-paragraph/fun-fact-bottom-deco-green.png")`,
        title : "text-white",
        counter : "text-warm-light"
    },
    orange : {
        backgroundColor : "#FFAB7C",
        topDeco : `url("/assets/fun-fact-paragraph/fun-fact-top-deco-orange.png")`,
        bottomDeco : `url("/assets/fun-fact-paragraph/fun-fact-bottom-deco-orange.png")`,
        title : "text-blue",
        counter : "text-warm"
    }
}

const currentTheme = ref(themes[props.theme])

</script>

<template>
    <div class="content-funfact">
        <div class="content-funfact-deco top"></div>
        <div class="content-funfact-deco bottom"></div>
        <div class="content-funfact-content">
            <span class="content-funfact-counter hidden" :class="themes[theme].counter">
                <slot name="counter"></slot>
            </span>
            <h2 class="content-funfact-title" :class="themes[theme].title">
                <slot name="title"></slot>
            </h2>
            <div class="mt-7 mb-4" :class="hiddenTopDesc ? 'hidden' : ''">
                <slot name="upper-description"></slot>
            </div>
            <slot name="funcfact-p"></slot>
            <div class="flex mb-4" :class="hiddenLegacyP ? 'hidden' : ''">
                <div class="content-funfact-image">
                    <slot name="image"></slot>
                </div>
                <div class="content-funfact-textbox">
                    <p>
                        <slot name="image-description"></slot>
                    </p>
                </div>
            </div>
            <slot name="bottom-description" :class="hiddenBotDesc ? 'hidden' : ''">

            </slot>
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<style scoped>
.content-funfact {
    @apply relative min-h-48 mt-8;
    background-color: v-bind('currentTheme.backgroundColor');
}

.content-funfact-content {
    @apply flex flex-col max-w-screen-md mx-auto py-20;
}

/** DECOS */
.content-funfact-deco {
    @apply absolute w-full left-0 h-14;
    @apply bg-repeat-x;
}
.content-funfact-deco.top {
    @apply top-0 bg-top bg-white;
    background-image: v-bind('currentTheme.topDeco');
}
.content-funfact-deco.bottom {
    @apply bottom-0 bg-bottom bg-white;
    background-image: v-bind('currentTheme.bottomDeco')
}

.content-funfact-image{
    @apply w-5/12 md:w-3/10 flex flex-col justify-center
}

.content-funfact-textbox{
    @apply w-7/12 sm:w-1/2 flex flex-col justify-center px-4 md:px-9 pt-4 text-black
}

.content-funfact-title{
    @apply font-medium mb-2 mt-3 max-w-screen-sm mx-auto px-8
}
.content-funfact-counter{
    @apply px-4 md:px-32 md:mb-6
}
</style>
