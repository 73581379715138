export const levelsMap = {
    1: {
        id: 1,
        prettyName: "1",
        title: "Livello 1",
        description: "conoscenza generale",
        duration: "10'",
        selectorImg: {
            default: "/assets/operas/barbieredisiviglia/level-0/level-selector-O0L0.png",
            warm: "/assets/operas/barbieredisiviglia/level-0/level-selector-big-O0L0.png"
        },
        achievements: [1, 2, 3],
        chapters: [1, 2, 3, 4],
    },
    2: {
        id: 2,
        prettyName: "2",
        title: "Livello 2",
        description: "conoscenza intermedia",
        duration: "15'",
        selectorImg: {
            default: "/assets/operas/barbieredisiviglia/level-1/level-selector-O0L1.png",
            warm: "/assets/operas/barbieredisiviglia/level-1/level-selector-big-O0L1.png"
        },
        achievements: [4, 5, 9],
        chapters: [5, 6, 7, 8],
    },

    3: {
        id: 3,
        prettyName: "3",
        title: "Livello 3",
        description: "conoscenza avanzata",
        duration: "20'",
        selectorImg: {
            default: "/assets/operas/barbieredisiviglia/level-2/level-selector-O0L2.png",
            warm: "/assets/operas/barbieredisiviglia/level-2/level-selector-big-O0L2.png"
        },
        achievements:[6, 7, 8],
        chapters: [9, 10, 11, 12],
    },

}

