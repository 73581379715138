<script setup></script>

<template>
    <div class="content-textbox">
        <p>
            <slot></slot>
        </p>
    </div>
</template>

<style scoped>
.content-textbox {
    @apply container max-w-screen-sm ps-14 pe-14 text-black;
}
.content-textbox:last-child {
    @apply mb-0;
}
</style>
