import {useRoute} from "vue-router";
import {AppContent} from "@/content/data/content.js";

function extractOperaRouteInfo(){
    const route = useRoute()
    return extractOperaInfoFromRoute(route)
}

function extractOperaInfoFromRoute(route){
    const operaId = route.params.operaId
    const operaIndex = AppContent.operas.findIndex(el => el.prettyName === operaId);

    return {
        id : operaId,
        index : operaIndex
    }
}

function extractLevelRouteInfo(){
    const route = useRoute()
    return extractLevelInfoFromRoute(route)
}

function extractLevelInfoFromRoute(route){
    const levelId = route.params.levelId
    const operaInfo = extractOperaInfoFromRoute(route)

    const levelIndex = AppContent.operas[operaInfo.index].levels.findIndex(el => el.prettyName === levelId);

    return {
        operaInfo,
        levelInfo : {
            id : levelId,
            index : levelIndex
        }
    }
}

function extractChapterRouteInfo(){
    const route = useRoute()
    return extractChapterInfoFromRoute(route)
}

function extractChapterInfoFromRoute(route){
    const chapterId = route.params.chapterId
    const levelRouteInfo = extractLevelInfoFromRoute(route)
    const chapterIndex = AppContent.operas[levelRouteInfo.operaInfo.index]
        .levels[levelRouteInfo.levelInfo.index]
        .chapters.findIndex(el => el.prettyName === chapterId)

    return{
        ...levelRouteInfo,
        chapterInfo : {
            id : chapterId,
            index : chapterIndex
        }
    }
}

export {
    extractChapterRouteInfo,
    extractLevelRouteInfo,
    extractOperaRouteInfo,
    extractChapterInfoFromRoute,
    extractLevelInfoFromRoute,
    extractOperaInfoFromRoute,
}
