<script setup>
import {computed, defineModel, onMounted, ref} from "vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {extractChapterRouteInfo, extractLevelRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {AppContent} from "@/content/data/content.js";

const props = defineProps({
    chapterIndex : {type: Number, required: true},
})

const UserProgression = useUserProgressionStore()

const routeInfo = extractChapterRouteInfo()

const operaIndex = routeInfo.operaInfo.index;
const levelIndex = routeInfo.levelInfo.index;

const readChapters = defineModel()

const canProceed = ref(false)

function onCompleted(){
    readChapters.value = props.chapterIndex + 1;
}

const isMinigameCompleted = () => localStorage.getItem('minigame-completed') === "true"

onMounted(() => {
    window.addEventListener('message', (event) => {
        if(event.data.source === "rof-minigame") {
            canProceed.value = event.data.content === "minigame-completed"
            UserProgression.setChapterAsRead(operaIndex, levelIndex, props.chapterIndex)
        }
    });
})

</script>

<template>
    <div class="minigame-container z-0 relative justify-center">
        <iframe src="/minigame/index.html?level=bds_1" class="grow"/>

        <div v-if="canProceed" class="minigame-complete-button-container">
            <button  @click="onCompleted" class="minigame-complete-button">
                CONTINUA
            </button>
        </div>

    </div>
</template>

<style scoped>
.minigame-container {
    @apply absolute w-full h-full bottom-1 left-0 flex flex-row pb-16;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.minigame-complete-button-container{
    @apply absolute self-end w-full p-4 bottom-14 z-10
}

.minigame-complete-button{
    @apply w-full p-4 bg-green text-white rounded-3xl my-4 z-10
}
</style>
