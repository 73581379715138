import Opera0LevelDescription from "@/content/components/ilbarbieredisiviglia/Opera0LevelDescription.vue";
import BarbiereIcon from "@/components/icons/BarbiereIcon.vue";
import Opera0End from "@/content/components/ilbarbieredisiviglia/Opera0End.vue";

export const operasMap = {
    1: {
        id: 1,
        prettyName: "ilbarbieredisiviglia",
        title: "Il barbiere di Siviglia",
        selectorImg: BarbiereIcon,
        selectorDecos: [
            {
                img: "/assets/operas/opera01-selector-deco-01.png",
            },
            {
                img: "/assets/operas/opera01-selector-deco-02.png",
            },
            {
                img: "/assets/operas/opera01-selector-deco-03.png",
            },
            {
                img: "/assets/operas/opera01-selector-deco-04.png",
            },
            {
                img: "/assets/operas/opera01-selector-deco-05.png",
            },
            {
                img: "/assets/operas/opera01-selector-deco-06.png",
            },
        ],
        levelDescriptionComponent : Opera0LevelDescription,
        levels: [1, 2, 3],
        epilogue : Opera0End
    },
    2: {
        id: 2,
        prettyName: "cenerentola",
        title: "Cenerentola",
        selectorImg: "/assets/operas/opera02-selector.png",
        selectorDecos: [
            {
                img: "/assets/operas/opera01-selector-deco-01.png",
            },
            {
                img: "/assets/operas/opera01-selector-deco-02.png",
            },
            {
                img: "/assets/operas/opera01-selector-deco-03.png",
            },
            {
                img: "/assets/operas/opera01-selector-deco-04.png",
            },
            {
                img: "/assets/operas/opera01-selector-deco-05.png",
            },
            {
                img: "/assets/operas/opera01-selector-deco-06.png",
            },
        ],
    }
}

