import axios from "axios";

function updateUserProfileImage(profileImage){
    axios.post('/user/profileImage/update', {profileImage})
        .catch(err => console.log(err))
}

function updateUserLevelProgressionState(levelId, state){
    axios.post('/user/levelProgression/update', {levelId, state})
        .catch(err => console.log(err))
}

function updateUserChapterProgressionState(chapterId, state){
    axios.post('/user/chapterProgression/update', {chapterId, state})
        .catch(err => console.log(err))
}

function addAchievementToUser(achievementId){
    axios.post('/user/achievements/add', {achievementId})
        .catch(err => console.log(err))
}

function addGlossaryWordToUser(glossaryWordId){
    axios.post('/user/glossaryWords/add', {glossaryWordId})
        .catch(err => console.log(err))
}

function addCharacterToUser(characterId){
    axios.post('/user/characters/add', {characterId})
        .catch(err => console.log(err))
}

function addAriaToUser(ariaId) {
    axios.post('/user/arias/add', {ariaId})
        .catch(err => console.log(err))
}

function addAutoimprestitoToUser(autoimprestitoId){
    axios.post('/user/autoimprestiti/add', {autoimprestitoId})
        .catch(err => console.log(err))
}

function addDirectorToUser(directorId){
    axios.post('/user/directors/add', {directorId})
        .catch(err => console.log(err))
}

export const remote = {
    updateUserProfileImage,
    updateUserLevelProgressionState,
    updateUserChapterProgressionState,
    addCharacterToUser,
    addGlossaryWordToUser,
    addAchievementToUser,
    addAriaToUser,
    addAutoimprestitoToUser,
    addDirectorToUser,
}
