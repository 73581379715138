<script setup>
import {RouterLink} from "vue-router";
import AppButton from "@/components/utilities/AppButton.vue";

const props = defineProps({
    to: {type: String, required: true},
    level: {type: Object, required: true},
    descriptionComponent : {type : Object, required : true},
    progression: {type: String, required: true},
});

const texts = {
    locked: "BLOCCATO",
    unlocked: "INIZIA",
    completed: "RIPASSA",
    mastered: "RIPASSA",
};


</script>

<template>
    <!-- livello card -->
    <div class="level-card">
        <!-- head -->
        <div class="livello-head px-8 pt-8">
            <h2 class="text-white uppercase mb-2 text-2xl">
                {{ level.title }}
            </h2>
            <slot name="stats"></slot>
        </div>
        <!-- background image -->
        <div class="level-card-imagebox">
            <img :src="level.selectorImg.default" class="absolute-top preserve-ratio image-cover fixed-top" />
            <img class="absolute-top preserve-ratio image-cover fixed-bottom" src="/assets/halftones/level-card-halftone.png">
        </div>
        <!-- foot -->
        <div class="level-foot">
            <p class="text-white my-4">
                <component :is="descriptionComponent">
                    <span class="font-bold text-warm-light">{{level.description}}</span>
                </component>
            </p>
            <RouterLink :to="to">
                <AppButton
                    :disabled="progression === 'locked'"
                    :text="texts[progression]"
                    class="uppercase w-full"
                    text="inizia"
                    theme="roundedWhiteBg"
                    variant="text"
                ></AppButton>
            </RouterLink>
        </div>
    </div>
</template>

<style scoped>
.level-card {
    @apply bg-green-light text-warm-light rounded-3xl flex flex-col mx-4 md:mx-10;

    width: 18em;
    height: clamp(28em, 60vh, 36em);
    /* height: 28rem; */

    /* height: 32em; */
    /* min-height: 16em; */
    min-width: 18em;
    scroll-snap-align: center;
}

.level-card-imagebox{
    @apply mt-4 mb-4 flex-grow relative
}

.level-foot{
    @apply flex flex-col px-8 pb-9;
}

.level-foot-content{
    @apply px-8 bg-green-light
}

.level-foot-halftone{
    @apply w-full relative h-60 z-10;
}

.level:first-child {
}

.level:last-child {
    @apply mr-0;
}


</style>
