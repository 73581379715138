<script setup>
// https://gesture.vueuse.org/
import { useDeviceStateStore } from "@/stores/appState.js";
import { ref } from "vue";
import PersonaggioCardInner from "@/components/content/characters/CharacterCardInner.vue";
import IconDrag from "@/components/icons/IconDrag.vue";
import IconCloseCard from "@/components/icons/IconCloseCard.vue";

const props = defineProps({
    isRevealed: { type: Boolean, required: true, default: false },
    character: { type: Object, required: true },
});

const emit = defineEmits(["revealed", "closeCard"]);

const DeviceState = useDeviceStateStore();

const curtain = ref(null);

function dragHandler(dragState){
    let left = dragState.movement[0];
    let contWidth = curtain.value.getBoundingClientRect().width;
    let triggerPoint = contWidth / 2;

    if (dragState.dragging) {
        curtain.value.style.left = left + "px";
        curtain.value.style.cursor = "grabbing";
    } else {
        curtain.value.style.left = "0px";
        curtain.value.style.cursor = "grab";
    }

    if (Math.abs(left) > triggerPoint) {
        emit("revealed");
    }
};
</script>

<template>
    <!-- card container -->
    <div class="character-card">
        <button class="h-11 w-11 absolute right-3.5 top-4 z-10">
            <IconCloseCard @click="emit('closeCard')"/>
        </button>
        <!-- actual card content -->
        <PersonaggioCardInner
            ref="content"
            :isRevealed="isRevealed"
            :character="character"
        ></PersonaggioCardInner>

        <!-- card reveal mechanism -->
        <div
            v-if="!isRevealed"
            class="absolute top-0 left-0 right-0 bottom-0 overflow-clip h-full w-full"
            v-drag="dragHandler"
            :useTouch="DeviceState.getTouchState()"
        >
            <!-- draggable curtain -->
            <div class="character-card-curtain" ref="curtain">
                <div class="h-12 w-12 mb-4 select-none">
                    <IconDrag></IconDrag>
                </div>
                <p class="select-none">Trascina la tenda</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.character-card {
    @apply relative w-96 h-144 max-w-full max-h-full bg-black rounded-3xl overflow-hidden border-yellow border-2;
}
.character-card-curtain {
    @apply absolute top-0 w-full h-full bg-black text-warm-light;
    @apply flex flex-col justify-center items-center;
    @apply bg-no-repeat bg-cover;
    /* left: var(--progress); */
    background-image: url("/assets/curtains/curtain-bg-left-new.png");
}
</style>
