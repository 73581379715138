<script setup>
import {useAudioGroupController} from "@/libraries/audio/AudioGroupController.js";
import {ref} from "vue";

/**
 * HTML Video wrapper component
 * // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video
 * @param {number} width Source width
 * @param {number} height Source height
 * @param {boolean} autoplay
 * @param {boolean} muted
 * @param {boolean} controls
 * #sources {slot}
 */
const props = defineProps({
    width: { type: String, required: true }, // no percentage
    height: { type: String, required: true }, // no percentage
    autoplay: { type: Boolean, required: false },
    muted: { type: Boolean, required: false },
    controls: { type: Boolean, required: false },
    controlslist: { type: String, required: false },
    poster: { type: String, required: false },
});

const emits = defineEmits(["play"])

const video = ref(null)

const audioController = useAudioGroupController()

function videoStarted(){
    audioController.newAudioStarted(video.value)
    emits("play")
}

</script>

<template>
    <video
        class="p-4 rounded-3xl mx-auto"
        :width="width"
        :height="height"
        :autoplay="autoplay"
        :muted="muted"
        :controls="controls"
        :controlslist="controlslist"
        :poster="poster"
        preload="auto"
        @play="videoStarted"
        ref="video"
    >
        <slot name="sources">
            <!-- fallback -->
            No video sources provided or your browser does not support the video
            tag.
        </slot>
    </video>
</template>

<style scoped></style>
