import {defineStore} from "pinia";
import {remote} from "@/libraries/remote/Remote.js";

export const useUserStore = defineStore("user", {
    state: () => ({
        id: 0,
        email: "",
        profileImg: "default",
        role : ""
    }),
    getters: {
        currentProfileImg(state){
            return state.profileImg
        },
        isGuest(state){
            return state.role === "guest"
        }
    },
    actions: {
        setValues(setupValues){
            this.profileImg = setupValues.profileImg;
            this.email = setupValues.email;
            this.id = setupValues.id;
            this.role = setupValues.role;
        },
        setProfileImg(profileImg) {
            this.profileImg = profileImg;
            remote.updateUserProfileImage(this.profileImg);
        },
    },
    persist: true,
});
