<script setup>
// import Dialog from "@/components/Dialog.vue";
import { ref } from "vue";
import PersonaggioTrigger from "@/components/content/characters/CharacterTrigger.vue";

const emit = defineEmits(['character-unlocked'])

const props = defineProps({
    characters : {type : Object, required: true},
})

const isRevealed = ref(false);

function characterUnlocked(characterId){
    emit('character-unlocked', characterId)
}
</script>

<template>
    <!-- grid -->
    <div class="personaggi-cards-reveal">
        <!-- reveal trigger -->
        <PersonaggioTrigger @character-unlocked="characterUnlocked" v-for="character in characters" :character="character"/>
    </div>
</template>

<style scoped>
.personaggi-cards-reveal {
    @apply grid grid-cols-3 mx-4 gap-4 mb-8 max-w-screen-sm mx-auto px-4;
}
</style>
