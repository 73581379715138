<script setup>

</script>

<template>
    <div class="content-character-voices-list">
        <slot></slot>
    </div>
</template>

<style scoped>
.content-character-voices-list{
    @apply max-w-screen-sm mx-auto
}
</style>
