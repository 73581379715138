import {extractChapterInfoFromRoute, extractLevelInfoFromRoute} from "@/libraries/routes/RoutesUtilities.js";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {redirectToHome} from "@/libraries/middlewares/Utilities.js";

function redirectIfChapterNotUnlocked(to, from, next){
    const routeInfo = extractChapterInfoFromRoute(to)

    const operaIndex = routeInfo.operaInfo.index
    const levelIndex = routeInfo.levelInfo.index
    const chapterIndex = routeInfo.chapterInfo.index

    const UserProgression = useUserProgressionStore()

    if(UserProgression.levelProgression(operaIndex, levelIndex).state === "locked" ||
        UserProgression.chapterProgression(operaIndex, levelIndex, chapterIndex).state === "locked"){
        redirectToHome(next)
    }
    next()
}

function redirectIfLevelNotUnlocked(to, from, next){
    const routeInfo = extractLevelInfoFromRoute(to)
    const operaIndex = routeInfo.operaInfo.index
    const levelIndex = routeInfo.levelInfo.index

    const UserProgression = useUserProgressionStore()

    if (UserProgression.levelProgression(operaIndex, levelIndex).state === "locked") {
        redirectToHome(next)
    }
    next()
}

export {
    redirectIfChapterNotUnlocked,
    redirectIfLevelNotUnlocked
}



