<script setup>
import Title from "@/components/content/ContentTitle.vue";
import Textbox from "@/components/content/ContentTextbox.vue";
import GlossaryWord from "@/components/glossary/GlossaryWordTooltip.vue";
import FunFact from "@/components/content/ContentFunFact.vue";
import NextChapterCard from "@/components/content/ContentNextChapterCard.vue";
import {defineModel, defineProps, watch} from "vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {useUserNotificationsStore} from "@/stores/userNotificationStore.js";
import {extractChapterRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {AppContent} from "@/content/data/content.js";
import CharacterVoiceCard from "@/components/content/characters/ContentCharacterVoiceCard.vue";
import CharacterParagraph from "@/components/content/CharacterParagraph.vue";
import ContentFunFact from "@/components/content/ContentFunFact.vue";
import ContentGlossaryTooltip from "@/components/glossary/GlossaryWordTooltip.vue";
import CharacterVoicesList from "@/components/content/characters/CharacterVoicesList.vue";
import ContentFunfactParagraph from "@/components/content/ContentFunfactParagraph.vue";

const props = defineProps({
    chapterIndex : {type : Number, required : true}
})

const readChapters = defineModel()

const UserProgression = useUserProgressionStore()
const UserNotifications = useUserNotificationsStore()

const routeInfo = extractChapterRouteInfo()

const operaIndex = routeInfo.operaInfo.index
const levelIndex = routeInfo.levelInfo.index;
const chapterIndex = routeInfo.chapterInfo.index;

const currentChapter = AppContent.operas[operaIndex].levels[levelIndex].chapters[chapterIndex];

watch(UserProgression.glossaryWords, () => {
    if(!UserProgression.isAchievementUnlocked(4) &&
        UserProgression.allLevelGlossaryWordFound(operaIndex, levelIndex)){
        UserProgression.unlockAchievementById(4);
        UserNotifications.pushNotification("achievement", 4);
    }
})
</script>

<template>
    <Title>Quali sono i ruoli vocali dei personaggi più importanti del <i class="italic">Barbiere di Siviglia</i> ?</Title>

    <CharacterVoicesList>
        <CharacterVoiceCard>
            <template #profile-picture>
                <img src="/assets/characters/character-almaviva-profile.png" alt="character profile picture">
            </template>
            <template #title>Conte d’Almaviva</template>
            <template #subtitle><GlossaryWord :word-id="15">Tenore</GlossaryWord></template>
        </CharacterVoiceCard>

        <CharacterVoiceCard>
            <template #profile-picture>
                <img src="/assets/characters/character-figaro-profile.png" alt="character profile picture">
            </template>
            <template #title>Figaro</template>
            <template #subtitle><GlossaryWord :word-id="16">Baritono</GlossaryWord></template>
        </CharacterVoiceCard>

        <CharacterVoiceCard>
            <template #profile-picture>
                <img src="/assets/characters/character-rosina-profile.png" alt="character profile picture">
            </template>
            <template #title>Rosina</template>
            <template #subtitle><GlossaryWord :word-id="17">Mezzosoprano</GlossaryWord></template>
        </CharacterVoiceCard>

        <CharacterVoiceCard>
            <template #profile-picture>
                <img src="/assets/characters/character-bartolo-profile.png" alt="character profile picture">
            </template>
            <template #title>Bartolo</template>
            <template #subtitle><GlossaryWord :word-id="18">Basso</GlossaryWord></template>
        </CharacterVoiceCard>

        <CharacterVoiceCard>
            <template #profile-picture>
                <img src="/assets/characters/character-basilio-profile.png" alt="character profile picture">
            </template>
            <template #title>Basilio</template>
            <template #subtitle><GlossaryWord :word-id="18">Basso</GlossaryWord></template>
        </CharacterVoiceCard>

        <CharacterVoiceCard>
            <template #profile-picture>
                <img src="/assets/characters/character-berta-profile.png" alt="character profile picture">
            </template>
            <template #title>Berta</template>
            <template #subtitle><GlossaryWord :word-id="19">Soprano</GlossaryWord></template>
        </CharacterVoiceCard>
    </CharacterVoicesList>

    <Title>Per interpretare <i class="italic">Il barbiere</i> bisogna essere parecchio in gamba.</Title>

    <Textbox>
        È opinione diffusa che i ruoli vocali delle opere buffe richiedano voci meno importanti di quelle serie.
        Con Rossini però la situazione cambia.
    </Textbox>
    <Textbox>
        Attraverso un uso appropriato della <GlossaryWord :word-id="20">vocalità</GlossaryWord>,
        il compositore pesarese determina infatti il carattere e l'evoluzione psicologica dei personaggi,
        con situazioni di spessore drammatico, pur senza mutare il lieto fine.
    </Textbox>

    <CharacterParagraph>
        <template #title>Rosina</template>
        <template #image>
            <img src="/assets/operas/barbieredisiviglia/level-1/content-image-3-O0L1C1.png"/>
        </template>
        <template #image-description>
            <p class="mb-4">
                Un perfetto esempio dell’evoluzione drammaturgica dei personaggi del <i class="italic">Barbiere</i> è Rosina.
            </p>
            <p>
                Esordendo con la fresca e maliziosa <GlossaryWord :word-id="21">aria</GlossaryWord> “Una voce poco fa”
                manifesta il proposito di lottare per affermare il diritto alla propria libertà,
                per poi incarnare sentimenti nobili, degni di una futura Contessa d'Almaviva, con la grande aria “della lezione”.
            </p>

        </template>
    </CharacterParagraph>

    <CharacterParagraph>
        <template #title>Conte d’Almaviva</template>
        <template #image>
            <img src="/assets/operas/barbieredisiviglia/level-1/content-image-4-O0L1C1.png"/>
        </template>
        <template #image-description>
            <p class="mb-4">
                Si presenta con l’<GlossaryWord :word-id="22">arietta</GlossaryWord> di maniera, aggraziata e leziosa “Ecco ridente in cielo”.
            </p>
            <p class="mb-4">
                Alla reazione di Rosina, che accetta la corte ma pretende garanzie,
                il Grande di Spagna Almaviva decide di nascondere la propria identità presentandosi come Lindoro.
            </p>
            <p>
                Infatti, nella seconda cavatina “Se il mio nome saper voi bramate”,
                esprime sentimenti autentici accompagnato dalla spontaneità del suono della chitarra.
            </p>

        </template>
    </CharacterParagraph>

    <CharacterParagraph>
        <template #title>Bartolo</template>
        <template #image>
            <img src="/assets/operas/barbieredisiviglia/level-1/content-image-5-O0L1C1.png"/>
        </template>
        <template #image-description>
            <p class="mb-4">
                Sul versante opposto, quello del “mondo di ieri”,
                Bartolo afferma la sua appartenenza a una classe sociale rispettabile,
                con la lunga e complessa aria di sortita “A un dottor della mia sorte”.
            </p>
            <p>
                Le difficoltà distribuite nella sua parte attestano il proposito di Rossini
                di conferire a questo basso buffo peso specifico e dignità consone a uno stimato dottore in medicina,
                estranee al livello del caratterista.
            </p>
        </template>
    </CharacterParagraph>

    <CharacterParagraph>
        <template #title>Figaro</template>
        <template #image>
            <img src="/assets/operas/barbieredisiviglia/level-1/content-image-6-O0L1C1.png"/>
        </template>
        <template #image-description>
            <p class="mb-4">
                Quanto a Figaro e alla sua celeberrima cavatina di sortita “Largo al factotum”:
                quanti sono i cantanti capaci di restituire tutte le note scritte da Rossini?
            </p>
            <p>
                Vitalismo, frenesia ritmica, smania di egocentrismo si manifestano in una vocalità che prefigura la tipologia del moderno baritono.
            </p>

        </template>
    </CharacterParagraph>

    <ContentFunFact theme="green" hidden-legacy-p>
        <template #counter>CURIOSITÀ (2 su 3)</template>
        <template #title>Gioachino Rossini: compositore e non solo! </template>
        <template #funcfact-p>
            <ContentFunfactParagraph>
                <template #image>
                    <img src="/assets/operas/barbieredisiviglia/level-1/funfact-image-1-O0L1C1.png" alt="fun-fact related image"/>
                </template>
                <template #image-description>
                    <p>
                        Nell'autunno 1805 Rossini tredicenne canta nel ruolo del piccolo Adolfo nella <i class="italic">Camilla ossia
                        Il sotterraneo</i> di Ferdinando Paër al Teatro del Corso di Bologna.
                    </p>
                </template>
            </ContentFunfactParagraph>
        </template>
        <template #bottom-description>
            <Textbox>
                Si conclude con questa prestazione, avvenuta dietro regolare contratto, la sua esperienza come cantante d'opera,
                che alternava a quella di maestro al cembalo nei teatri frequentati dai genitori.
            </Textbox>
            <Textbox>
                Prima della muta della voce, Rossini sarà apprezzato anche nel genere sacro,
                esibendosi come solista durante le celebrazioni religiose, favorito dal divieto canonico di fare entrare le donne in chiesa.
            </Textbox>
        </template>
    </ContentFunFact>

    <NextChapterCard v-model="readChapters" :chapter-index="chapterIndex"/>
</template>

<style scoped>

</style>
