<template>
    <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.269 35.3101C20.1495 34.4129 20.1495 32.94 19.269 32.0428L7.75493 20.3108L33.7404 20.3108C34.9865 20.3108 36 19.2782 36 18.0085L36 17.9746C36 16.7049 34.9865 15.6722 33.7404 15.6722L7.75493 15.6722L19.269 3.94028C20.1495 3.04303 20.1495 1.57018 19.269 0.672936C18.3884 -0.224313 16.9429 -0.224313 16.0623 0.672936L0.660437 16.3663C-0.220145 17.2636 -0.220145 18.7364 0.660436 19.6337L16.0623 35.3271C16.9429 36.2243 18.3884 36.2243 19.269 35.3271L19.269 35.3101Z"
            fill="currentColor"
        />
    </svg>
</template>
