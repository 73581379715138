<script setup>

import IconCloseCard from "@/components/icons/IconCloseCard.vue";
import AppButton from "@/components/utilities/AppButton.vue";
import MiniArrowIcon from "@/components/icons/MiniArrowIcon.vue";
import {gsap} from "gsap";
import {Draggable} from "gsap/Draggable";
import {onMounted, ref} from "vue";

gsap.registerPlugin(Draggable)


const emit = defineEmits(["closeCard"]);

const slideDuration = 0.8;

let animation = gsap.to({}, {});

const currentSlide = ref(0)

const slides = ref([]);
const slidesCount = ref(0)

const slideInner = ref(null)
const slidesContainer = ref(null)

let draggable

const slidesWidth = () => slides.value[0].offsetWidth

const draggableBounds = () => {
    return {minX: - slidesWidth() * (slidesCount.value - 1), maxX: 0}
}

onMounted(() => {

    const slideInnerSelector = gsap.utils.selector(slideInner.value)
    slides.value = slideInnerSelector(".slide")
    slidesCount.value = slides.value.length

    gsap.set(slides.value, {
        xPercent: i => i * 100
    });

    /*
    draggable = Draggable.create(slideInner.value, {
        trigger : slidesContainer.value,
        type : "x",
        liveSnap : snapX,
        onDragStart : updateDraggable,
        zIndexBoost : false
    })[0]
     */
})

function updateDraggable(){
    draggable.applyBounds(draggableBounds())
}

function animateSlides(direction) {

    if (direction !== 0){
        direction = direction > 0 ? 1 : -1;
    }

    if (0 <= currentSlide.value + direction && currentSlide.value + direction < slidesCount.value && !animation.isActive()){

        currentSlide.value += direction;

        animation = gsap.to(slideInner.value, {
            xPercent: (-100 * currentSlide.value),
            duration: slideDuration,
            ease: "power3.out",
        });
    }
}

function snapX(value) {
    let snapped = gsap.utils.snap(slidesWidth(), value);
    return gsap.utils.clamp(-slidesWidth() * (slidesCount.value - 1), 0, snapped);
}
</script>

<template>
    <div class="slides-card">
        <button class="h-11 w-11 absolute right-3.5 top-4 z-10 hidden">
            <IconCloseCard @click="emit('closeCard')"/>
        </button>
        <div ref="slidesContainer" class="slides-container">
            <div ref="slideInner" class="slides-inner">
                <slot name="slides"></slot>
            </div>
        </div>
        <div class="slides-card-title-container">
            <h3 class="slides-card-title">
                <slot name="title"></slot>
            </h3>
        </div>
        <div class="controls">
            <div>
                <AppButton :disabled="currentSlide === 0" variant="icon" theme="transparentBlack" @click="animateSlides(-1)">
                    <template #icon>
                        <MiniArrowIcon :disabled="currentSlide === 0" :left="true"/>
                    </template>
                </AppButton>
            </div>
            <div class="slides-dots-container">
                <svg v-for="i in slidesCount" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7" cy="7.5" r="7" :fill="(i - 1) === currentSlide ? '#F7F0F5' : '#39AFA1'"/>
                </svg>
            </div>
            <div>
                <AppButton :disabled="currentSlide === slidesCount - 1" variant="icon" theme="transparentBlack" @click="animateSlides(+1)">
                    <template #icon>
                        <MiniArrowIcon :disabled="currentSlide === slidesCount - 1"/>
                    </template>
                </AppButton>
            </div>
        </div>
    </div>
</template>

<style scoped>
.slides-card{
    @apply relative w-96 h-144 max-w-full max-h-full bg-black rounded-3xl overflow-hidden border-yellow border-2;
}

.slides-container {
    @apply relative overflow-hidden flex flex-row w-full h-full;
}

.slides-inner {
    @apply relative h-full w-full;
}

.controls {
    @apply absolute top-16 inset-x-0 px-5 flex flex-row justify-between items-center z-30;
}

.slides-card-title-container{
    @apply absolute z-20 inset-x-5 top-5 h-8 bg-warm flex flex-col justify-center text-center p-1;
}

.slides-card-title{
    @apply text-white text-xl uppercase font-medium;
}

.slides-dots-container{
    @apply flex items-center gap-3.5;
}
</style>
