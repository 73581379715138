import {levelsMap} from "@/content/data/levels.js";
import {chaptersMap} from "@/content/data/chapters.js";
import {achievementProfilePictures, achievementsMap} from "@/content/data/achievements.js";
import {operasMap} from "@/content/data/operas.js";
import {glossaryWordsGroupsMap, glossaryWordsMap} from "@/content/data/glossaryWords.js";
import {charactersMap} from "@/content/data/characters.js";
import {instrumentsMap} from "@/content/data/instruments.js";
import {ariasMap} from "@/content/data/arias.js";
import {autoimprestitiMap, autoimprestitiSourcesMap} from "@/content/data/autoimprestiti.js";

const operasIds = [1]

const getOperaFromId = (operaId) => composeOpera(operasMap[operaId])

const composeOpera = (operaRow) => {
    return {
        ...operaRow,
        levels: operaRow.levels.map(levelId => getLevelFromId(levelId))
    }
}

const getLevelFromId = (levelId) => composeLevel(levelsMap[levelId])

const composeLevel = (levelRow) => {
    return {
        ...levelRow,
        chapters : levelRow.chapters.map(chapterId => getChapterFromId(chapterId)),
        achievements : levelRow.achievements.map(achievementId => getAchievementFromId(achievementId)),
    }
}

const getChapterFromId = (chapterId) => composeChapter(chaptersMap[chapterId])

const composeChapter = (chapterRow) => {
    return {
        ...chapterRow,
        characters : chapterRow.characters?.map(charId => getCharacterFromId(charId)),
        instruments : chapterRow.instruments?.map(instrumentId => getInstrumentFromId(instrumentId)),
        arias : chapterRow.arias?.map(ariaId => getAriaFromId(ariaId)),
        autoimprestiti : chapterRow.autoimprestiti?.map(id => getAutoimprestitoFromId(id)),
    }
}

const getAchievementFromId = (achievementId) => achievementsMap[achievementId]

const getAutoimprestitoFromId = (autoimprestitoId) =>  composeAutoimprestito(autoimprestitiMap[autoimprestitoId])
const composeAutoimprestito = (autoimprestitoRow) => {
    return {
        ...autoimprestitoRow,
        sources : autoimprestitoRow.sources.map(autoimprestitoSourceId => getAutoimprestitoSourceFromId(autoimprestitoSourceId))
    }
}

const getAutoimprestitoSourceFromId = (id) => autoimprestitiSourcesMap[id]

const getGlossaryGroupFromId = (glossaryGroupId) => composeGlossaryGroup(glossaryWordsGroupsMap[glossaryGroupId])

const composeGlossaryGroup = (glossaryGroupRow) => {
    return {
        ...glossaryGroupRow,
        items : glossaryGroupRow.items.map(wordId => getGlossaryWordFromId(wordId))
    }
}

const getGlossaryWordFromId = (wordId) => glossaryWordsMap[wordId]

const getCharacterFromId = (characterId) => charactersMap[characterId]

const getInstrumentFromId = (instrumentId) => instrumentsMap[instrumentId]

const getAriaFromId = (ariaId) => ariasMap[ariaId]

export const AppContent = {
    operas : operasIds.map(operaId => getOperaFromId(operaId)),
    glossary : Object.values(glossaryWordsGroupsMap).map(glossaryGroupRow => composeGlossaryGroup(glossaryGroupRow)),
    achievementProfilePictures
}
