<script setup>
import LayoutSecondaryPage from "@/components/home/LayoutSecondaryPage.vue";
import SecondaryPageHeader from "@/components/utilities/SecondaryPageHeader.vue";
import IconGlossario from "@/components/icons/IconGlossary.vue";
import GlossarioList from "@/components/glossary/GlossaryList.vue";
import {useUserNotificationsStore} from "@/stores/userNotificationStore.js";

const notificationStore = useUserNotificationsStore()
notificationStore.clearGlossaryNotifications()

</script>

<template>
    <LayoutSecondaryPage>
        <template #head>
            <SecondaryPageHeader>
                <template #icon
                    ><IconGlossario class="w-full h-full" />
                </template>
                <template #text>Glossario</template>
            </SecondaryPageHeader>
        </template>
        <!-- page content -->
        <template #main>
            <div class="container max-w-screen-lg">
                <GlossarioList />
            </div>
        </template>
    </LayoutSecondaryPage>
</template>
