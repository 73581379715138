<script setup>
import {onMounted, ref} from "vue";


const scrollableFadeClass = ref("");

const scrollableOverlay = ref(null);

const handleScroll = (e) => {
    let containerHeight = e.target.offsetHeight;
    let scrollTop = e.target.scrollTop;
    let scrollHeight = e.target.scrollHeight;

    // console.debug(containerHeight, scrollHeight);

    let endScroll = scrollHeight - containerHeight;
    let tollerance = 2;

    if (scrollTop < tollerance) {
        // can only scroll bottom
        scrollableFadeClass.value = "is--scrollable";
        // scrollableFadeClass.value = "is--scrollable";
    } else if (scrollTop > endScroll - tollerance) {
        // can only scroll top
        scrollableFadeClass.value = "";
        // scrollableFadeClass.value = "";
    } else {
        // can scroll both
        scrollableFadeClass.value = "is--scrollable";
        // scrollableFadeClass.value = "is--scrollable";
    }
};

onMounted(() => {
    // need this for triggering handScroll on slot change
    scrollableOverlay.value.scrollTo(0, 1);
    scrollableOverlay.value.scrollTo(0, 0);
});
</script>

<template>
    <div
        ref="scrollableOverlay"
        class="content-container"
        @scroll="handleScroll"
    >
        <div class="content-inner">
            <slot name="content"></slot>
        </div>
        <div
            :class="scrollableFadeClass"
            class="content-container-halftonescroll"
        ></div>
    </div>
</template>

<style scoped>
.content-container {
    @apply bg-white relative overflow-auto flex-grow rounded-t-3xl mx-2;
}

/* .content-container.is--scrollable {
    background-image: url("/assets/halftone-scrollable-bottom.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
} */

.content-container-halftonescroll {
    @apply sticky w-full bottom-0 left-0 pointer-events-none min-h-48 -mt-32;
    background-image: url("/assets/halftones/halftone-scrollable-bottom.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;

    transition: opacity 0.4s ease;
    opacity: 0;
}

.content-container-halftonescroll.is--scrollable {
    background-image: url("/assets/halftones/halftone-scrollable-bottom.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;

    opacity: 1;
}
</style>
