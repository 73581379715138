<script setup>
import IconAccordionExpand from "@/components/icons/IconAccordionExpand.vue";

const props = defineProps({
    vertical: {type: Boolean, required: false, default: false},
    backTo : {type : String, required: true},
    theme : {type : String, required: false, default : "blue"}
});

const themes = {
    blue : "text-blue",
    white : "text-white"
}

</script>

<template>
    <RouterLink :to="backTo">
        <div class="breadcrumb">
            <div :class="[vertical ? 'vertical' : '', themes[theme]]" class="breadcrumb-icon" >
                <IconAccordionExpand class="w-full h-full rotate-90"/>
            </div>
            <span class="breadcrumb-title" :class="themes[theme]">
                <slot></slot>
            </span>
        </div>
    </RouterLink>
</template>

<style scoped>
.breadcrumb {
    @apply flex items-center my-4 md:mt-16 md:mb-10 font-medium;
}

.breadcrumb-icon {
    @apply w-8 h-8 mr-2;
}

.breadcrumb-title{
    @apply text-2xl
}

.breadcrumb-icon.vertical {
    @apply rotate-90;
}
</style>
