<script setup>

</script>

<template>
<div class="py-1.5">
    <img class="inline-block px-6" src="/assets/icons/instrument-list-item-dot.png"  alt="instrument list item dot">
    <span class="text-black"><slot></slot></span>
</div>
</template>

<style scoped>

</style>
