import { createRouter, createWebHistory } from "vue-router";

import Opere from "@/pages/Operas.vue";
import Livelli from "@/pages/Levels.vue";
import Capitoli from "@/pages/Chapters.vue";
import Content from "@/pages/Content.vue";
import Achievements from "@/pages/Achievements.vue";
import Glossario from "@/pages/Glossary.vue";
import Profilo from "@/pages/Profile.vue";
import {
    redirectIfChapterDoesntExist,
    redirectIfLevelDoesntExist,
    redirectIfOperaDoesntExist
} from "@/libraries/middlewares/ContentExistCheckers.js";
import {
    redirectIfChapterNotUnlocked,
    redirectIfLevelNotUnlocked
} from "@/libraries/middlewares/ContentUnlockedCheckers.js";
import Epilogue from "@/pages/Epilogue.vue";

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            component: Opere,
            name: "Opere",
            alias: ["/app"],
            meta: { transition: "operas" }
        },
        {
            path: "/app/:operaId",
            component : Livelli,
            name: "Livelli",
            beforeEnter : [redirectIfOperaDoesntExist],
            meta: { transition: "levels" },
        },
        {
            path: "/app/:operaId/epilogo",
            component : Epilogue,
            name : "Epilogue",
            beforeEnter : [redirectIfOperaDoesntExist],
            meta: { transition: "operaEpilogue" },
        },
        {
            path: "/app/:operaId/:levelId",
            component: Capitoli,
            name: "Capitoli",
            meta: { transition: "chapters" },
            beforeEnter : [redirectIfOperaDoesntExist, redirectIfLevelDoesntExist, redirectIfLevelNotUnlocked],
        },
        {
            path: "/app/:operaId/:levelId/:chapterId",
            component: Content,
            name: "Content",
            meta: { transition: "content" },
            beforeEnter : [redirectIfOperaDoesntExist, redirectIfLevelDoesntExist,
                redirectIfChapterDoesntExist, redirectIfChapterNotUnlocked],
        },
        {
            path: "/app/achievements",
            component: Achievements,
            name: "Achievements",
            meta: { transition: "secondary" },
        },
        {
            path: "/app/glossario",
            component: Glossario,
            name: "Glossario",
            meta: { transition: "secondary" },
        },
        {
            path: "/app/profilo",
            component: Profilo,
            name: "Profilo",
            meta: { transition: "secondary" },
        }
    ],
});

