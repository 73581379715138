<script setup>
import {ref, watch} from "vue";

const props = defineProps({
    variant: { type: String, required: true },
});

const variants = {
    locked: "/assets/icons/icon-story-disable.svg",
    unlocked: "/assets/icons/icon-story-active.svg",
    current: "/assets/icons/icon-story-outline.svg",
    read: "/assets/icons/icon-story-active.svg",
    mastered: "/assets/icons/icon-story-master.svg",
};

const variant = ref(props.variant);

watch(() => props.variant, (newVal) => {
    variant.value = newVal
})

</script>

<template>
    <img :src="variants[variant]" alt="storia icon" />
</template>
