<script setup>
import { useAppStateStore } from "@/stores/appState.js";
import { onMounted } from "vue";

const props = defineProps({
    hasHalftoneVariant : {type: Boolean, required : false, default: false},
})

const AppState = useAppStateStore();

onMounted(() => {
    props.hasHalftoneVariant ? AppState.setPrimaryVariationTheme() : AppState.setPrimaryTheme();
});
</script>

<template>
    <div class="primary-page">
        <slot></slot>
    </div>
</template>

<style scoped>
.primary-page {
    @apply flex flex-col flex-grow overflow-auto;
    /* @apply h-auto; */
    /* @apply min-h-128; */
}
</style>
