import {
    extractLevelInfoFromRoute,
    extractOperaInfoFromRoute
} from "@/libraries/routes/RoutesUtilities.js";
import {operasMap} from "@/content/data/operas.js";
import {redirectToHome} from "@/libraries/middlewares/Utilities.js";
import {AppContent} from "@/content/data/content.js";

function redirectIfOperaDoesntExist(to, from, next){
    if(Object.values(operasMap).some(el => el.prettyName === to.params.operaId)){
        next()
        return
    }
    redirectToHome(next)
}

function redirectIfLevelDoesntExist(to, from, next){
    //do' per scontato che l'opera esista (uso queste guard sempre contatenate tra loro)
    const routeInfo = extractOperaInfoFromRoute(to)
    if(AppContent.operas[routeInfo.index].levels.some(el => el.prettyName === to.params.levelId)){
        next()
        return
    }
    redirectToHome(next)
}

function redirectIfChapterDoesntExist(to, from, next){
    //do' per scontato che l'opera e il livello esistano (uso queste guard sempre contatenate tra loro)
    const routeInfo = extractLevelInfoFromRoute(to)
    if(AppContent.operas[routeInfo.operaInfo.index].levels[routeInfo.levelInfo.index]
        .chapters.some(el => el.prettyName === to.params.chapterId)){
        next()
        return
    }
    redirectToHome(next)
}

export {
    redirectIfChapterDoesntExist,
    redirectIfLevelDoesntExist,
    redirectIfOperaDoesntExist
}
