<script setup>
import AppAccordion from "@/components/utilities/AppAccordion.vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {AppContent} from "@/content/data/content.js";

const UserProgression = useUserProgressionStore();
</script>

<template>
    <template v-if="UserProgression.anyGlossaryWordUnlocked()">
        <!-- An accordion for each GLOSSARIO section -->
        <template v-for="(group, gIndex) in AppContent.glossary">
            <AppAccordion
                v-if="UserProgression.glossaryGroupHasSomeWordsUnlocked(gIndex)"
                :key="group.title"
                :ariaTitle="group.title"
                :preExpanded="gIndex === 0"
                theme="glossaryGroup">
                <!-- section title -->
                <template #trigger>
                    <h2>
                        {{ group.title }}
                    </h2>
                </template>

                <template #content>
                    <!-- An accordion for each section's item -->
                    <template
                        v-for="(item, iIndex) in group.items" :key="item.word">
                        <AppAccordion
                            v-if="UserProgression.isGlossaryWordUnlocked(item.id)"
                            :ariaTitle="item.word"
                            theme="glossaryItem">
                            <!-- item title -->
                            <template #trigger>
                                <h3>{{ item.word }}</h3>
                            </template>
                            <!-- item content -->
                            <template #content>
                                <div class="glossary-item-content">
                                    <!-- I know v-html is very dangerous, i will use it carefully -->
                                    <p v-html="item.description"></p>
                                </div>
                            </template>
                        </AppAccordion>
                    </template>
                </template>
            </AppAccordion>
        </template>
    </template>
    <div v-else class="px-8">
        <p>Non hai ancora trovato dei termini.</p>
        <p>Cercali nelle opere.</p>
    </div>
</template>

<style scoped>
.glossary-item-content {
    @apply p-6 rounded-3xl bg-white text-grey-dark;
}
</style>
