<script setup>
// https://valgeirb.github.io/vue3-popper
import {useUserNotificationsStore} from "@/stores/userNotificationStore.js";
import { useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {computed, onMounted, ref} from "vue";
import Popper from "vue3-popper";
import AppButton from "@/components/utilities/AppButton.vue";
import {glossaryWordsMap} from "@/content/data/glossaryWords.js";

const props = defineProps({
    wordId : { type : Number, required: true },
    theme : { type : String, required : false, default : "warm"}
});

const themes = {
    warm : {
        trigger : "text-warm"
    },
    white : {
        trigger : "text-white"
    }
}

const word = glossaryWordsMap[props.wordId]
const UserProgression = useUserProgressionStore();
const UserNotifications = useUserNotificationsStore();

const trigger = ref(null);

const isWordFound = computed(() => UserProgression.isGlossaryWordUnlocked(word.id))

const addWordToGlossary = (closePopper) => {
    if (!UserProgression.isGlossaryWordUnlocked(word.id)) {
        UserProgression.addGlossaryWord(word.id);
        UserNotifications.pushNotification("glossary", word.id);
    }
    closePopper()
};


</script>

<template>
    <Popper arrow class="red">
        <!-- trigger -->
        <span class="tooltip" :class="!UserProgression.isGlossaryWordUnlocked(word.id) ? themes[theme].trigger : ''" ref="trigger">
            <u class="underline"><slot></slot></u>
        </span>
        <!-- content -->
        <template #content="{close}" class="w-full ">
            <h3 class="text-center mb-2 not-italic" v-if="trigger">{{ word.word }}</h3>
            <!-- I know v-html is very dangerous, i will use it carefully -->
            <p class="tooltip-content not-italic" v-html="word.description"></p>
            <div class="flex flex-col">
                <AppButton v-if="!isWordFound" @click="addWordToGlossary(close)" text="SBLOCCA" theme="roundedBlueText" variant="text"/>
            </div>
        </template>
    </Popper>
</template>

<style scoped>
.tooltip-content {
    @apply my-4 w-full text-black text-base font-normal leading-none;
}
/* :deep(.popper) {
    background: #e92791;
    padding: 20px;
    border-radius: 20px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
} */

/* .dark {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: white;
    --popper-theme-border-width: 0px;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 32px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
} */
:deep(.popper){
    @apply max-w-64
}
.red {
    --popper-theme-background-color: #ff5964;
    --popper-theme-background-color-hover: #ff5964;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 1px;
    --popper-theme-border-style: solid;
    --popper-theme-border-color: #ff5964;
    //--popper-theme-border-radius: 0 32px 32px;
    --popper-theme-border-radius: 32px;
    --popper-theme-padding: 15px 20px 20px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
    /* margin: 2rem !important; */
}


</style>
