<template>
    <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.6839 13.4606C16.281 13.8383 15.6482 13.8179 15.2705 13.415L8.5 6.19317L1.72953 13.415C1.3518 13.8179 0.718967 13.8383 0.316055 13.4606C-0.0868573 13.0829 -0.107271 12.45 0.27046 12.0471L8.5 3.26894L16.7295 12.0471C17.1073 12.45 17.0868 13.0829 16.6839 13.4606Z"
            fill="currentColor"
        />
    </svg>
</template>
