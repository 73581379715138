<script setup>
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {RouterLink} from "vue-router";
import AppButton from "@/components/utilities/AppButton.vue";
import LivelloStats from "@/components/levels/LevelStats.vue";
import {extractLevelRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {AppContent} from "@/content/data/content.js";

const props = defineProps({
    data: {type: Object, required: true},
});

const UserProgression = useUserProgressionStore();

const levelRouteInfo = extractLevelRouteInfo()

const operaId = levelRouteInfo.operaInfo.id

const operaIndex = levelRouteInfo.operaInfo.index;
const levelIndex = levelRouteInfo.levelInfo.index;

function unlockNextLevel(){
    if(levelIndex < AppContent.operas[operaIndex].levels.length - 1){
        UserProgression.setLevelAsUnlocked(operaIndex, levelIndex + 1)
        UserProgression.setChapterAsUnlocked(operaIndex, levelIndex + 1, 0)
    }
}

</script>

<template>
    <div class="content-end-chapter">
        <div class="content-end-imgbox relative">
            <img :src="data.selectorImg.warm" class="absolute-top preserve-ratio image-cover fixed-top" />
            <img class="absolute-top preserve-ratio image-cover fixed-bottom" src="/assets/halftones/level-end-halftone-warm-light.png">
        </div>

        <div class="content-end-chapter-foot">
            <h1 class="text-green-light text-3xl font-medium text-center pt-3">
                Hai completato {{ data.title.toUpperCase() }}
            </h1>
            <div class="stats-container">
                <div class="end-chapter-stats">
                    <LivelloStats :data :progression="UserProgression.unlockedLevelAchievements(operaIndex, levelIndex)"
                                  theme="levelPage" :is-report="true"/>
                </div>
            </div>
            <RouterLink class="next-level-button" :to="`/app/${operaId}`" @click="unlockNextLevel">
                <AppButton
                    class="uppercase"
                    text="Inizia il prossimo livello"
                    theme="roundedRedText"
                    variant="text"
                >
                </AppButton>
            </RouterLink>
        </div>
    </div>
</template>

<!-- https://kovart.github.io/dashed-border-generator/ -->
<style scoped>

img.aligned-top{
    @apply absolute top-0 left-0 inset-x-0 object-cover
}

.preserve-ratio{
    max-height: 100%;
    min-width: 100%
}

.preserve-ratio.top{
    object-position: top;
}

.preserve-ratio.bottom{
    object-position: bottom;
}

.content-end-chapter {
    @apply flex flex-col max-w-screen-md mx-0 md:mx-auto;
    height: clamp(28em, 100vh, 44em);
}

.content-end-imgbox{
    @apply mt-4 mb-4 flex-grow
}

.stats-container{
    @apply flex flex-col px-8 w-full
}

.level-end-foot-halftone{
    @apply w-full relative h-60 z-10;
}

.end-chapter-stats {
    @apply px-8 py-4 my-4 bg-warm-light rounded-2xl border-dashed border-green-light border-2 ;
    /* border: dashed 1px black; */
}

.content-end-chapter-foot{
    @apply flex flex-col items-center w-full pb-24
}

.next-level-button {
    @apply mt-6
}
</style>
