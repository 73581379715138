<script setup>
import {RouterLink} from "vue-router";
import {AppContent} from "@/content/data/content.js";
import AppButton from "@/components/utilities/AppButton.vue";
import IconArrowLeft from "@/components/icons/IconArrowLeft.vue";
import IconArrowRight from "@/components/icons/IconArrowRight.vue";
import {computed} from "vue";


const currentOperaIndex = defineModel();

function prevOpera() {
    currentOperaIndex.value -= currentOperaIndex.value > 0 ? 1 : 0;
}

function nextOpera() {
    currentOperaIndex.value += currentOperaIndex.value < AppContent.operas.length - 1 ? 1 : 0;
}

const currentOpera = computed(() => {
    return AppContent.operas[currentOperaIndex.value];
})

const operaUrl = computed(() => {
    return `/app/${currentOpera.value.prettyName}`
})

// import { Transition } from "vue";
</script>

<template>
    <Transition appear mode="out-in" name="operenav">
        <div class="opere-nav">
            <!-- previous button -->
            <AppButton
                :disabled="currentOperaIndex === 0"
                text="previous"
                theme="transparentBlack"
                variant="icon"
                @click="prevOpera()"
            >
                <template #icon>
                    <IconArrowLeft/>
                </template>
            </AppButton>
            <!-- opera selector button -->
            <RouterLink :to="operaUrl" class="w-full mx-4">
                <AppButton
                    :disabled="currentOperaIndex !== 0"
                    :text="currentOperaIndex === 0 ? 'esplora' : 'in arrivo'"
                    class="uppercase w-full flex-grow"
                    theme="roundedRedBg"
                    variant="text"
                />
            </RouterLink>
            <!-- next button -->
            <AppButton
                :disabled="currentOperaIndex === AppContent.operas.length - 1"
                text="next"
                theme="transparentBlack"
                variant="icon"
                @click="nextOpera()"
            >
                <template #icon>
                    <IconArrowRight/>
                </template>
            </AppButton>
        </div>
    </Transition>
</template>

<style scoped>
.opere-nav {
    @apply relative container max-w-screen-sm flex justify-between p-2;
    max-width: 360px;
}

/**TRANSITIONS */
.operenav-enter-active,
.operenav-leave-active {
    opacity: 1;
    transition: opacity 0.8s ease;
    transition-delay: 0.8s, 0s;
}

.operenav-enter-from,
.operenav-leave-to {
    opacity: 0;
}
</style>
