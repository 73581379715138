<script setup>
import {useUserProgressionStore,} from "@/stores/userProgressionStore.js";
import {useRouter} from "vue-router";
import AppButtonHold from "@/components/utilities/AppButtonHold.vue";
import {extractLevelRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {defineModel, defineProps, ref} from "vue";
import {AppContent} from "@/content/data/content.js";
import {useUserNotificationsStore} from "@/stores/userNotificationStore.js";
import AppButton from "@/components/utilities/AppButton.vue";

const props = defineProps({
    chapterIndex : {type : Number, required : true}
})

const UserProgression = useUserProgressionStore();
const UserNotifications = useUserNotificationsStore();

const router = useRouter()

const readChapters = defineModel()

const levelRouteInfo = extractLevelRouteInfo()

const operaId = levelRouteInfo.operaInfo.id
const levelId = levelRouteInfo.levelInfo.id

const operaIndex = levelRouteInfo.operaInfo.index;
const levelIndex = levelRouteInfo.levelInfo.index;
let chapterIndex = props.chapterIndex;

const currentLevel = AppContent.operas[operaIndex].levels[levelIndex];
const currentChapter =  currentLevel.chapters[chapterIndex];

function nextChapter(){

    if(readChapters.value === props.chapterIndex){
        readChapters.value = props.chapterIndex + 1
    }

    UserProgression.setChapterAsRead(operaIndex, levelIndex, chapterIndex)

    chapterIndex++;

    UserProgression.setChapterAsUnlocked(operaIndex, levelIndex, chapterIndex);

    //window.location.href = `/app/${operaId}/${levelId}/${currentLevel.chapters[chapterIndex].prettyName}`

    router.push(`/app/${operaId}/${levelId}/${currentLevel.chapters[chapterIndex].prettyName}`)
}

const nextChapterName = currentLevel.chapters[chapterIndex + 1].title.toUpperCase()

</script>

<template>
    <div class="next-chapter-card">
        <div class="next-chapter-card-content">
            <h3 class="mb-4 text-blue font-medium text-center text-2xl">
                Hai completato <span class="uppercase">{{ currentChapter.title }}</span>!
            </h3>
            <p>
                <slot></slot>
            </p>
            <p class="mb-4 px-4">
                Continua il tuo viaggio alla scoperta di quest'opera!
            </p>
            <div class="flex flex-col px-6">
                <AppButton
                    theme="roundedRedBgAlt"
                    :text="'INIZIA ' + nextChapterName"
                    @click="nextChapter()"
                ></AppButton>
            </div>
        </div>
    </div>
</template>

<style scoped>
.next-chapter-card {
    @apply container max-w-screen-sm mx-auto px-4;
}

.next-chapter-card-content {
    @apply mt-16 mb-20 py-8 px-4 bg-warm-light text-black rounded-3xl;
}
</style>
