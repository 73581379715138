<script setup>
import { useAppStateStore } from "@/stores/appState.js";
const AppState = useAppStateStore();

import { onMounted } from "vue";
onMounted(() => {
    AppState.setSecondaryTheme();
});
</script>

<template>
    <div class="secondary-page">
        <slot name="head"></slot>
        <slot name="main"></slot>
    </div>
</template>

<style scoped>
.secondary-page {
    @apply text-white mt-8 flex flex-col flex-grow;
    @apply overflow-auto;
}
</style>
