<script setup>
import {ref} from "vue";
import {gsap} from "gsap";
import OperaItemDecorations from "@/components/operas/OperaItemDecorations.vue";
import BarbiereIcon from "@/components/icons/BarbiereIcon.vue";

/**
 * Opera item displayed in opera list
 */
const props = defineProps({
    title: {type: String, required: true},
    img: {type: Object, required: true},
    decos: {type: Array, required: true},
});

// store title value for css halfone shadows
const title = ref(props.title);
const cssVars = () => ({
    "--title": JSON.stringify(title.value),
})

const transitions = {
    createTl: (el, opts) => {
        const selector = gsap.utils.selector(el);
        let tl = gsap
            .timeline(opts)
            .fromTo(
                selector(".opera-content"),
                {
                    translateY: "-100%",
                },
                {
                    translateY: "0%",
                    duration: 0.8,
                    ease: "power1.out",
                },
                0.2
            )
            .fromTo(
                selector(".opera-titlebox"),
                {
                    translateY: "-100%",
                },
                {
                    translateY: "0%",
                    duration: 0.8,
                    ease: "power1.out",
                },
                "<"
            )
            .fromTo(
                selector(".opera-decos-half:nth-child(odd) .opera-deco"),
                {
                    translateX: "-100%",
                    opacity: 0,
                },
                {
                    translateX: "0%",
                    opacity: 1,
                    duration: 0.6,
                    stagger: 0.1,
                    ease: el.tlState == "enter" ? "power2.out" : "power2.in",
                },
                "<"
            )
            .fromTo(
                selector(".opera-decos-half:nth-child(even) .opera-deco"),
                {
                    translateX: "100%",
                    opacity: 0,
                },
                {
                    translateX: "0%",
                    opacity: 1,
                    duration: 0.6,
                    stagger: -0.1,
                    ease: el.tlState == "enter" ? "power2.out" : "power2.in",
                },
                "<"
            );

        return tl;
    },
    onBeforeEnter: (el) => {
        el.tlState = "enter";
        el.tl = transitions.createTl(el, {paused: true});
    },
    onEnter: async (el, done) => {
        await el.tl.resume();
        done();
    },
    onBeforeLeave: (el) => {
        el.tl.reversed(true);
        el.tlState = "leave";
    },
    onLeave: async (el, done) => {
        await el.tl.resume();
        done();
    },
};
</script>

<template>
    <Transition
        :css="false"
        appear
        mode="out-in"
        name="opera"
        @enter="transitions.onEnter"
        @leave="transitions.onLeave"
        @before-enter="transitions.onBeforeEnter"
        @after-enter="transitions.onAfterEnter"
        @before-leave="transitions.onBeforeLeave"
        @after-leave="transitions.onAfterLeave"
    >
        <div class="opera">
            <OperaItemDecorations :decos="props.decos"></OperaItemDecorations>
            <div class="opera-top">
                <div class="opera-content">
                    <div class="flex flex-col justify-center items-center h-full md:absolute md:start-0 md:bottom-0">
                        <div class="lines-deco">
                            <div class="opera-line"></div>
                            <div class="opera-line"></div>
                            <div class="opera-line"></div>
                        </div>
                        <div class="opera-imgbox">
                            <!--<BarbiereIcon class="w-full h-full"/>-->
                            <component :is="img" class="w-full h-full"/>
                        </div>
                    </div>
                    <div class="opera-titlebox">
                        <h1 :style="cssVars()" class="opera-title">
                            {{ title }}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<style scoped>
.opera {
    border: 1px red blue;
}

/* .opera {
    @apply absolute top-0 left-0 w-full h-full grid;
    grid-template-rows: 0.7fr 0.3fr;
}
*/
.opera-top {
    @apply absolute w-full h-full top-0 left-0;
    @apply flex flex-col justify-end;
}

.opera-content {
    @apply container max-w-screen-lg flex flex-col justify-center items-center h-full p-4 pt-0;
    @apply md:relative md:flex md:justify-end
}

.lines-deco {
    @apply w-32 flex-grow flex flex-row justify-between overflow-hidden;
}

.opera-line {
    @apply w-0.5 h-full bg-green;
    background: linear-gradient(to top, #fff, #fff 15%, #39AFA1 15.1%, #39AFA1);
}

.opera-line:nth-child(2) {
    transform: translateY(-4em);
}

.opera-imgbox {
    @apply relative pointer-events-none -mt-20;
    @apply h-72 w-72 md:h-45vh md:w-45vh
}

.opera-titlebox {
    @apply text-blue text-center mt-4;
    @apply md:pb-16 max-w-72 md:max-w-200  self-center
}

.opera-title {
    @apply font-display font-bold relative text-5xl;
    @apply md:text-7.5xl;
    z-index: 1;
}

.opera-title::before {
    @apply absolute inline-block -top-1 -left-4;
    background: url(/assets/halftones/blue-halftone-text-bg.png);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    content: var(--title);
    color: transparent;
    z-index: -1;
}

/**TRANSITIONS */
.opera-enter-active,
.opera-leave-active {
    transform: translateY(0%);
    transition: transform 1s ease-out;
}

.opera-enter-from,
.opera-leave-to {
    transform: translateY(-100%);
}

.opera-enter-active .opera-content .opera-titlebox,
.opera-leave-active .opera-content .opera-titlebox {
    transform: translateY(0%);
    transition: transform 1s ease-out;
}

.opera-enter-from .opera-content .opera-titlebox,
.opera-leave-to .opera-content .opera-titlebox {
    transform: translateY(-100%);
}
</style>
