<script setup>
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {computed, onMounted, ref, watch} from "vue";
import {gsap} from "gsap";
import IconStoria from "@/components/icons/IconStory.vue";
import IconPersonaggi from "@/components/icons/IconCharacters.vue";
import IconMusica from "@/components/icons/IconMusic.vue";
import IconMinigame from "@/components/icons/IconMinigame.vue";
import {
    extractChapterInfoFromRoute,
    extractChapterRouteInfo,
    extractLevelRouteInfo
} from "@/libraries/routes/RoutesUtilities.js";
import {RouterLink, useRoute} from "vue-router";
import {AppContent} from "@/content/data/content.js";

const icons = {
    storia: IconStoria,
    personaggi: IconPersonaggi,
    musica: IconMusica,
    minigame: IconMinigame,
};

const UserProgression = useUserProgressionStore();

const route = useRoute()

const routeInfo = extractChapterRouteInfo()

const operaId = routeInfo.operaInfo.id
const levelId = routeInfo.levelInfo.id

const operaIndex = routeInfo.operaInfo.index;
const levelIndex = routeInfo.levelInfo.index;

const currChapterIndex = ref(routeInfo.chapterInfo.index)

const currentLevel = AppContent.operas[operaIndex].levels[levelIndex];
const getChapterProgression = (chapterIndex) => {
    return UserProgression.chapterProgression(operaIndex, levelIndex, chapterIndex)
}

const iconVariant = (chapterIndex) => {
    if(UserProgression.isLevelMastered(operaIndex, levelIndex)){
        return "mastered"
    } else if(chapterIndex === currChapterIndex.value) {
        return "current"
    }
    return getChapterProgression(chapterIndex).state
}

const isNavBtnEnabled = (chapterIndex) => getChapterProgression(chapterIndex).state !== 'locked'

const chapterUrl = (chapter) => `/app/${operaId}/${levelId}/${chapter.prettyName}`

watch(() => route.params.chapterId, (newVal) => {
    const newRouteInfo = extractChapterInfoFromRoute(route)
    currChapterIndex.value = newRouteInfo.chapterInfo.index
})


watch(UserProgression, () => {
    updateProgress();
});

const progress = ref(0);
const progressBar = ref(null);
const progressBarColor = computed(() => UserProgression.isLevelMastered(operaIndex, levelIndex) ? "bg-gold": "bg-warm-light" )
function updateProgress(){
    let levelProgression = UserProgression.levelCompletionPerc(operaIndex, levelIndex);

    if (levelProgression === 0) {
        progress.value = 0;
    } else if (levelProgression <= 0.25) {
        progress.value = 0.15;
    } else if (levelProgression <= 0.5) {
        progress.value = 0.4;
    } else if (levelProgression <= 0.75) {
        progress.value = 0.62;
    } else {
        progress.value = 1;
    }

    gsap.to(progressBar.value, {
        width: progress.value * 100 + "%",
        duration: 0.2,
    });
}

onMounted(() => {
    updateProgress();
});
</script>

<template>
    <div class="content-nav">
        <div class="content-nav-container">
            <div class="content-nav-icons">
                <div v-for="(item, index) in currentLevel.chapters" class="content-nav-icon">
                    <router-link :to="chapterUrl(item)" v-if="isNavBtnEnabled(index)">
                        <component
                            :is="icons[item.prettyName]"
                            :variant="iconVariant(index)"
                            class="w-full h-full"
                        />
                    </router-link>
                    <component
                        v-else
                        :is="icons[item.prettyName]"
                        :variant="iconVariant(index)"
                        class="w-full h-full"
                    />
                </div>
            </div>
            <div class="content-nav-progress">
                <div ref="progressBar" class="content-nav-progress-bar" :class="progressBarColor"></div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.content-nav {
    @apply w-full ;
}

.content-nav-container{
    @apply container max-w-screen-md px-0 block fixed inset-x-0 bottom-0 z-10
}
.content-nav-icons {
    @apply h-16 px-4 py-2 bg-blue flex items-center justify-around;
}

/* .content-nav-icon {
} */

.content-nav-progress {
    @apply h-3 w-full bg-grey-light;
}

.content-nav-progress-bar {
    @apply h-full;
    width: 0;
    /* width: calc((100% * var(--progress))); */
}
</style>
