<script setup>
import AppDialog from "@/components/utilities/AppDialog.vue";
import {ref} from "vue";
import ContentAppendixCard from "@/components/content/ContentAppendixCard.vue";

const dialog = ref(null);

const cardBg = ref("url('/assets/operas/barbieredisiviglia/level-2/appendice-image.png')")

const isRevealed = ref(false)

function setRevealed(){
    dialog.value.openDialog()
    isRevealed.value = true;
}

</script>

<template>
    <div
        class="appendix-card-trigger"
        @click.self="setRevealed()"
        :class="isRevealed ? 'revealed' : ''"
    >
        <!-- reveal trigger content -->
        <p class="appendix-card-trigger-text">APPENDICE III</p>
        <!-- dialog shown on trigger's click -->
        <AppDialog ref="dialog" class="h-104">
            <!-- card  -->
            <ContentAppendixCard @close-card="dialog.closeDialog()"/>
        </AppDialog>
    </div>
</template>

<style scoped>
.appendix-card-trigger {
    @apply bg-black text-gold w-full h-full rounded-2xl overflow-clip;

    background-image: url("/assets/curtains/curtain-bg-left-new.png");
    background-size: cover;
    background-repeat: no-repeat;
}
.appendix-card-trigger.revealed {
    /* @apply bg-green ; */
    background-image: v-bind(cardBg);
    background-position: top;
    background-size: cover;
}

.appendix-card-trigger-text {
    @apply pt-5 font-bold text-sm uppercase text-center;
    /* font-size: 0.8rem; */
}
</style>
