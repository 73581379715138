<script setup></script>

<template>
    <header
        class="container text-warm-light max-w-screen-lg flex items-center mb-8 px-8 md:px-0"
    >
        <!-- icon -->
        <div class="w-12 h-12 mr-2">
            <slot name="icon"></slot>
        </div>
        <!-- text -->
        <h1 class="text-4xl font-medium">
            <slot name="text"></slot>
        </h1>
    </header>
</template>

<style scoped></style>
