<script setup>
</script>

<template>
<div class="content-character-voice-card">
    <div class="col-span-2">
        <slot name="profile-picture"></slot>
    </div>
    <div class="col-span-3 p-2 px-4 flex items-center">
        <div>
            <h3 class="uppercase text-xl text-black">
                <slot name="title"></slot>
            </h3>
            <h4 class="pt-1 text-black"><slot name="subtitle"></slot></h4>
        </div>
    </div>
</div>
</template>

<style scoped>
.content-character-voice-card{
    @apply grid grid-cols-5 px-6 py-2 max-w-screen-sm
}
</style>
