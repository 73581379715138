<script setup>
import {ref} from "vue";
import {useAudioGroupController} from "@/libraries/audio/AudioGroupController.js";
import {useMediaControls} from "@vueuse/core";

/**
 * HTML Audio wrapper component
 * // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/audio
 */
const props = defineProps({
    src: { type: String, required: true },
    loop: { type: Boolean, required: false },
    muted: { type: Boolean, required: false },
    controls: { type: Boolean, required: false },
});

const audio = ref(null);

const emit = defineEmits(["play", "pause"]);

const audioController = useAudioGroupController()

const { playing, currentTime, duration, volume } = useMediaControls(audio, {src : props.src})

// exposed methods
function play() {
    playing.value = true
    emit('play')
}
function pause() {
    playing.value = false
    emit('pause')
}
function reset() {
    pause()
    currentTime.value = 0;
}
function playReset() {
    if (!playing.value) {
        play()
    } else {
        reset();
    }
}

function playPause(){
    if(playing.value){
        pause()
    } else {
        play()
    }
}

function setCurrentTime(newVal){
    currentTime.value = newVal
}

function audioStarted(){
    audioController.newAudioStarted(audio.value, {onStop : () => emit("pause")})
    emit('play')
}

defineExpose({
    playReset,
    playPause,
    setCurrentTime,
    playing,
    duration,
    currentTime
});


</script>

<template>
    <figure class="audioplayer-figure">
        <figcaption class="audioplayer-caption">
            <slot></slot>
        </figcaption>
        <audio
            class="audioplayer"
            ref="audio"
            :src="src"
            :loop="loop"
            :muted="muted"
            :controls="controls"
            controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"
            preload="auto"
            @play="audioStarted"
            type="audio/mpeg"
        ></audio>
    </figure>

</template>

<style scoped>
/* .audio-figure {
} */

.audioplayer {
    @apply bg-transparent w-full;
}
</style>
