<script setup>

import CharacterCardContentItem from "@/components/content/characters/CharacterCardContentItem.vue";

const props = defineProps({
    isRevealed: { type: Boolean, required: true, default: false },
    character: { type: Object, required: true },
});

const cardBg = `url(${props.character.cardPic})`

</script>

<template>
    <div class="character-card-inner">
        <div class="p-c-imagebox"></div>

        <div class="p-c-content" v-if="isRevealed">
            <!-- head -->
            <div class="character-card-content-top">
                <div class="character-card-content-title-curtain">
                    <img
                        class="w-full h-full"
                        src="/assets/characters/personaggio-title-deco.png"
                    />
                </div>
                <div class="character-card-content-title">
                    <div class="character-card-content-title-topcurtain"></div>
                    <h3 class="font-medium text-xl">{{character.name.toUpperCase()}}</h3>
                </div>
                <div class="character-card-content-title-curtain">
                    <img
                        class="w-full h-full scale-x-[-1]"
                        src="/assets/characters/personaggio-title-deco.png"
                    />
                </div>
            </div>
            <div>
                <!-- items -->
                <div class="p-c-c-items">
                    <CharacterCardContentItem
                        icon="/assets/icons/character-card/Icona_Potere.png"
                        :text="character.mainStrength"
                    >
                    </CharacterCardContentItem>
                    <CharacterCardContentItem
                        icon="/assets/icons/character-card/Icona_Caratteristiche.png"
                        :text="character.traits"
                    >
                    </CharacterCardContentItem>
                    <CharacterCardContentItem
                        icon="/assets/icons/character-card/Icona_Punti_Deboli.png"
                        :text="character.weaknesses"
                    >
                    </CharacterCardContentItem>
                    <CharacterCardContentItem
                        icon="/assets/icons/character-card/Icona_Arma_Segreta.png"
                        :text="character.weapon"
                    >
                    </CharacterCardContentItem>
                </div>
                <!-- description -->
                <div class="p-c-c-description flex">
                    <img class="inline w-6 h-6" src="/assets/icons/character-card/Icona_Obiettivo.png">
                    <div class="ms-4 table">
                        <p class="h-full align-middle table-cell">{{character.goal}}</p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.character-card-inner {
    @apply relative w-full h-full;
    background-color: #394B72;
}
.p-c-imagebox {
    @apply absolute w-full h-full left-0 top-0 bg-no-repeat bg-cover;
    background-image: v-bind(cardBg);
}
.p-c-content {
    @apply relative h-full flex flex-col justify-between items-stretch;
}

.p-c-c-items {
    @apply mb-4 mr-4;
    @apply flex flex-col justify-start items-start;
}

.p-c-c-description {
    @apply mx-4 px-8 py-4 bg-warm-light rounded-t-2xl;
}

.character-card-content-top {
    @apply flex justify-between;
}

.character-card-content-title-curtain {
    /* border: 1px solid black; */
}

.character-card-content-title {
    @apply flex flex-col justify-stretch text-center;
}
.character-card-content-title-topcurtain {
    @apply w-full h-5;
    /* border: 1px solid black; */
}
.character-card-content-title h3 {
    @apply px-2 py-1 bg-warm text-white;
}
</style>
