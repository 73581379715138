export const ariasMap = {
    1 : {
        id : 1,
        name : "Largo al factotum",
        shortDescription : "Cavatina di Figaro",
        longDescription : "In questa famosissima aria," +
            " Figaro si presenta al pubblico mettendo in evidenza le sue arti di barbiere e definendosi" +
            " “factotum della città”, da personaggio esuberante e intraprendente qual è.",
        audioSource : "https://io-bunny-cdn.b-cdn.net/ROF/arias/cavatina-di-figaro.mp3"
    },
    2 : {
        id : 2,
        name : "Se il mio nome saper voi bramate",
        shortDescription : "Canzone del Conte",
        longDescription : "Con questa serenata rivolta a Rosina, in ascolto dalla finestra," +
            " il Conte d’Almaviva nasconde la propria identità presentandosi come Lindoro," +
            " un giovane innamorato e intenzionato a sposare la ragazza.",
        audioSource : "https://io-bunny-cdn.b-cdn.net/ROF/arias/canzone-conte.mp3"
    },
    3 : {
        id : 3,
        name : "Una voce poco fa",
        shortDescription : "Cavatina di Rosina",
        longDescription : "In questa aria di presentazione, Rosina delinea un ritratto di sé stessa:" +
            " docile e amorosa, ma pronta a reagire e a tramare “cento trappole” per ottenere ciò che desidera.",
        audioSource : "https://io-bunny-cdn.b-cdn.net/ROF/arias/cavatina-di-rosina.mp3"
    },
    4 : {
        id : 4,
        name : "La calunnia è un venticello",
        shortDescription : "Aria di Basilio",
        longDescription : "Basilio, in questa aria, suggerisce a Bartolo di liberarsi di Almaviva," +
            " diffondendo calunnie sul suo conto fino a portarlo alla disperazione e alla rovina.",
        audioSource : "https://io-bunny-cdn.b-cdn.net/ROF/arias/aria-di-basilio.mp3"
    },
    5 : {
        id : 5,
        name : "A un Dottor della mia sorte",
        shortDescription : "Aria di Bartolo",
        longDescription : "In questa aria, il personaggio di Bartolo si mostra diffidente anche nei riguardi di Rosina," +
            " di cui comprende la furbizia, sospettando che la giovane abbia scritto una lettera a qualcuno.",
        audioSource : "https://io-bunny-cdn.b-cdn.net/ROF/arias/aria-di-bartolo.mp3"
    },
    6 : {
        id : 6,
        name : "Il vecchiotto cerca moglie",
        shortDescription : "Aria di Berta",
        longDescription : "Berta, governante di Bartolo, in questa aria dal ritmo incalzante," +
            " si lamenta per la confusione che, nella casa, suscita il sentimento dell’amore nel padrone e in Rosina," +
            " ammettendo, in cuor suo, che anche lei vorrebbe provare lo stesso “tormento”.",
        audioSource : "https://io-bunny-cdn.b-cdn.net/ROF/arias/aria-di-berta.mp3"
    },
    7 : {
        id : 7,
    },
    8 : {
        id : 8,
    },
    9 : {
        id : 9,
    },
}
