<script setup>

import Title from "@/components/content/ContentTitle.vue";
import Instrument from "@/components/content/music/InstrumentListItem.vue";
import DownloadableItem from "@/components/content/ContentDownloadableItem.vue";
import ImageBox from "@/components/content/ContentImageBox.vue";
import ContentFunFact from "@/components/content/ContentFunFact.vue";
import NextChapterCard from "@/components/content/ContentNextChapterCard.vue";
import {defineModel, defineProps, watch} from "vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {useUserNotificationsStore} from "@/stores/userNotificationStore.js";
import AriaPlayer from "@/components/content/music/AriaPlayer.vue";
import ContentFunfactParagraph from "@/components/content/ContentFunfactParagraph.vue";
import ContentTextbox from "@/components/content/ContentTextbox.vue";
import GlossaryWordTooltip from "@/components/glossary/GlossaryWordTooltip.vue";

const props = defineProps({
    chapterIndex : {type : Number, required : true}
})

const readChapters = defineModel()

const UserProgression = useUserProgressionStore()
const UserNotifications = useUserNotificationsStore()

function schemeDownloaded(){
    if(!UserProgression.isAchievementUnlocked(5)){
        UserProgression.unlockAchievementById(5);
        UserNotifications.pushNotification("achievement", 5);
    }
}

function unlockTemporaleAchievement(){
    if (!UserProgression.isAchievementUnlocked(9)) {
        UserProgression.unlockAchievementById(9);
        UserNotifications.pushNotification("achievement", 9);
    }
}


</script>

<template>
    <Title>Qual è l’organico orchestrale del <i class="italic">Barbiere di Siviglia</i> ?</Title>
    <div class="px-8 mt-2 max-w-screen-sm mx-auto">
        <Instrument>2 Flauti/Ottavini</Instrument>
        <Instrument>2 Oboi*</Instrument>
        <Instrument>2 Clarinetti</Instrument>
        <Instrument>2 Fagotti</Instrument>
        <Instrument>2 Corni</Instrument>
        <Instrument>2 Trombe</Instrument>
        <Instrument>Timpani**</Instrument>
        <Instrument>Gran cassa</Instrument>
        <Instrument>Sistri</Instrument>
        <Instrument>Pianoforte***</Instrument>
        <Instrument>Chitarra</Instrument>
        <Instrument>Archi</Instrument>
    </div>
    <div class="px-16 pt-4 pb-12 max-w-screen-sm mx-auto">
        <p>
            *L'Oboe II è presente solo nelle fonti della Sinfonia.
        </p>
        <p>
            **I Timpani sono presenti solo in alcune fonti della Sinfonia.
        </p>
        <p>
            ***Il Pianoforte è indicato solo nel N. 11 [Aria Rosina], bb. 4-6.
        </p>
    </div>
    <ImageBox align="center">
        <img src="/assets/operas/barbieredisiviglia/level-1/content-image-7-O0L1C2.png" alt="" />
    </ImageBox>
    <Title>Scarica lo schema musicale del <i class="italic">Barbiere di Siviglia</i> !</Title>
        <DownloadableItem @download="schemeDownloaded" src="https://io-bunny-cdn.b-cdn.net/ROF/documents/Schema%20musicale%20Barbiere.pdf" :n-pages="2">
            <template #title>Schema musicale del <i class="italic">Barbiere di Siviglia</i></template>
        </DownloadableItem>
    <ContentFunFact theme="green" hidden-legacy-p>
        <template #counter>CURIOSITÀ (3 su 3)</template>
        <template #title>Il temporale che ritorna.</template>
        <template #funcfact-p>
            <ContentFunfactParagraph>
                <template #image>
                    <img src="/assets/operas/barbieredisiviglia/level-1/funfact-image-2-O0L1C2.png" alt="fun-fact related image"/>
                </template>
                <template #image-description>
                    <p>
                        “Segue Istromentale esprimente un Temporale. Dalla finestra di prospetto si vedono frequenti lampi,
                        e si ascolta il romore del tuono. Sulla fine dell'Istromentale si vede dal di fuori aprire la gelosia,
                        ed entrare un dopo l'altro Figaro, ed il Conte avvolti in mantello e bagnati dalla pioggia. Figaro avrà in mano una lanterna.”
                    </p>
                </template>
            </ContentFunfactParagraph>
        </template>
        <template #bottom-description>
            <ContentTextbox>
                Il <i class="italic">Temporale</i> presente nel <i class="italic">Barbiere</i> non è un brano originale.
                Rossini lo ha infatti ripreso e rielaborato dalla
                <i class="italic"><GlossaryWordTooltip :word-id="53" theme="white">Pietra del paragone</GlossaryWordTooltip></i>,
                riutilizzandolo anche nell'<i class="italic">Occasione fa il ladro.</i>
            </ContentTextbox>
            <ContentTextbox>
                Rossini aveva già scritto brevi composizioni strumentali ispirate alla natura,
                come la <i class="italic">Tempesta</i> (dalle <i class="italic">Sei Sonate a quattro</i>),
                la cui eco è riconoscibile proprio nel <i class="italic">Tempesta</i> del <i class="italic">Barbiere di Siviglia.</i>
            </ContentTextbox>
        </template>
        <template #footer>
            <AriaPlayer src="https://io-bunny-cdn.b-cdn.net/ROF/arias/temporale.mp3" @play="unlockTemporaleAchievement">
                <template #player>
                    <h3 class="font-medium text-2xl leading-none text-grey-dark">Ascolta il <i class="italic">Temporale</i></h3>
                </template>
            </AriaPlayer>
        </template>
    </ContentFunFact>

    <NextChapterCard v-model="readChapters" :chapter-index="chapterIndex"/>
</template>

<style scoped>
.ps{
    @apply px-16 pt-4 pb-12 max-w-screen-sm mx-auto;
}
</style>
